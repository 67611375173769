import {User} from './user';

export class Ressourcerie {

	title = '';
	visuel = '';
	type = '';
	url = '';
	likes = 0;
	author = '';
	inaric = '';
	user: User;
	public date = new Date().toString();

	public dateDay;
	public dateMonth;
	public dateYear;

	public month = [
		"Janvier",
		"Fevrier",
		"Mars",
		"Avril",
		"Mai",
		"Juin",
		"Juillet",
		"Aout",
		"Septembre",
		"Octobre",
		"Novembre",
		"Décembre",
	];

	constructor(raw: any = null) {
		if (raw.hasOwnProperty('title')) {
			this.title = raw.title;
		}
		if (raw.hasOwnProperty('visuel')) {
			this.visuel = raw.visuel;
		}
		if (raw.hasOwnProperty('url')) {
			this.type = raw.url;
		}
		if (raw.hasOwnProperty('url')) {
			this.url = raw.url;
		}
		if (raw.hasOwnProperty('likes')) {
			this.likes = raw.likes;
		}
		if (raw.hasOwnProperty('date')) {
			this.date = raw.date;
		}
		if (raw.hasOwnProperty('author')) {
			this.author = raw.author;
		}
		if (raw.hasOwnProperty('inaric')) {
			this.inaric = raw.inaric;
		}
		if (raw.hasOwnProperty('user')) {
			this.user = new User(raw.user);
		}
		if (this.date !== "" && this.date !== undefined) {

			// const arrDate = this.date.split('.');

			// const date = new Date(arrDate[1] + '/' + arrDate[0] + '/' + arrDate[2]);
			const date = new Date(this.date);
			this.dateDay = date.getDate();
			this.dateYear = date.getFullYear();
			this.dateMonth = this.month[date.getMonth()];
		}
	}
}


