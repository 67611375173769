import {User} from "./user";
import {ForumPost} from "./forum-post";
import {Comment} from "./comment";
import {environment} from "../../../environments/environment";
import {File} from "./file";

export class Event {
	public id: number;
	public uuid: string;
	public title: string;
	public description: string;
	public posts: ForumPost[];
	public owner: User;
	public createdAt;
	public updatedAt;
	public type;
	public picture;
	public national;
	public urlPicture;
	public category;
	public startAt;
	public stopAt;
	public location;
	public address;
	public city;
	public zipCode;
	public capacity;
	public visioUrl;
	public canMessaging;
	public isPrivate;
	public select: boolean;
	public participants: User[];
	public comments: Comment[];
	public startAtMonth;
	public startAtMonthCut;
	public startAtYears;
	public startAtDay;
	public startAtHours;
	public startAtMinutes;
	public startAtDayNumber;
	public stopAtMonth;
	public stopAtMonthCut
	public stopAtYears;
	public stopAtDay;
	public stopAtHours;
	public stopAtMinutes;
	public stopAtDayNumber;
	public month = [
		"Janvier",
		"Fevrier",
		"Mars",
		"Avril",
		"Mai",
		"Juin",
		"Juillet",
		"Aout",
		"Septembre",
		"Octobre",
		"Novembre",
		"Décembre",
	];
	public day = [
		"Dimanche",
		"Lundi",
		"Mardi",
		"Mercredi",
		"Jeudi",
		"Vendredi",
		"Samedi",
	];


	public monthCut = [
		"Janv",
		"Fev",
		"Mars",
		"Avr",
		"Mai",
		"Juin",
		"Juil",
		"Août",
		"Sept",
		"Oct",
		"Nov",
		"Déc",
	];


	public geoLon: number;
	public geoLat: number;
	public distance = 0;
	public files: File[] = [];

	constructor(data = null) {
		this.id = 0;
		this.uuid = "";
		this.title = "";
		this.description = "";
		this.posts = [];
		this.comments = [];
		this.owner = new User();
		this.createdAt = new Date().toString();
		this.updatedAt = new Date().toString();
		this.type = "";
		this.picture = "";
		this.urlPicture = "";
		this.category = "";
		this.category = "";
		this.national = false;
		this.startAt = new Date().toISOString();
		this.stopAt = "";
		this.location = "";
		this.address = "";
		this.city = "";
		this.zipCode = "";
		this.capacity = "";
		this.visioUrl = "";
		this.canMessaging = false;
		this.isPrivate = false;
		this.select = false;
		this.participants = [];
		this.geoLat = 0;
		this.geoLon = 0;
		this.distance = 0;
		this.files = [];
		if (data) {
			this.id = data.id;
			this.uuid = data.uuid;
			this.title = data.title;
			this.national = data.national;
			this.description = data.description;

			if (data.posts !== undefined) {
				this.posts = data.posts.map((item) => {
					return new ForumPost(item);
				});
			}
			if (data.comments !== undefined) {
				this.comments = data.comments.map((item) => {
					return new Comment(item);
				});
			}
			if (data.owner !== undefined) {
				this.owner = new User(data.owner);
			}
			if (data.files !== undefined) {
				this.files = data.files.map(item => {
					return new File(item);
				});
			}
			this.geoLat = data.geoLat;
			this.geoLon = data.geoLon;
			this.createdAt = data.createdAt;
			this.updatedAt = data.updatedAt;
			this.type = data.type;
			this.picture = data.picture;
			if (this.picture !== "" && this.picture !== undefined) {
				this.urlPicture = environment.host + "media/" + data.picture;
			}
			this.category = data.category;
			this.startAt = data.startAt;
			this.stopAt = data.stopAt;
			this.location = data.location;
			this.address = data.address;
			this.city = data.city;
			this.zipCode = data.zipCode;
			this.capacity = data.capacity;
			this.visioUrl = data.visioUrl;
			this.canMessaging = data.canMessaging;
			this.isPrivate = data.ispublic;
			this.distance = data.distance;
			if (data.participants !== undefined) {
				this.participants = data.participants.map((item) => {
					return new User(item);
				});
			}
		}
		if (this.startAt !== "" && this.startAt !== undefined) {
			const date = new Date(this.startAt);
			this.startAtDayNumber = date.getDate();
			this.startAtDay = this.day[date.getDay()];
			this.startAtYears = date.getFullYear();
			this.startAtMonth = this.month[date.getMonth()];
			this.startAtMonthCut = this.monthCut[date.getMonth()];
			this.startAtHours =
				(date.getHours() < 10 ? "0" : "") + date.getHours();
			this.startAtMinutes =
				(date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
		}
		if (this.stopAt !== "" && this.stopAt !== undefined) {
			const date = new Date(this.stopAt);
			this.stopAtDayNumber = date.getDate();
			this.stopAtDay = this.day[date.getDay()];
			this.stopAtYears = date.getFullYear();
			this.stopAtMonth = this.month[date.getMonth()];
			this.stopAtMonthCut = this.monthCut[date.getMonth()];
			this.stopAtHours =
				(date.getHours() < 10 ? "0" : "") + date.getHours();
			this.stopAtMinutes =
				(date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
		}
	}
}
