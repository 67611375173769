import {Component, OnInit} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {ModalService} from "../../../shared/services/utils/modal.service";
import {Event} from "../../../shared/models/event";
import {ResponsiveService} from "../../../shared/services/utils/responsive.service";

@Component({
	selector: "app-modal-thematic-event",
	templateUrl: "./thematic-event.component.html",
	styleUrls: ["./thematic-event.component.scss"],
})
export class ThematicEventModalComponent implements OnInit {
	items: Event[] = [];
	title: String = "Événements";
	description: String = "";
	category: String = "";

	constructor(private modalController: ModalController, private modalService: ModalService, public responsiveService: ResponsiveService) {
	}

	ngOnInit(): void {

		this.items = this.items.filter((item: any) => {
			if (item.startAt) {
				return new Date(item.startAt).getTime() >= new Date().getTime();
			} else {
				return true;
			}
		});

		this.items.sort((a, b) => {
			const dateA = new Date(a.startAt);
			const dateB = new Date(b.startAt);
			return dateA.getTime() - dateB.getTime();
		});
	}

	addEvent() {
		this.modalService.openEventCreate(new Event(), 'Événement', this.category, () => {

		});
	}

	openEvent(event: Event) {
		this.modalService.openEvent(event, 'Événement', () => {

		});
	}


	dismissModal() {
		this.modalController.dismiss({
			dismissed: true,
		});
	}
}
