<div [class]="responsive.isDesktop()?'ion-content-all-desktop':'ion-content-all'">
	<ion-item (click)="dismissModal()" class="header" lines="none">
		<ion-icon name="chevron-back-outline"></ion-icon>
		<ion-label></ion-label>
	</ion-item>
	<div style="width:100%;height:100%;">
		<iframe [src]="url | safe" allowfullscreen frameborder="0" height="100%"
				style="width:100%;height:calc(100% - 46px);"></iframe>
	</div>
</div>
