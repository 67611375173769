<ion-header class="ion-no-border" translucent>
    <ion-toolbar>
        <ion-title class="ion-text-center">Modifier votre photo de profil</ion-title>
        <ion-buttons slot="start">
            <ion-button (click)="dismissModal()">
                <ion-icon name="close-outline" style="font-size: 30px;color: var(--cftc-color-6);"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
            <ion-button>

            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content fullscreen>
    <ion-grid style="height: 100%;">
        <ion-row style="    height: 123px;margin-bottom: 20px;">
            <ion-col>
                <ion-avatar>
                    <img height="123px" src="{{user.picture}}">
                </ion-avatar>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="error !==''">
            <ion-col>
                {{error}}
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <ion-button (click)="picture()" class="bg-yellow bd-yellow" style="width: 100%;">
                    <ion-icon name="images-outline" style="font-size: 30px;margin-right: 10px"></ion-icon>
                    Choisir dans vos images
                </ion-button>
                <ion-button (click)="camera()" class="bg-yellow bd-yellow" style="width: 100%;">
                    <ion-icon name="camera-outline" style="font-size: 30px;margin-right: 10px"></ion-icon>
                    Prendre une photo
                </ion-button>
                <ion-button (click)="delete()" class="bg-transparent bd-green text-green" style="width: 100%;">Supprimer
                    votre
                    photo actuelle
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
