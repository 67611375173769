<ion-card [class.desktop]="responsive.isDesktop()">
	<ion-card-content>
		<ion-grid style="">
			<ion-row>
				<ion-col class="title" size="12">
					De nouvelles notifications
					sont disponibles
				</ion-col>
				<ion-col class="description" size="12">
					Vous pouvez maintenant recevoir vos notifications directement sur votre téléphone. Pour cela,
					autorisez CFTC l’App à vous en envoyer !
				</ion-col>
				<ion-col size="6">
					<ion-button (click)="dismissModal()" class="bd-green bg-transparent text-green btn-map"
								style="width: 95%"
								type="button">
						Plus tard
					</ion-button>
				</ion-col>
				<ion-col size="6" style="text-align: right">
					<ion-button (click)="accept()" class="bd-yellow bg-yellow btn-map"
								style="width: 95%;"
								type="button">
						Autoriser
					</ion-button>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ion-card-content>
</ion-card>
