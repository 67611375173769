import {Platform} from '@ionic/angular';
import {Component, Input, OnInit} from '@angular/core';
import {ResponsiveService} from '../../../../shared/services/utils/responsive.service';
import {Article} from '../../../../shared/models/article';
import {Video} from 'src/app/shared/models/video';

@Component({
	selector: 'app-video-find-renderer',
	templateUrl: './video-find.component.html',
	styleUrls: ['./video-find.component.scss'],
})
export class VideoFindRendererComponent implements OnInit {

	@Input() index;
	@Input() video: Video;

	constructor(public platform: Platform, public responsive: ResponsiveService) {
	}

	ngOnInit(): void {
	}

}
