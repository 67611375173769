<ion-item (click)="dismissModal()" lines="none" style="--background: white!important;">
	<ion-icon class="back-icon" name="chevron-back-outline"></ion-icon>
	<ion-label *ngIf="!isOffer" class="back">Forum</ion-label>
	<ion-label *ngIf="isOffer" class="back">Petites Annonces</ion-label>
</ion-item>
<ion-content style="--background: white;">
	<ion-grid style="padding: 15px;">
		<ion-row>
			<ion-col *ngIf="subject && subject.uuid === '' && !isOffer" class="title" size="12">
				Créer un sujet sur le forum
			</ion-col>
			<ion-col *ngIf="subject && subject.uuid !== '' && !isOffer" class="title" size="12">
				Modifier le sujet :
			</ion-col>
			<ion-col *ngIf="subject && subject.uuid === '' && isOffer" class="title" size="12">
				Créer une petite annonce
			</ion-col>
			<ion-col *ngIf="subject && subject.uuid !== '' && isOffer" class="title" size="12">
				Modifier une petite annonce :
			</ion-col>
			<ion-col class="sub-title" size="12">
				<ion-label>Nom du sujet*</ion-label>
			</ion-col>
			<ion-col size="12">
				<ion-input *ngIf="!isOffer" [(ngModel)]="subject && subject.title" autocapitalize="sentences"
						   autocomplete="on"
						   autocorrect="on" placeholder="Entre un sujet de manière claire"
						   spellcheck="true"></ion-input>
				<ion-input *ngIf="isOffer" [(ngModel)]="subject && subject.title" autocapitalize="sentences"
						   autocomplete="on"
						   autocorrect="on" placeholder="Que proposez ou recherchez-vous ?"
						   spellcheck="true"></ion-input>
			</ion-col>
			<ion-col class="sub-title" size="12">
				<ion-label *ngIf=" !isOffer">Votre question en détails*</ion-label>
				<ion-label *ngIf=" isOffer">Votre annonce en détails*</ion-label>
			</ion-col>
			<ion-col size="12">
				<ion-textarea #myTextArea *ngIf="!isOffer" [(ngModel)]="subject && subject.description"
							  autocomplete="on"
							  autocorrect="on"
							  placeholder="Donnez un maximum de détails pour que la communauté puisse vous aider"
							  rows="5"
							  spellcheck="true"
							  type="text"></ion-textarea>
				<ion-textarea #myTextArea *ngIf="isOffer" [(ngModel)]="subject && subject.description" autocomplete="on"
							  autocorrect="on"
							  placeholder="Donnez un maximum de détails pour décrire ce que vous cherchez ou ce que vous proposez à la communauté CFTC"
							  rows="5"
							  spellcheck="true"
							  type="text"></ion-textarea>
			</ion-col>
			<ion-col *ngIf="!isOffer" class="sub-title" size="12">
				<ion-label>Thématique *</ion-label>
			</ion-col>
			<ion-col *ngIf="!isOffer" size="12">
				<ion-item *ngIf="subject" class="input" lines="none">
					<ion-select (ionChange)="thematiqueChange($event)"
								[(ngModel)]="subject.topic.uuid"
								[interfaceOptions]="{'cssClass': 'mycomponent-wider-popover'}"
								interface="popover"
								mode="ios" placeholder="Choisissez la thématique*">
						<ion-select-option *ngFor="let topic of topics"
										   [value]="topic.uuid">{{ topic.title }}
						</ion-select-option>
					</ion-select>
					<ion-icon name="chevron-down-outline" slot="end" style="position: absolute; right:10px;"></ion-icon>
				</ion-item>
			</ion-col>
			<ion-col class="sub-title" size="12">
				<ion-label>Joindre un / plusieurs fichiers</ion-label>
			</ion-col>
			<ion-col size="12" style="margin-bottom: 5px !important;">
				<ion-item class="file ion-no-padding" lines="none">
					<ion-label class="ion-text-wrap" fixed slot="start">Formats .jpg, .pdf, .png (max. 10Mo / fichier)
					</ion-label>
					<input #fileInput (change)="chooseFile($event)" accept=".jpg,.png,.pdf" style="display:none;"
						   type="file">
					<ion-button (click)="fileInput.click()" slot="end">
						<ion-icon name="push-outline"></ion-icon>
					</ion-button>
				</ion-item>
			</ion-col>
			<ion-col *ngIf="subject && subject.files.length>0" size="12">
				<ion-item *ngFor="let file of subject.files;let index=index" class="files ion-no-padding" lines="none">
					<ion-icon name="attach-outline"></ion-icon>
					<ion-label>{{ file.name }}</ion-label>
					<ion-icon (click)="removeImage(index)" name="close-outline" style="margin-right: 10px"></ion-icon>
				</ion-item>
			</ion-col>

			<ion-col *ngIf="subject && subject.uuid === ''" size="12">
				<ion-item class="picture ion-no-padding check" lines="none">
					<ion-label class="ion-text-wrap">J’accepte les conditions d’utilisation du forum</ion-label>
					<ion-checkbox [(ngModel)]="cg" mode="md" slot="start"></ion-checkbox>
				</ion-item>
			</ion-col>

			<ion-col size="12">
				<ion-button (click)="edit()" *ngIf="subject && subject.uuid !== ''"
							[disabled]="(subject && subject.title === '') || (subject && subject.description ==='')"
							class="bg-yellow bd-yellow text-white"
							style="width: 100%;margin: 0;margin-bottom: 10px">
					Modifier le sujet
				</ion-button>
				<ion-button (click)="delete()" *ngIf="subject && subject.uuid !== ''"
							class="bg-transparent bd-red text-red"
							style="width: 100%;margin: 0;margin-bottom: 10px">
					Supprimer le sujet
				</ion-button>
				<ion-button (click)="archiver()" *ngIf="subject && subject.uuid !== ''"
							class="bg-transparent bd-gray text-gray"
							style="width: 100%;margin: 0">
					Archiver le sujet
				</ion-button>
				<ion-button (click)="save()" *ngIf="subject && subject.uuid === ''"
							[disabled]="(subject && subject.title === '') || (subject && subject.description ==='') || !cg"
							class="bg-yellow bd-yellow text-white"
							style="width: 100%;margin: 0">
					Créer un sujet
				</ion-button>
			</ion-col>
		</ion-row>
	</ion-grid>
</ion-content>

