import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
	selector: 'app-banner',
	templateUrl: './banner.component.html',
	styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
	@Input() hidden = false;

	constructor(
		private route: ActivatedRoute,
	) {
	}

	ngOnInit() {
	}

	open() {
		if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
			window.open('http://play.google.com/store/apps/details?id=fr.cftc.syndicat&hl=en', '_system');
		}
		if (navigator.userAgent.toLowerCase().indexOf('iphone') > -1) {
			window.open('https://apps.apple.com/fr/app/cftc-lapp/id1576169174', '_system');
		}
	}

	close() {
		this.hidden = !this.hidden;
	}
}
