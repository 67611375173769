<ion-header class="ion-no-border" translucent >
	<ion-toolbar>
		<ion-title [class]="responsive.isMobile() ? 'title' : 'title-desktop'"  class="ion-text-center">Devenir élu CSE</ion-title>
		<ion-buttons slot="start">
			<ion-button (click)="dismissModal()" class="button-close">
				<ion-icon name="close-outline" style="font-size: 42px;color: var(--cftc-color-1);" ></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>
	<ion-content style="--overflow: hidden;" fullscreen>
		<ion-row class="row-align">
			<ion-col class="col-margin" style="margin-top: 28px;">
				<ion-label [class]="responsive.isMobile() ? 'title-text' : 'title-text-desktop'">
					Merci pour votre demande !
				</ion-label>
			</ion-col>
		</ion-row>
		<ion-row class="row-align">
			<ion-col class="col-margin">
				<ion-label [class]="responsive.isMobile() ? 'description' : 'description-desktop'">
					Vous serez bientôt contacté par votre structure CFTC.
					Vous avez par ailleurs reçu un mail de confirmation contenant un kit "Je veux devenir élu CSE".
				</ion-label>
			</ion-col>
		</ion-row>
	</ion-content>

