import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {HttpService} from '../services/utils/http.service';
import {ResponsiveService} from '../services/utils/responsive.service';
import {Capacitor} from '@capacitor/core';

@Injectable()
export class FirstGuard {
	constructor(private router: Router, private httpService: HttpService, private responsiveService: ResponsiveService) {
	}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | boolean {
		if (this.httpService.token !== undefined && this.httpService.token !== '') {
			return true;
		}
		if (this.responsiveService.isDesktop()) {
			localStorage.setItem('first', 'true');
		}
		const isFirst = localStorage.getItem('first') === 'true';
		if (!isFirst && Capacitor.getPlatform() !== 'web') {
			// this.router.navigate(["/onboarding"]);
			return true;
		} else {
			return true;
		}

		return false;
	}
}
