<ion-card>
	<ion-card-header>
		<ion-item lines="none">
			Partenaire CFTC
			<ion-button (click)="dismissModal()" [disabled]="counter > 0" class="btn-close" clear icon-only slot="end">
				<ion-icon *ngIf="counter <= 0" name="close-outline"></ion-icon>
				<ion-label *ngIf="counter > 0">{{counter}}</ion-label>
			</ion-button>
		</ion-item>
	</ion-card-header>
	<ion-card-content>
		<div (click)="open(pub.btnUrl)" [style.background-image]="'url('+pub.image+')'" class="img">

		</div>

	</ion-card-content>
</ion-card>
