import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ToastService} from '../../../shared/services/utils/toast.service';
import {ModalService} from '../../../shared/services/utils/modal.service';
import {ModalController} from '@ionic/angular';
import {ResponsiveService} from '../../../shared/services/utils/responsive.service';
import {UserService} from '../../../shared/services/user.service';
import {lastValueFrom} from 'rxjs';

@Component({
	selector: 'app-suppression-reactive-account',
	templateUrl: './suppression-reactive-account.component.html',
	styleUrls: ['./suppression-reactive-account.component.scss'],
})
export class SuppressionReactiveAccountComponent implements OnInit, AfterViewInit {

	title: string;
	uuid: string;
	isDeleted = false;
	description = '';

	constructor(
		private router: Router,
		private toastService: ToastService,
		private modalService: ModalService,
		public modalController: ModalController,
		public responsive: ResponsiveService,
		private userService: UserService
	) {
	}

	ngOnInit(): void {
	}

	ngAfterViewInit() {

	}

	dismissModal() {
		this.modalController.dismiss({
			dismissed: true,
		});
	}

	async activeAccount() {
		try {
			await lastValueFrom(this.userService.reactiveAccount(this.uuid));
			this.dismissModal();
		} catch (e) {
			console.error(e);
		}
	}

	openHome() {
		this.dismissModal();
	}
}
