import {Injectable} from "@angular/core";
import {AlertController} from "@ionic/angular";
import {ToastService} from "../services/utils/toast.service";

@Injectable()
export class AlertHelper {
	constructor(private alertController: AlertController, private toastService: ToastService) {
	}

	/*	async showSignal(callback: (data: any) => void) {
			const alert = await this.alertController.create({
				cssClass: "signalement",
				header: "Signaler un contenu",
				inputs: [
					{
						name: "text",
						type: "text",
						placeholder: "Les raisons du signalement"
					},
				],
				buttons: [
					{
						text: "Annuler",
						role: "cancel",
						handler: () => {
						}
					}, {
						text: "Envoyer",
						handler: (data) => {
							callback(data);
							this.toastService.show("Merci pour votre signalement, il sera traité au plus vite.");
						}
					}
				]
			});

			await alert.present();
		}*/


}
