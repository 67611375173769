import {ThematicSection} from "./thematicSection";

export class ThematicCollection {
	public title = '';
	public subtitle = '';
	public btn_url = '';
	public btn_label = '';
	public btn_type = '';
	public btn_uuid = '';
	public description = '';
	public image = '';
	public sections: ThematicSection[] = [];
	public data = null;

	constructor(data = null) {
		if (data) {
			this.title = data.title;
			this.subtitle = data.subtitle;
			this.btn_url = data.btn_url;
			this.btn_label = data.btn_label;
			this.btn_type = data.btn_type;
			this.btn_uuid = data.btn_uuid;
			this.description = data.description;
			this.image = data.image;
			if (data.sections) {
				this.sections = data.sections.map((section: any) => {
					return new ThematicSection(section);
				});
			}
			if (data.data) {
				this.data = data.data;
			}
		}
	}
}
