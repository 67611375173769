import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ResponsiveService} from '../../../shared/services/utils/responsive.service';
import {UserService} from '../../../shared/services/user.service';
import {ToastService} from '../../../shared/services/utils/toast.service';
import {AppUpdate} from "@capawesome/capacitor-app-update";

@Component({
	selector: 'app-update',
	templateUrl: './update.component.html',
	styleUrls: ['./update.component.scss'],
})
export class UpdateComponent implements OnInit {

	constructor(
		public modalController: ModalController,
		public responsive: ResponsiveService,
		public userService: UserService,
		public toastService: ToastService
	) {

	}


	ngOnInit(): void {
	}

	dismissModal() {
		localStorage.setItem('firstStartApp', 'true');
		this.modalController.dismiss({
			dismissed: true
		});
	}


	async accept() {
		await AppUpdate.openAppStore()
		//Capacitor.getPlatform() === 'android' ? window.open('https://play.google.com/store/apps/details?id=fr.cftc.syndicat', '_system') : window.open('https://apps.apple.com/fr/app/fr.cftc.syndicat/id1576169174', '_system');
	}

}
