import {Injectable} from "@angular/core";
import {GoogleAnalyticsService} from "ngx-google-analytics";
import {User} from "src/app/shared/models/user";

@Injectable()
export class AnalyticsService {

	constructor(protected $gaService: GoogleAnalyticsService) {

	}

	/**
	 * Track page view
	 * @param path
	 * @param title
	 * @param user
	 */
	pageView(path: string, title: string, user: User) {
		if (user.cookieOptions.analytics) {
			this.$gaService.pageView(path, title);
		}
	}

	/**
	 * Track event
	 * @param action
	 * @param category
	 * @param label
	 * @param user
	 */
	event(action: string, category: string, label: string, user: User) {
		if (user.cookieOptions.analytics) {
			this.$gaService.event(action, category, label);
		}
	}
}
