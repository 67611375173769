<ion-item lines="none">
    <ion-grid>
        <ion-row>
            <ion-col class="title" size="12">
                {{item.title}}
            </ion-col>
            <ion-col class="subject" size="12">
                {{item.posts.length}} réponse<span *ngIf="item.posts.length>1">s</span> | Créé
                par {{item.owner.lastName}} {{item.owner.firstName[0]}}.
                | {{item.createdAt | date:"dd MM YYYY"}}
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-item>
