import {Component, OnInit} from '@angular/core';
import {ResponsiveService} from '../../../shared/services/utils/responsive.service';
import {EventService} from '../../../shared/services/event.service';
import {NotificationService} from 'src/app/shared/services/alert.service';
import {Router} from '@angular/router';
import {Alert} from 'src/app/shared/models/alert';
import {ModalService} from 'src/app/shared/services/utils/modal.service';
import {ModalController} from '@ionic/angular';
import {ForumService} from 'src/app/shared/services/forum.service';

@Component({
	selector: 'app-modal-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss'],
})
export class NotificationModalComponent implements OnInit {

	alerts: Alert[] = [];

	constructor(
		public modalService: ModalService,
		public modalController: ModalController,
		public responsive: ResponsiveService,
		public eventService: EventService,
		public forumService: ForumService,
		private router: Router,
		private notificationService: NotificationService,
	) {

	}


	ngOnInit(): void {
		this.init();
	}

	ionViewWillEnter() {
		this.alerts = [];
		this.init();
	}

	init() {
		this.notificationService.getAlerts().subscribe((alerts: Array<Alert>) => {
			this.alerts = alerts;
		});
	}

	open(item: Alert) {
		switch (item.category) {
			case 'forum':
				this.forumService.getSubject(item.uuidDestination).subscribe(value => {
					if (value.topic.title === 'Petites annonces') {
						this.router.navigate(['/community/offers/' + value.uuid]);
					} else {
						this.router.navigate(['/community/forum/' + value.topic.uuid + '/' + value.uuid]);
					}
					this.dismissModal();
				}, error => {
				});

				break;
			case 'event':
				this.eventService.get(item.uuidDestination).subscribe(value => {
					this.dismissModal();
					this.modalService.openEvent(value, 'Notification', data => {
					});
				}, error => {
				});
				break;
			case '':
				this.router.navigate(['/community/offers/' + item.uuidDestination]);
				this.dismissModal();
				break;
		}
		this.notificationService.deleteAlert(item.uuid).subscribe(value => {
			this.init();
		}, error => {
		});
	}

	dismissModal() {
		this.modalController.dismiss({
			dismissed: true,
		});
	}
}
