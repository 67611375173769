import {Platform} from '@ionic/angular';
import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ResponsiveService} from '../../../../shared/services/utils/responsive.service';
import {Ressourcerie} from "../../../../shared/models/ressourcerie";

@Component({
	selector: 'app-ressourcerie-thematic-renderer',
	templateUrl: './ressourcerie-thematic.component.html',
	styleUrls: ['./ressourcerie-thematic.component.css'],
})
export class RessourcerieThematicRendererComponent implements OnInit, OnDestroy, AfterViewInit {

	@Input() index;
	@Input() item: Ressourcerie;
	@Input() isNotHome: boolean;
	@Input() isList: boolean;
	@Input() isDecodeur: boolean;

	colors = {
		'3 min pour comprendre': '#fabb1b',
		Dossier: '#22b29d',
		Quiz: '#adce6a',
		Vidéo: '#1b316e',
		Infographie: '#7ab1d9',
		'Publication CFTC': '#276ab2',
		'Nos réponses à vos questions': '#103F93',
	};

	constructor(public platform: Platform, public responsive: ResponsiveService) {
	}


	ngOnInit(): void {
		/*const tag = document.createElement('script');
		tag.src = 'https://www.youtube.com/iframe_api';
		document.body.appendChild(tag);*/
	}

	ngAfterViewInit() {

	}

	ionViewDidLeave() {

	}

	ngOnDestroy() {

	}

	onReady() {

	}

	onStateChange(event) {

	}

}
