import {User} from "./user";
import {Message} from "./message";

export class Chat {
	public id = 0;
	public uuid = "";
	public name = "";
	public createdAt = new Date().toString();
	public updatedAt = new Date().toString();
	public participants: User[] = [];
	public messages: Message[] = [];
	public isNewMessage = false;
	public select = false;
	public indexs: any[] = [];
	public totalRecods: any[] = [];
	public currentIndex = -1;
	public blackListed = false;
	constructor(data = null) {
		if (data) {
			this.id = data.id;
			this.uuid = data.uuid;
			this.name = data.name;
			this.blackListed = data.blacklisted;
			this.isNewMessage = data.isNewMessage;
			if (data.participants !== undefined) {
				this.participants = data.participants.map(item => {
					return new User(item);
				});
			}
			if (data.messages !== undefined) {
				this.messages = data.messages.map(item => {
					return new Message(item);
				});
			}

			this.createdAt = data.createdAt;
			this.updatedAt = data.updatedAt;
		}
	}
}
