<ion-card>
	<ion-card-content>
		<ion-grid>
			<ion-row>
				<ion-col class="title" size="12">
					Votre compte a été supprimé
				</ion-col>
				<ion-col class="description" size="12">
					Votre compte CFTC l'App a bien été supprimé. Désormais vous ne pouvez plus vous connecter avec vos
					identifiants et les utilisateurs de CFTC l'App ne peuvent plus vous trouver. Il est cependant encore
					possible de réactiver votre compte si vous le souhaitez. Vous avez pour cela 30 jours. Une fois les
					30 jours passés, votre compte ainsi que son contenu seront définitivement supprimés.
				</ion-col>
				<ion-col class="ion-text-center" size="12">
					<ion-button (click)="activeAccount()" class="bg-yellow" style="width: 100%">
						Réactiver mon compte
					</ion-button>
				</ion-col>
				<ion-col (click)="openHome()" class="ion-text-center home" size="12">
					Retourner à l’accueil
				</ion-col>
			</ion-row>
		</ion-grid>
	</ion-card-content>
</ion-card>
