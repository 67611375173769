import {Component, OnInit} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {ResponsiveService} from "../../../shared/services/utils/responsive.service";
import {EventService} from "../../../shared/services/event.service";
import {Router} from "@angular/router";
import {AlertHelper} from "../../../shared/helpers/alert.helper";
import {UserService} from "../../../shared/services/user.service";
import {ToastService} from "../../../shared/services/utils/toast.service";
import {MessengerService} from "../../../shared/services/messenger.service";
import {ModalService} from "../../../shared/services/utils/modal.service";
import {Article} from "../../../shared/models/article";
import {Share} from "@capacitor/share";

@Component({
	selector: "app-modal-profile",
	templateUrl: "./article.component.html",
	styleUrls: ["./article.component.scss"],
})
export class ArticleModalComponent implements OnInit {
	article: Article = new Article();
	url: string;

	constructor(
		private router: Router, private toastService: ToastService,
		private alertHelper: AlertHelper, public modalController: ModalController,
		public responsive: ResponsiveService, public eventService: EventService,
		private userService: UserService, private messengerService: MessengerService,
		private modalService: ModalService) {

	}


	ngOnInit(): void {

	}

	async share() {
		await Share.share({
			title: this.article.title,
			text: this.article.description,
			url: this.article.url,
			dialogTitle: "CFTC Article",
		});
	}

	dismissModal() {
		this.modalController.dismiss({
			dismissed: true,
		});
	}

	openDecoder() {
		window.open(this.article.url, "_blank");
	}

	checkDomain() {
		return this.article.url.indexOf("cftc-ledecodeur.fr") !== -1;
	}


}
