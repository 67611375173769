import {environment} from "../../../environments/environment";

export class File {
	public id = 0;
	public uuid = "";
	public name = "";
	public filename: any = "";
	public createdAt = new Date().toString();
	public updatedAt = new Date().toString();

	constructor(data = null) {
		if (data) {
			this.id = data.id;
			this.uuid = data.uuid;
			this.name = data.name;
			this.filename = environment.host + "media/" + data.filename + "?cache=" + new Date().getTime();
			this.createdAt = data.createdAt;
			this.updatedAt = data.updatedAt;
		}
	}
}
