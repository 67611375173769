<div class="ion-content-all">
	<ion-item lines="none" style="--background: white!important;--min-height: 66px;--max-height: 66px">
		<ion-icon (click)="dismissModal()" *ngIf="responsive.isMobile()" class="back-icon"
				  name="chevron-back-outline"></ion-icon>
		<ion-label class="back">Actualité</ion-label>
		<ion-icon (click)="dismissModal()" *ngIf="responsive.isDesktop()" class="back-icon" name="close-outline"
				  slot="end"></ion-icon>
	</ion-item>
	<ion-content>
		<ion-grid>
			<ion-row>
				<ion-col class="title" size="12">{{video.title}}</ion-col>
				<ion-col *ngIf="video.date !=='' && video.date !== undefined" class="date" size="12"
						 style="margin-top: 15px">{{video.date |
					date:"dd MM YYYY"}} | Catégorie d’article
				</ion-col>
			</ion-row>
		</ion-grid>
		<iframe #videoPlayer [src]="video.youtubeUrl | safe" allow="autoplay" allowfullscreen frameborder="0"
				height="500" style=" border-radius: 8px;" title="YouTube video player" width="100%"></iframe>
	</ion-content>
</div>
