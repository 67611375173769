import {Component, OnInit} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {ResponsiveService} from "../../../shared/services/utils/responsive.service";
import {EventService} from "../../../shared/services/event.service";

@Component({
	selector: "app-modal-access",
	templateUrl: "./access.component.html",
	styleUrls: ["./access.component.scss"],
})
export class AccessModalComponent implements OnInit {

	constructor(
		public modalController: ModalController,
		public responsive: ResponsiveService,
		public eventService: EventService,
	) {

	}


	ngOnInit(): void {
	}

	moreToKnow() {
		window.open('https://www.cftc.fr/adherer/#sympathisant', '_blank');
	}

	dismissModal(result) {
		this.modalController.dismiss({
			dismissed: true,
			result
		});
	}


	open() {
		window.open("https://www.cftc.fr/adherer", "_blank");
	}
}
