import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {User} from '../models/user';
import {HttpService} from './utils/http.service';
import {map} from 'rxjs/operators';
import {PaginationService} from './pagination';
import {GeoService} from './geo.service';
import {Router} from '@angular/router';
import {MenuController, Platform} from '@ionic/angular';

@Injectable({
	providedIn: 'root'
})
export class UserService extends PaginationService {

	userAuth: BehaviorSubject<User>;
	users: BehaviorSubject<Array<User>>;
	isConnected: boolean;
	isLocation = false;

	constructor(
		private httpClient: HttpClient,
		private https: HttpService,
		private geoService: GeoService,
		private router: Router,
		private httpService: HttpService,
		public platform: Platform,
		private menuCtrl: MenuController,
	) {
		super();
		this.userAuth = new BehaviorSubject<User>(null);
		this.users = new BehaviorSubject<Array<User>>(null);
		this.isConnected = false;
	}

	getUserAuthValue() {
		const value = this.userAuth.getValue();
		console.log('getUserAuthValue', value)
		return value
	}

	/**
	 *
	 * @param username
	 * @param password
	 */
	signIn(username: string, password: string): Observable<any> {
		return this.https.post('user/auth', {username, password}).pipe(
			map((res: any) => {
				const user: User = new User(res.user);
				if (res.user.removedAt !== null && res.user.removedAt !== undefined && res.user.removedAt !== '') {
					return user;
				} else {
					this.https.token = res.token;
					this.userAuth.next(user);
					this.isConnected = true;
					localStorage.setItem('token', this.https.token);
					localStorage.setItem('user', JSON.stringify(res.user));
					return this.getUserAuthValue();
				}


			}));
	}

	signInSSO(token: string): Observable<any> {
		return this.https.post('user/auth/sso', {token}).pipe(
			map((res: any) => {
				this.https.token = res.token;
				const user: User = new User(res.user);
				this.userAuth.next(user);
				this.isConnected = true;
				localStorage.setItem('token', this.https.token);
				localStorage.setItem('user', JSON.stringify(res.user));
				localStorage.setItem('tokenSSO', JSON.stringify(token));
				return this.getUserAuthValue();
			}));
	}

	/**
	 *
	 * @param email
	 * @param firstName
	 * @param lastName
	 * @param departmentCode
	 * @param password
	 * @param confirmPassword
	 * @param optIn
	 * @param prospectingUsable
	 */
	signUp(email: string, firstName: string, lastName: string, birthday: string/*, departmentCode: string*/): Observable<any> {
		return this.https.post('user/create', {
			email,
			firstName,
			lastName,
			birthday,
			// departmentCode
		});

	}

	signUpInaric(email: string, firstName: string, lastName: string, inaric: string, birthday: string): Observable<any> {
		return this.https.post('user/create-inaric', {
			email,
			firstName,
			lastName,
			inaric,
			birthday
		});

	}

	setLocation(geoLat: string, geoLon: string): Observable<any> {
		return this.https.post('user/position', {
			geoLat,
			geoLon,
		});

	}

	/**
	 *
	 */
	getsLatLon(distance): Observable<Array<User>> {
		return this.https.post('user/find-lat-lon',
			{
				distance
			}
		).pipe(
			map((res: any) => {
				const newArray = res.map(item => {
					return new User(item);
				});
				this.users.next(newArray);
				return this.users.getValue();
			})
		);
	}

	/**
	 *
	 */
	getQRCode(): Observable<any> {
		return this.https.get('user/qrcode').pipe(
			map((res: any) => {
				return res;
			})
		);
	}

	/**
	 *
	 */
	getPresenceCongres(): Observable<any> {
		return this.https.get('user/presence-congres').pipe(
			map((res: any) => {
				return res;
			})
		);
	}

	/**
	 *
	 */
	getUserAuth(): Observable<User> {
		return this.userAuth;
	}

	/**
	 *
	 * @param page
	 * @param filters
	 * @param perPage
	 * @param all
	 * @param concat
	 */
	gets(page = 1, filters: object = {}, perPage: number = 0, all: boolean = false, concat = true): Observable<Array<User>> {

		if (page === 1 || !concat) {
			this.users.next([]);
		}
		if (!all && this.nextPage === null) {
			return of(this.users.getValue());
		}

		let url = 'directory?page=' + page;

		if (perPage !== 0) {
			url += '&per_page=' + perPage;
		}

		return this.https.post(url, filters).pipe(
			map((res: any) => {
				this.from = res.from;
				this.to = res.to;
				this.perPage = res.per_page;
				this.total = res.total;
				this.currentPage = res.current_page;
				this.prevPage = res.prev_page;
				this.nextPage = res.next_page;
				const newArray = res.data.map(item => {
					return new User(item);
				});
				let currentArray = this.users.getValue() ? this.users.getValue() : [];
				this.users.next(currentArray.concat(newArray));

				return this.users.getValue();
			})
		);
	}

	/**
	 *
	 * @param user
	 * @param relations
	 * @private
	 */
	get(user: User, relations: boolean = true): Observable<User> {
		// let code = user.uuid;
		let code = user.inaric;
		if (code === '') {
			code = user.uuid;
		}

		return this.https.get('user/' + code + '/' + (relations ? '1' : '0')).pipe(
			map((res: any) => {
				const user: User = new User(res);
				if (this.userAuth) {
					if (code === this.getUserAuthValue().uuid && this.getUserAuthValue().uuid !== '' || code === this.getUserAuthValue().inaric && this.getUserAuthValue().inaric !== '') {
						this.userAuth.next(user);
						localStorage.setItem('user', JSON.stringify(res));
					}
				}
				return user;
			})
		);
	}

	/**
	 *
	 * @param user
	 */
	update(user: User): Observable<User> {
		return this.https.patch('user', {
			email: user.email,
			address: user.address,
			city: user.city,
			zipCode: user.zipCode,
			cguAccepted: user.cguAccepted,
			optIn: user.optIn,
			prospectingUsable: user.prospectingUsable,
			phoneNumber: user.phoneNumber,
			employer: user.employer,
			visibility: user.visibility
		}).pipe(
			map((res: any) => {
				const user: User = new User(res);
				this.userAuth.next(user);
				localStorage.setItem('user', JSON.stringify(res));
				return this.getUserAuthValue();
			})
		);
	}

	picture(picture: string): Observable<User> {
		return this.https.patch('user/picture', {
			picture
		}).pipe(
			map((res: any) => {
				const user: User = new User(res);
				this.userAuth.next(user);
				localStorage.setItem('user', JSON.stringify(res));
				return this.getUserAuthValue();
			})
		);
	}

	pictureDelete(): Observable<User> {
		return this.https.delete('user/picture').pipe(
			map((res: any) => {
				const user: User = new User(res);
				this.userAuth.next(user);
				localStorage.setItem('user', JSON.stringify(res));
				return this.getUserAuthValue();
			})
		);
	}

	updateVisibility(state): Observable<User> {
		return this.https.get('user/visibility/' + state).pipe(
			map((res: any) => {
				const user: User = new User(res);
				this.userAuth.next(user);
				localStorage.setItem('user', JSON.stringify(res));
				return this.getUserAuthValue();
			})
		);
	}

	updateCGU(optIn, cguAccepted, prospectingUsable, analyticsEnabled): Observable<User> {
		return this.https.patch('user/cgu', {
			optIn,
			cguAccepted,
			prospectingUsable,
			analyticsEnabled
		}).pipe(
			map((res: any) => {
				const user: User = new User(res);
				this.userAuth.next(user);
				localStorage.setItem('user', JSON.stringify(res));
				return this.getUserAuthValue();
			})
		);
	}

	async updateLatLong() {
		const location: any = await this.geoService.location();
		const user = this.getUserAuthValue();
		if (user) {
			if (location) {
				this.isLocation = true;
				user.geoLat = location.coords.latitude;
				user.geoLon = location.coords.longitude;
			} else {
				this.isLocation = false;
				user.geoLat = 0;
				user.geoLon = 0;
			}
		}
		this.userAuth.next(user);

	}

	find(name) {
		return this.https.post('user/find', {
			name
		}).pipe(
			map((res: any) => {
				const newArray = res.map(item => {
					return new User(item);
				});
				this.users.next(newArray);
				return this.users.getValue();
			})
		);
	}

	blacklist(inaric: string): Observable<any> {
		return this.https.post('user/blacklist/' + inaric, {});
	}

	changeNotificationToken(token: string): Observable<any> {
		return this.https.patch('user/notification/token', {token}).pipe(
			map((res: any) => {
				const user: User = this.getUserAuthValue();
				user.notificationToken = res.notificationToken;
				this.userAuth.next(user);
				localStorage.setItem('user', JSON.stringify(user));
				return this.getUserAuthValue();
			})
		);
	}

	changeNotificationOptions(notificationOptions: any): Observable<any> {
		return this.https.patch('user/notification/options', {
			notificationOptions
		}).pipe(
			map((res: any) => {
				const user: User = new User(res);
				this.userAuth.next(user);
				localStorage.setItem('user', JSON.stringify(res));
				return this.getUserAuthValue();
			})
		);
	}

	changeNotification(notificationEnabled: boolean): Observable<any> {
		return this.https.patch('user/notification', {
			notificationEnabled
		}).pipe(
			map((res: any) => {
				const user: User = new User(res);
				this.userAuth.next(user);
				localStorage.setItem('user', JSON.stringify(res));
				return this.getUserAuthValue();
			})
		);
	}

	changeAnalytics(analyticsEnabled: boolean): Observable<any> {
		return this.https.patch('user/analytics', {
			analyticsEnabled
		}).pipe(
			map((res: any) => {
				const user: User = new User(res);
				this.userAuth.next(user);
				localStorage.setItem('user', JSON.stringify(res));
				return this.getUserAuthValue();
			})
		);
	}

	changeFacebook(facebookAnalyticsEnabled: boolean): Observable<any> {
		return this.https.patch('user/facebook-analytics', {
			facebookAnalyticsEnabled
		}).pipe(
			map((res: any) => {
				const user: User = new User(res);
				this.userAuth.next(user);
				localStorage.setItem('user', JSON.stringify(res));
				return this.getUserAuthValue();
			})
		);
	}

	changePassword(password: string, confirmPassword: string): Observable<any> {
		return this.https.patch('user/change-password', {
			password,
			confirmPassword
		});
	}

	sendCandidatureCSE(name: string, address: string, postal: string, city: string, phone: string, email: string): Observable<any> {
		return this.https.post('user/send-candidature-cse', {
			name,
			address,
			postal,
			city,
			phone,
			email
		});
	}

	recovery(email: string): Observable<any> {
		return this.https.post('user/recovery', {email});
	}

	recoveryCode(code: string, email: string): Observable<any> {
		return this.https.post('user/recovery-code', {code, email});
	}

	resetPassword(password: string, confirmPassword: string, email: string, code: string): Observable<any> {
		return this.https.post('user/reset-password', {password, confirmPassword, email, code});
	}

	cgu(): Observable<any> {
		return this.https.get('user/cgu');
	}

	confidentiality(): Observable<any> {
		return this.https.get('user/confidentiality');
	}

	logout() {
		this.menuCtrl.close('mobile');
		localStorage.removeItem('cguAccepted');
		localStorage.removeItem('user');
		localStorage.removeItem('token');
		this.httpService.token = '';
		this.isConnected = false;
		this.userAuth.next(null);
		this.router.navigate(['/auth/signin']);
	}

	// Suppression compte
	deleteAccount(description): Observable<any> {
		return this.https.post('user/delete', {description});
	}

	// Réactivation compte
	reactiveAccount(uuid: string): Observable<any> {
		return this.https.post('user/reactive', {uuid});
	}
}
