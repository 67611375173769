import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {HttpService} from "./utils/http.service";
import {PaginationService} from "./pagination";
import {Article} from "../models/article";
import {Interstitiel} from "../models/interstitiel";

@Injectable({
	providedIn: "root"
})
export class InterstitielService extends PaginationService {

	interstitiels: BehaviorSubject<Array<Article>>;

	constructor(private https: HttpService) {
		super();

		this.interstitiels = new BehaviorSubject<Array<Article>>([]);

	}

	gets(): Observable<any> {
		return this.https.get('interstitiels').pipe(
			map((res: any) => {
				const newArray = res.map(item => {
					return new Interstitiel(item);
				});
				this.interstitiels.next(newArray);
				return this.interstitiels.getValue();
			})
		);

	}
}
