import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from '../services/user.service';
import {User} from '../models/user';

@Injectable()
export class CguGuard {
	constructor(private router: Router, private userService: UserService) {
	}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | boolean {
		const user: User = this.userService.getUserAuthValue();
		const cguAccepted = localStorage.getItem('cguAccepted') === 'true';

		if (user === null) {
			this.router.navigate(['/auth/signin']);
			return false;
		}

		if ((user && user.cguAccepted) || cguAccepted) {
			return true;
		}
		this.router.navigate(['/auth/cgu']);
		return false;
	}
}
