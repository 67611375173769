import {UserService} from 'src/app/shared/services/user.service';
import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ResponsiveService} from '../../../shared/services/utils/responsive.service';
import {Router} from '@angular/router';
import {ToastService} from '../../../shared/services/utils/toast.service';
import {ModalService} from '../../../shared/services/utils/modal.service';
import {User} from '../../../shared/models/user';
import {lastValueFrom} from 'rxjs';

@Component({
	selector: 'app-modal-delete-account',
	templateUrl: './delete-account.component.html',
	styleUrls: ['./delete-account.component.scss'],
})
export class DeleteAccountModalComponent implements OnInit, AfterViewInit {

	title: string;
	state = 0;
	user: User;
	isDeleted = false;
	description = '';
	deleteText = '';

	constructor(
		private router: Router,
		private toastService: ToastService,
		private modalService: ModalService,
		public modalController: ModalController,
		public responsive: ResponsiveService,
		private userService: UserService
	) {
	}

	ngOnInit(): void {
		this.user = this.userService.getUserAuthValue();
	}

	ngAfterViewInit() {
	}

	dismissModal(isDeleted: boolean) {
		this.modalController.dismiss({
			dismissed: true,
			isDeleted,
		});
	}

	async deleted() {
		try {
			await lastValueFrom(this.userService.deleteAccount(this.description));
			this.isDeleted = true;
			this.state = 1;
		} catch (e) {
			console.error(e);
		}
	}

	async activeAccount() {
		try {
			await lastValueFrom(this.userService.reactiveAccount(this.user.uuid));
			this.isDeleted = false;
			this.dismissModal(this.isDeleted);
		} catch (e) {
			console.error(e);
		}
	}

	openHome() {
		this.dismissModal(this.isDeleted);
	}
}
