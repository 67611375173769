import {Injectable} from "@angular/core";
import {ToastController} from "@ionic/angular";

@Injectable()
export class ToastService {


	constructor(private toastController: ToastController) {

	}

	async show(message, timer = 2000) {
		const toast = await this.toastController.create({
			message,
			duration: timer
		});
		toast.present();
	}
}
