import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {HttpService} from "./utils/http.service";
import {PaginationService} from "./pagination";
import {ForumTopic} from "../models/forum-topic";
import {ForumSubject} from "../models/forum-subject";
import {ForumPost} from "../models/forum-post";
import {File} from "../models/file";

@Injectable({
	providedIn: "root"
})
export class ForumService extends PaginationService {

	topics: BehaviorSubject<Array<ForumTopic>>;
	topic: BehaviorSubject<ForumTopic>;
	subjects: BehaviorSubject<Array<ForumSubject>>;
	subject: BehaviorSubject<ForumSubject>;
	posts: BehaviorSubject<Array<ForumPost>>;
	post: BehaviorSubject<ForumPost>;

	constructor(private http: HttpService) {
		super();
		this.topics = new BehaviorSubject<Array<ForumTopic>>([]);
		this.topic = new BehaviorSubject<ForumTopic>(null);
		this.subjects = new BehaviorSubject<Array<ForumSubject>>([]);
		this.subject = new BehaviorSubject<ForumSubject>(null);
		this.posts = new BehaviorSubject<Array<ForumPost>>([]);
		this.post = new BehaviorSubject<ForumPost>(null);
	}

	/**************************************** Topic ****************************************/
	getTopic(uuid: string): Observable<ForumTopic> {
		return this.http.get("forum/topic/" + uuid).pipe(
			map((res: any) => {
				const topic: ForumTopic = new ForumTopic(res);
				this.topic.next(topic);
				return topic;
			})
		);
	}

	getTopics(): Observable<Array<ForumTopic>> {
		return this.http.get("forum/topics").pipe(
			map((res: any) => {
				const newArray = res.map(item => {
					return new ForumTopic(item);
				});
				this.topics.next(newArray);
				return this.topics.getValue();
			})
		);
	}

	getTopicOffer(): Observable<ForumTopic> {
		return this.http.get("forum/topic/personal-ad").pipe(
			map((res: any) => {
				return new ForumTopic(res);
			})
		);
	}

	/**************************************** Subject ****************************************/
	getSubject(uuid: string): Observable<ForumSubject> {
		return this.http.get("forum/topic/subject/" + uuid).pipe(
			map((res: any) => {
				const subject: ForumSubject = new ForumSubject(res);
				this.subject.next(subject);
				return subject;
			})
		);
	}

	getWidget(): Observable<any> {
		return this.http.get("forum/widget-subjects/").pipe(
			map((res: any) => {
				const newArray = res.map(item => {
					return new ForumSubject(item);
				});
				this.subjects.next(newArray);
				return this.subjects.getValue();
			})
		);

	}

	getSubjects(uuidTopic: string): Observable<Array<ForumSubject>> {
		return this.http.get("forum/topic/" + uuidTopic + "/subjects").pipe(
			map((res: any) => {
				const newArray = res.map(item => {
					return new ForumSubject(item);
				});
				this.subjects.next(newArray);
				return this.subjects.getValue();
			})
		);
	}

	addSubject(uuidTopic: string, title: string, description: string, files: any[]): Observable<any> {
		return this.http.post("forum/topic/" + uuidTopic + "/subject", {title, description, files}).pipe(
			map((res: any) => {
				const subject: ForumSubject = new ForumSubject(res);
				this.subject.next(subject);
				return subject;
			})
		);
	}

	updateSubject(uuid: string, uuidTopic: string, title: string, description: string, files: any[]): Observable<any> {
		return this.http.patch("forum/topic/subject/" + uuid, {title, description, files, topic: uuidTopic}).pipe(
			map((res: any) => {
				const subject: ForumSubject = new ForumSubject(res);
				this.subject.next(subject);
				return subject;
			})
		);
	}

	signalSubject(uuid: string, text: string): Observable<any> {
		return this.http.post("forum/topic/subject/" + uuid + "/signal", {text});
	}

	archiveSubject(uuid: string): Observable<any> {
		return this.http.get("forum/topic/subject/" + uuid + "/archive").pipe(
			map((res: any) => {
				const subject: ForumSubject = new ForumSubject(res);
				this.subject.next(subject);
				return subject;
			})
		);
	}

	deleteSubject(uuid: string): Observable<any> {
		return this.http.delete("forum/topic/subject", uuid);
	}

	getPersonnalAds(): Observable<any> {
		return this.http.get("forum/personal-ads").pipe(
			map((res: any) => {
				const newArray = res.map(item => {
					return new ForumSubject(item);
				});
				this.subjects.next(newArray);
				return this.subjects.getValue();
			})
		);
	}

	/**************************************** Post ****************************************/
	getPost(uuid: string): Observable<ForumPost> {
		return this.http.get("forum/post/" + uuid).pipe(
			map((res: any) => {
				const post: ForumPost = new ForumPost(res);
				this.post.next(post);
				return post;
			})
		);
	}

	getPosts(): Observable<Array<ForumPost>> {
		return this.http.get("forum/topics").pipe(
			map((res: any) => {
				const newArray = res.map(item => {
					return new ForumPost(item);
				});
				this.posts.next(newArray);
				return this.posts.getValue();
			})
		);
	}

	addPost(uuid: string, text: string, files: File[] = []): Observable<any> {
		return this.http.post("forum/topic/subject/" + uuid + "/post", {text, files}).pipe(
			map((res: any) => {
				const post: ForumPost = new ForumPost(res);
				this.post.next(post);
				return post;
			})
		);
	}

	updatePost(uuid: string, text: string, files: File[]): Observable<any> {
		return this.http.patch("forum/topic/subject/post/" + uuid, {text, files}).pipe(
			map((res: any) => {
				const post: ForumPost = new ForumPost(res);
				this.post.next(post);
				return post;
			})
		);
	}

	deletePost(uuid: string): Observable<any> {
		return this.http.delete("forum/topic/subject/post", uuid);
	}

	signalPost(uuid: string, text: string): Observable<any> {
		return this.http.post("forum/topic/subject/post/" + uuid + "/signal", {text});
	}
}
