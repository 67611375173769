import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ResponsiveService} from '../../../shared/services/utils/responsive.service';
import {UserService} from '../../../shared/services/user.service';
import {ToastService} from 'src/app/shared/services/utils/toast.service';

@Component({
	selector: 'app-modal-notification-new',
	templateUrl: './notification-new.component.html',
	styleUrls: ['./notification-new.component.scss'],
})
export class NotificationNewModalComponent implements OnInit {

	constructor(
		public modalController: ModalController,
		public responsive: ResponsiveService,
		public userService: UserService,
		public toastService: ToastService
	) {

	}


	ngOnInit(): void {
	}

	dismissModal() {
		localStorage.setItem('firstStartApp', 'true');
		this.modalController.dismiss({
			dismissed: true
		});
	}


	accept() {
		this.userService.changeNotification(true).subscribe(value => {
			const notificationOptions = {
				forum: true,
				offer: true,
				message: true,
				event: true,
			};
			this.userService.changeNotificationOptions(notificationOptions).subscribe(data => {
				this.dismissModal();
			});

		}, error => {
			this.toastService.show(error);
		});
	}
}
