<div *ngIf="responsive.isDesktop()">
	<ion-header class="ion-no-border" translucent >
		<ion-toolbar>
			<ion-buttons style="justify-content: end;">
				<ion-button (click)="dismissModal()" class="button-close">
					<ion-icon name="close-outline" style="font-size: 42px;color: var(--cftc-color-1);" ></ion-icon>
				</ion-button>
			</ion-buttons>
		</ion-toolbar>
	</ion-header>
	<ion-content style="--overflow: hidden;" fullscreen>
		<ion-row style="justify-content: center; text-align:center;">
			<ion-col style="margin-left: 25px; margin-right: 25px;">
				<ion-label class="title-desktop ion-text-center">
					« Les 12 coups de midi »
				</ion-label>
			</ion-col>
		</ion-row>
		<ion-row style="justify-content: center; text-align:center;">
			<ion-col style="margin-top: 30px;">
				<ion-label class="title-text-desktop">
					Merci pour votre participation !
				</ion-label>
			</ion-col>
		</ion-row>
	</ion-content>
</div>

<div *ngIf="responsive.isMobile()">
	<ion-header class="ion-no-border" translucent >
		<ion-toolbar style="--background: white;">
			<ion-buttons slot="start">
				<ion-button (click)="dismissModal()" class="button-close-mobile">
					<ion-icon name="close-outline" style="font-size: 42px;color: var(--cftc-color-1);" ></ion-icon>
				</ion-button>
			</ion-buttons>
		</ion-toolbar>
	</ion-header>
	<ion-content style="--overflow: hidden;z-index: 20000;" fullscreen>
		<ion-row style="justify-content: center; text-align:center;">
			<ion-col style="margin-left: 25px; margin-right: 25px; margin-top: -10px;">
				<ion-label class="title-desktop ion-text-center">
					« Les 12 coups de midi »
				</ion-label>
			</ion-col>
		</ion-row>
		<ion-row style="justify-content: center; text-align:center;">
			<ion-col style="margin-top: 7px;">
				<ion-label class="title-text-desktop">
					Merci pour votre participation !
				</ion-label>
			</ion-col>
		</ion-row>
	</ion-content>
</div>

