<ion-item (click)="dismissModal()" class="header" lines="none">
	<ion-icon name="chevron-back-outline"></ion-icon>
	<ion-label>Notifications</ion-label>
</ion-item>
<ion-content>
	<ion-list style="padding-right: 20px;padding-left: 20px">
		<app-notification-renderer (click)="open(alert)"
								   *ngFor="let alert of alerts.reverse(); let i = index"
								   [item]="alert"></app-notification-renderer>
	</ion-list>
</ion-content>
