import {Pipe, PipeTransform} from "@angular/core";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Pipe({
	name: "linkify"
})
export class LinkifyPipe implements PipeTransform {

	constructor(private domSanitizer: DomSanitizer) {
	}


	transform(value: any, args?: any): SafeHtml {
		const urlRegex =
			/(\b(https?|ftp|file):\/\/[-A-Z\d+&@#/%?=~_|!:,.;]*[-A-Z\d+&@#/%=~_|])/gi;

		const html = value.replace(
			urlRegex,
			(url) => `<a class="${args}" href="${url}" target="_system"  >${url}</a>`
		);

		return this.domSanitizer.bypassSecurityTrustHtml(html);
	}


}
