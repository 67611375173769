import {Component, Input, OnInit} from "@angular/core";
import {Platform, PopoverController} from "@ionic/angular";

@Component({
	selector: "app-popover",
	templateUrl: "./popover-message.component.html",
	styleUrls: ["./popover-message.component.scss"],
})
export class PopoverMessageComponent implements OnInit {
	@Input() buttons: Array<any> = [];

	constructor(public platform: Platform, private popCtrl: PopoverController) {

	}

	ngOnInit(): void {

	}

	openPath(button) {
		this.popCtrl.dismiss({button});
	}

}
