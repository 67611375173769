<ion-card [class.ion-card-desktop]="isDesktop" [class.ion-card-list]="isList">
	<ion-grid>
		<ion-row class="row">
			<ion-col size="12">
				<ion-item lines="none">
					<div class="date">
						<div class="text">
							<span class="number">{{
								item.startAtDayNumber
							}}</span>
							<br />
							<span class="month">{{
								item.startAtMonthCut
							}}</span>
						</div>
					</div>
					<div>
						<ion-label *ngIf="item.stopAt && item.startAt !== item.stopAt" class="title">
							{{ item.startAtDay }}. {{ item.startAtDayNumber }}
							{{ item.startAtMonthCut }}

							<ion-icon class="icon-arrow-line" name="arrow-forward-outline"></ion-icon>

							{{ item.stopAtDay }}.
							{{ item.stopAtDayNumber }} {{ item.stopAtMonthCut }}
							<!-- {{item.startAtMinutes}} -->
						</ion-label>
						<ion-label *ngIf="!item.stopAt" class="title">
							{{ item.startAtDay }}. {{ item.startAtDayNumber }}
							{{ item.startAtMonthCut }}, {{ item.startAtHours }}h
							<!-- {{item.startAtMinutes}} -->
						</ion-label>
						<p class="name">{{ item.title }}</p>

						<ion-item
							*ngIf="item.location !== ''"
							class="categories"
							lines="none"
						>
							<img
								[src]="'assets/image/map-pin.png'"
								alt=""
								style="height: 18px"
							/>
							<div>{{ item.location }}</div>
						</ion-item>
					</div>
				</ion-item>
			</ion-col>
		</ion-row>
	</ion-grid>
</ion-card>
