import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ResponsiveService} from '../../../shared/services/utils/responsive.service';
import {EventService} from '../../../shared/services/event.service';
import {UserService} from 'src/app/shared/services/user.service';
import {ToastService} from 'src/app/shared/services/utils/toast.service';
import {ModalService} from 'src/app/shared/services/utils/modal.service';
import {Interstitiel} from '../../../shared/models/interstitiel';
import {Browser} from '@capacitor/browser';

@Component({
	selector: 'app-modal-interstitiel',
	templateUrl: './interstitiel.component.html',
	styleUrls: ['./interstitiel.component.scss'],
})
export class InterstitielModalComponent implements OnInit {
	pub: Interstitiel = new Interstitiel();
	counter: 3;

	constructor(
		public modalController: ModalController,
		public responsive: ResponsiveService,
		public eventService: EventService,
		public userService: UserService,
		public toastService: ToastService,
		public modalService: ModalService,
	) {

	}


	ngOnInit(): void {
		const self = this;
		setInterval(() => {
			self.counter -= 1;
		}, 1000);
	}

	dismissModal() {
		this.modalController.dismiss({
			dismissed: true,
		});
	}


	open(url) {
		if (url && url !== '') {
			Browser.open({url});
		}
	}


}
