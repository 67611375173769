<img alt="update-background" src="assets/image/update-background.png"/>

<ion-card>
	<ion-card-content>
		<ion-grid style="">
			<ion-row>
				<ion-col class="title" size="12">
					L'application s'enrichit de nouvelles fonctionnalités
				</ion-col>
				<ion-col class="description" size="12">
					Pour continuer à profiter de CFTC l’App et découvrir ses nouveautés, vous devez télécharger sa
					dernière version sur votre store.
				</ion-col>
				<ion-col size="12">
					<ion-button (click)="accept()" class="bd-yellow bg-yellow btn-map"
								style="width: 100%;"
								type="button">
						Mettre à jour
					</ion-button>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ion-card-content>
</ion-card>
