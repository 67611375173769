import {User} from "./user";

export class Comment {
	private _month = [
		"Janvier",
		"Fevrier",
		"Mars",
		"Avril",
		"Mai",
		"Juin",
		"Juillet",
		"Aout",
		"Septembre",
		"Octobre",
		"Novembre",
		"Décembre"
	];

	constructor(data = null) {
		this._id = 0;
		this._uuid = "";
		this._text = "";
		this._createdAt = new Date();
		this._updatedAt = new Date();
		this._select = false;
		if (data) {
			this._id = data.id;
			this._uuid = data.uuid;
			this._text = data.text;
			this._owner = new User(data.owner);
			this._createdAt = new Date(data.createdAt);
			this._updatedAt = new Date(data.updatedAt);
			this._date = this.createdAt.getDate() + " " + this._month[this.createdAt.getMonth()] + " " + this.createdAt.getFullYear();

		}
	}

	private _id: number;

	get id(): number {
		return this._id;
	}

	set id(value: number) {
		this._id = value;
	}

	private _uuid: string;

	get uuid(): string {
		return this._uuid;
	}

	set uuid(value: string) {
		this._uuid = value;
	}

	private _text: string;

	get text(): string {
		return this._text;
	}

	set text(value: string) {
		this._text = value;
	}

	private _owner: User;

	get owner(): User {
		return this._owner;
	}

	set owner(value: User) {
		this._owner = value;
	}

	private _createdAt;

	get createdAt() {
		return this._createdAt;
	}

	set createdAt(value) {
		this._createdAt = value;
	}

	private _updatedAt;

	get updatedAt() {
		return this._updatedAt;
	}

	set updatedAt(value) {
		this._updatedAt = value;
	}

	private _select: boolean;

	get select(): boolean {
		return this._select;
	}

	set select(value: boolean) {
		this._select = value;
	}

	private _date: string;

	get date(): string {
		return this._date;
	}

	set date(value: string) {
		this._date = value;
	}
}
