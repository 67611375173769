import {Platform} from "@ionic/angular";
import {Component, Input, OnInit} from "@angular/core";
import {ResponsiveService} from "../../../../shared/services/utils/responsive.service";
import {ForumSubject} from "../../../../shared/models/forum-subject";

@Component({
	selector: "app-forum-subject-renderer",
	templateUrl: "./forum-subject.component.html",
	styleUrls: ["./forum-subject.component.css"],
})
export class ForumSubjectRendererComponent implements OnInit {
	@Input() item: ForumSubject;
	@Input() index;

	constructor(public platform: Platform, public responsive: ResponsiveService) {
	}

	ngOnInit(): void {
	}
}
