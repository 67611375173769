export class ThematicSection {

	public btn_label = '';
	public title = '';
	public type = '';
	public contents = [];
	public infobulle = '';

	constructor(data = null) {
		if (data) {
			this.btn_label = data.btn_label;
			this.title = data.title;
			this.type = data.type;
			this.contents = data.contents;
			this.infobulle = data.infobulle;
		}

	}
}
