import {ModalController, Platform} from "@ionic/angular";
import {Component, Input, OnInit} from "@angular/core";
import {ResponsiveService} from "../../../../shared/services/utils/responsive.service";
import {Event} from "../../../../shared/models/event";
import {EventService} from "../../../../shared/services/event.service";

@Component({
	selector: "app-event-new-renderer",
	templateUrl: "./event-new.component.html",
	styleUrls: ["./event-new.component.scss"],
})
export class EventNewRendererComponent implements OnInit {
	@Input() item: any;
	@Input() edit = false;
	@Input() isList: boolean = false;
	@Input() isDesktop: boolean = false;


	constructor(public platform: Platform, public responsive: ResponsiveService, public eventService: EventService,
				public modalController: ModalController) {
	}

	ngOnInit(): void {
	}

}
