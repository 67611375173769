import {Component, OnInit} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {ResponsiveService} from "../../../shared/services/utils/responsive.service";
import {EventService} from "../../../shared/services/event.service";
import {UserService} from "src/app/shared/services/user.service";
import {ToastService} from "src/app/shared/services/utils/toast.service";

@Component({
	selector: "app-modal-confidentiality",
	templateUrl: "./confidentiality.component.html",
	styleUrls: ["./confidentiality.component.scss"],
})
export class ConfidentialityModalComponent implements OnInit {
	html = "";
	title = "";
	constructor(
		public modalController: ModalController,
		public responsive: ResponsiveService,
		public eventService: EventService,
		public userService: UserService,
		public toastService: ToastService,
	) {

		this.userService.confidentiality().subscribe(value => {
			this.html = value.content;
			this.title = value.title;
		}, error => {
			this.toastService.show(error);
		});
	}


	ngOnInit(): void {
	}

	dismissModal(result) {
		this.modalController.dismiss({
			dismissed: true,
			result
		});
	}

}
