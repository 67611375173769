import {Injectable} from "@angular/core";

@Injectable({
	providedIn: "root"
})
export class PaginationService {

	from: number;
	to: number;
	perPage: number;
	total: number;
	currentPage: number;
	prevPage: number;
	nextPage: number;

	constructor() {
		this.from = 0;
		this.to = 0;
		this.perPage = 0;
		this.total = 0;
		this.currentPage = 0;
		this.prevPage = 0;
		this.nextPage = 0;
	}

}
