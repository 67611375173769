import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpService} from './utils/http.service';
import {PaginationService} from './pagination';

@Injectable({
	providedIn: 'root'
})
export class UrlService extends PaginationService {
	constructor(private https: HttpService) {
		super();
	}

	getUrl(url, raw = 1): Observable<any> {
		return this.https.post('url/get-url/', {url, raw}).pipe(
			map((res: any) => {
				return res;
			})
		);
	}

}
