<ion-item (click)="dismissModal()" lines="none" style="--background: white!important;">
	<ion-icon class="back-icon" name="chevron-back-outline"></ion-icon>
	<ion-label class="back">Retour</ion-label>
</ion-item>
<ion-content style="--background: white;">
	<ion-grid style="padding: 15px;">
		<ion-row>
			<ion-col class="title" size="12">
				Modifier un commentaire :
			</ion-col>
			<ion-col class="sub-title" size="12">
				<ion-label>Commentaire*</ion-label>
			</ion-col>
			<ion-col size="12">
				<ion-textarea #myTextArea [(ngModel)]="comment.text" autocomplete="on"
							  autocorrect="on" placeholder="Donnez un maximum de détails pour que la communauté puisse vous aider"
							  rows="5"
							  spellcheck="true"
							  type="text"></ion-textarea>
			</ion-col>
			<ion-col *ngIf="comment && comment.files && comment.files.length>0" class="sub-title" size="12">
				<ion-label>Les fichiers joins</ion-label>
			</ion-col>
			<ion-col *ngIf="comment && comment.files && comment.files.length>0" size="12">
				<ion-item *ngFor="let file of comment.files;let index=index" class="files ion-no-padding" lines="none">
					<ion-icon name="attach-outline"></ion-icon>
					<ion-label>{{file.name}}</ion-label>
					<ion-icon (click)="removeImage(index)" name="close-outline" style="margin-right: 10px"></ion-icon>
				</ion-item>
			</ion-col>

			<ion-col size="12">
				<ion-button (click)="edit()"
							[disabled]="(comment && comment.text === '')"
							[style.color]="'#fff'" class="bg-yellow bd-yellow"
							style="width: 100%;margin: 0;margin-bottom: 10px">
					Modifier le commentaire
				</ion-button>
				<ion-button (click)="delete()"
							class="bg-transparent bd-red text-red"
							style="width: 100%;margin: 0;margin-bottom: 10px">
					Supprimer le commentaire
				</ion-button>
			</ion-col>
		</ion-row>
	</ion-grid>
</ion-content>

