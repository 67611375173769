<ion-item class="header" lines="none">
	<ion-icon (click)="dismissModal()" name="chevron-back-outline"></ion-icon>
	<ion-input (keyup.enter)="find()" [(ngModel)]="findText">Rechercher :</ion-input>
	<ion-icon *ngIf="findText ===''" name="search-outline"></ion-icon>
	<ion-icon (click)="find()" *ngIf="findText !==''" name="arrow-forward-outline"></ion-icon>
</ion-item>


<ion-row style="padding-left: 10px;padding-right: 10px">
	<ion-col [size]="responsive.isMobile()?'4':'auto'">
		<ion-button (click)="changeShowArticles()" [class.ion-button-select]="!showArticles"
					class="bg-blue bd-blue button-over" type="button">
			Articles
		</ion-button>
	</ion-col>
	<ion-col [size]="responsive.isMobile()?'4':'auto'">
		<ion-button (click)="changeShowVideos()" [class.ion-button-select]="!showVideos"
					class="bg-blue bd-blue button-over" type="button">
			Vidéos
		</ion-button>
	</ion-col>
	<ion-col [size]="responsive.isMobile()?'4':'auto'">
		<ion-button (click)="changeShowAdherents()" [class.ion-button-select]="!showAdherents"
					class="bg-blue bd-blue button-over" type="button">
			Adhérents
		</ion-button>
	</ion-col>
	<ion-col [size]="responsive.isMobile()?'4':'auto'">
		<ion-button (click)="changeShowForums()" [class.ion-button-select]="!showForums"
					class="bg-blue bd-blue button-over" type="button">
			Forum
		</ion-button>
	</ion-col>
	<ion-col [size]="responsive.isMobile()?'4':'auto'">
		<ion-button (click)="changeShowOffers()" [class.ion-button-select]="!showOffers"
					class="bg-blue bd-blue button-over" type="button">
			Petites Annonces
		</ion-button>
	</ion-col>
</ion-row>
<ion-content>
	<div *ngIf="data.articles.length >0 && showArticles" class="content-list">
		<ion-label class="title">Actualités</ion-label>
		<ion-list>
			<app-article-find-renderer (click)="openArticle(article)" *ngFor="let article of data.articles"
									   [article]="article"></app-article-find-renderer>
		</ion-list>
		<ion-button (click)="openAllArticle()" class="bg-yellow bd-yellow text-white">
			Voir tous les articles
			<ion-icon name="chevron-forward-circle-outline" style="margin-left: 10px"></ion-icon>
		</ion-button>
	</div>
	<div *ngIf="data.videos.length >0 && showVideos" class="content-list">
		<ion-label class="title">Vidéos</ion-label>
		<ion-list>
			<app-video-find-renderer (click)="openVideo(video)" *ngFor="let video of data.videos" [video]="video">
			</app-video-find-renderer>
		</ion-list>
		<ion-button (click)="openAllVideo()" class="bg-yellow bd-yellow text-white">
			Voir toutes les vidéos
			<ion-icon name="chevron-forward-circle-outline" style="margin-left: 10px"></ion-icon>
		</ion-button>
	</div>
	<div *ngIf="data.directory.length >0 && showAdherents" class="content-list">
		<ion-label class="title">Adhérents</ion-label>
		<ion-list>
			<app-directory (click)="openProfile(user)" *ngFor="let user of data.directory" [item]="user">
			</app-directory>
		</ion-list>
		<ion-button (click)="openAllUser()" class="bg-yellow bd-yellow text-white">
			Voir tous les adhérents
			<ion-icon name="chevron-forward-circle-outline" style="margin-left: 10px"></ion-icon>
		</ion-button>
	</div>
	<div *ngIf="data.forums.length >0 && showForums" class="content-list">
		<ion-label class="title">Forums</ion-label>
		<ion-list>
			<app-forum-subject-renderer (click)="openForum(forum)" *ngFor="let forum of data.forums" [item]="forum">
			</app-forum-subject-renderer>
		</ion-list>
		<ion-button (click)="openForums()" class="bg-yellow bd-yellow text-white">
			Voir tous les Forums
			<ion-icon name="chevron-forward-circle-outline" style="margin-left: 10px"></ion-icon>
		</ion-button>
	</div>
	<div *ngIf="data.offers.length >0 && showOffers" class="content-list">
		<ion-label class="title">Petites Annonces</ion-label>
		<ion-list>
			<app-forum-subject-renderer (click)="openOffer(offer)" *ngFor="let offer of data.offers" [item]="offer">
			</app-forum-subject-renderer>
		</ion-list>
		<ion-button (click)="openOffers()" class="bg-yellow bd-yellow text-white">
			Voir tous les Forums
			<ion-icon name="chevron-forward-circle-outline" style="margin-left: 10px"></ion-icon>
		</ion-button>
	</div>

	<div style="margin-top: 60px; text-align: center;" *ngIf="noResult">
		<ion-label class="no-result">Aucun résultat ne correspond à votre recherche</ion-label>
	</div>
</ion-content>
