export class Video {
	public id = 0;
	public uuid = '';
	public createdAt = new Date().toString();
	public updatedAt = new Date().toString();
	public title = '';
	public url = '';
	public youtubeUrl = '';
	public date = new Date().toString();

	public dateDay;
	public dateMonth;
	public dateYear;

	public month = [
		"Janv",
		"Fev",
		"Mars",
		"Avr",
		"Mai",
		"Juin",
		"Juil",
		"Août",
		"Sept",
		"Oct",
		"Nov",
		"Déc",
	];

	public select = false;
	public youTubeID = '';
	constructor(data = null) {
		if (data) {
			this.id = data.id;
			this.uuid = data.uuid;
			this.title = data.title;

			if (data.youtubeUrl !== undefined && data.youtubeUrl !== '') {
				this.youTubeID = this.youTubeGetID(data.youtubeUrl);
				this.youtubeUrl = 'https://www.youtube.com/embed/' + this.youTubeID;
			}
			this.url = data.url;
			this.date = data.date;
			this.dateDay = data.dateDay;
			this.dateMonth = data.dateMonth;
			this.dateYear = data.dateYear;
			this.createdAt = data.createdAt;
			this.updatedAt = data.updatedAt;
		}
		if (this.date !== "" && this.date !== undefined) {
			const date = new Date(this.date);
			this.dateDay = date.getDate();
			this.dateYear = date.getFullYear();
			this.dateMonth = this.month[date.getMonth()];
		}
	}
	youTubeGetID(url){
		url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
		return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
	}
}
