import {Platform} from '@ionic/angular';
import {Component, Input, OnInit} from '@angular/core';
import {ResponsiveService} from '../../../../shared/services/utils/responsive.service';
import {Article} from '../../../../shared/models/article';

@Component({
	selector: 'app-article-find-renderer',
	templateUrl: './article-find.component.html',
	styleUrls: ['./article-find.component.scss'],
})
export class ArticleFindRendererComponent implements OnInit {

	@Input() index;
	@Input() article: Article;

	constructor(public platform: Platform, public responsive: ResponsiveService) {
	}

	ngOnInit(): void {
	}

}
