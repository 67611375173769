import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {HttpService} from "./utils/http.service";
import {Thematic} from "src/app/shared/models/thematic";
import {ThematicCollection} from "../models/thematicCollection";
import {ThematicContent} from "../models/thematicContent";
import {Md5} from "ts-md5";

@Injectable({
	providedIn: "root",
})
export class ThematicService {
	thematics: BehaviorSubject<Thematic[]>;
	content: BehaviorSubject<ThematicContent>;
	hash: string;

	constructor(private https: HttpService) {
		this.thematics = new BehaviorSubject<Thematic[]>([]);
		this.content = new BehaviorSubject<ThematicContent>(
			new ThematicContent()
		);
	}

	get(force = false): Observable<Thematic[]> {
		return this.https.get("thematics").pipe(
			map((thematics: any) => {

				const hash = Md5.hashStr(JSON.stringify(thematics)) as string;

				if (this.hash !== hash || force) {
					thematics = thematics.map(
						(item: any) => new Thematic(item)
					);
					this.thematics.next(thematics);
					// console.log('Refresh home',this.hash , hash);
					this.hash = hash;

				}
				// console.log(this.thematics.getValue());
				return this.thematics.getValue();
			})
		);
	}

	getContents(thematic: ThematicCollection): Observable<ThematicContent> {
		return this.https.post("thematics/contents", thematic).pipe(
			map((content: any) => {
				this.content.next(new ThematicContent(content));
				return this.content.getValue();
			})
		);
	}
}
