import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpService} from './utils/http.service';
import {PaginationService} from './pagination';
import {Alert} from 'src/app/shared/models/alert';

@Injectable({
	providedIn: 'root'
})
export class NotificationService extends PaginationService {

	alerts: BehaviorSubject<Array<Alert>>;
	usersConnected: BehaviorSubject<Array<string>>;

	constructor(private https: HttpService) {
		super();
		this.alerts = new BehaviorSubject<Array<Alert>>([]);
		this.usersConnected = new BehaviorSubject<Array<string>>([]);

	}

	getCronNotification(): Observable<{ alertCount: number, messageCount: number }> {
		return this.https.get('notification/cron').pipe(
			map((res: any) => {
				this.usersConnected.next(res.users);
				return res;
			})
		);
	}

	getAlerts(): Observable<any> {
		return this.https.get('alerts').pipe(
			map((res: any) => {
				const newArray = res.map(item => {
					return new Alert(item);
				});
				this.alerts.next(newArray);
				return this.alerts.getValue();
			})
		);

	}

	deleteAlert(uuid): Observable<any> {
		return this.https.delete('alert/' + uuid);
	}
}
