import {Injectable} from '@angular/core';
import {Route, Router} from '@angular/router';
import {UserService} from '../services/user.service';
import {HttpService} from '../services/utils/http.service';
import {User} from '../models/user';

@Injectable()
export class LoadGuard {
	constructor(private router: Router, private userService: UserService, private httpService: HttpService) {
	}

	canLoad(route: Route): Promise<boolean> {
		return new Promise((resolve) => {


			const token = localStorage.getItem('token');
			const user = localStorage.getItem('user');


			if (token) {
				this.httpService.token = token;
			}
			if (user) {
				const u = new User(JSON.parse(user));
				this.userService.userAuth.next(u);
				this.userService.updateLatLong();
				this.userService.isConnected = true;
			}

			resolve(true);
		});
	}
}
