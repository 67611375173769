import {Component, OnInit, ViewChild} from '@angular/core';
import {AlertController, ModalController, PopoverController} from '@ionic/angular';
import {ResponsiveService} from '../../../shared/services/utils/responsive.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertHelper} from '../../../shared/helpers/alert.helper';
import {User} from '../../../shared/models/user';
import {UserService} from '../../../shared/services/user.service';
import {ToastService} from '../../../shared/services/utils/toast.service';
import {ModalService} from '../../../shared/services/utils/modal.service';
import {CommonHelper} from "../../../shared/helpers/common.helper";
import {ForumSubject} from "../../../shared/models/forum-subject";
import {ForumTopic} from "../../../shared/models/forum-topic";
import {ForumService} from "../../../shared/services/forum.service";
import {AnalyticsService} from "../../../shared/services/utils/analytics.service";
import {
	PopoverMessageComponent
} from "../../../main/pages/chat/components/popovers/popover-message/popover-message.component";
import {
	SubjectCreateModalComponent
} from "../../../main/pages/community/pages/forum/components/modal/subject-create/subject-create.component";
import {File} from "../../../shared/models/file";
import {Camera, CameraResultType, CameraSource} from "@capacitor/camera";

@Component({
	selector: 'app-modal-forum',
	templateUrl: './forum.component.html',
	styleUrls: ['./forum.component.scss'],
})
export class ForumModalComponent implements OnInit {
	title = '';
	error = '';
	user = new User();

	topic: ForumTopic = new ForumTopic();
	subject: ForumSubject = new ForumSubject();
	message = "";
	newFile;
	@ViewChild("scroll", {static: false}) private scroll: any;

	constructor(
		public alertHelper: AlertHelper,
		private toastService: ToastService,
		private forumService: ForumService,
		public userService: UserService,
		private alertController: AlertController,
		private modalController: ModalController,
		private popoverController: PopoverController,
		private modalService: ModalService,
		private route: ActivatedRoute,
		private router: Router,
		public responsive: ResponsiveService,
		private commonHelper: CommonHelper,
		public responsiveService: ResponsiveService,
		private analyticsService: AnalyticsService
	) {
	}

	ngAfterViewInit() {
	}

	ionViewWillEnter() {
		this.init();
	}

	init(scrollToBottom = false) {
		this.message = "";
		this.newFile = null;
		this.user = this.userService.getUserAuthValue();
		this.forumService.getTopic(this.topic.uuid).subscribe(
			(value) => {
				this.topic = value;
			},
			(error) => {
				this.toastService.show(error);
			}
		);
		this.forumService.getSubject(this.subject.uuid).subscribe(
			(value) => {
				this.subject = value;
				if (scrollToBottom) {
					this.scrollToBottom();
				}
			},
			(error) => {
				this.toastService.show(error);
			}
		);
	}

	scrollToBottom() {
		if (this.responsiveService.isMobile()) {
			setTimeout(() => {
				if (this.scroll) {
					this.scroll.scrollToBottom();
				}
			}, 500);
		}
	}

	back() {
		this.router.navigate(["/community/forum/" + this.topic.uuid]);
	}

	openSubject(subject) {
		this.analyticsService.pageView(
			"/community/forum/" + +this.topic.uuid + "/" + subject.uuid,
			"Forum " + this.topic.title + " " + subject.title,
			this.userService.getUserAuthValue()
		);
		this.router.navigate([
			"/community/forum/" + this.topic.uuid + "/" + subject.uuid,
		]);
	}

	async openMenu(ev) {
		const popover = await this.popoverController.create({
			component: PopoverMessageComponent,
			componentProps: {
				buttons: [
					{
						key: "signal",
						name: "Signaler",
						icon: "alert-circle-outline",
						show: true,
					},
					{
						key: "edit",
						name: "Modifier",
						icon: "alert-circle-outline",
						show:
							this.user.uuid === this.subject.owner.uuid ||
							this.user.isSuperAdmin,
						color: true,
					},
				],
			},
			cssClass: "popover-mobile-cftc",
			event: ev,
			mode: "ios",
			translucent: true,
		});

		popover.onDidDismiss().then((dataReturned) => {
			if (dataReturned && dataReturned.data && dataReturned.data.button) {
				const button = dataReturned.data.button;
				switch (button.key) {
					case "signal":
						this.modalService.openSignalement((data: any) => {
							if (data.data.result) {
								this.forumService
									.signalSubject(
										this.subject.uuid,
										data.data.result
									)
									.subscribe(
										(value) => {
										},
										(error) => {
											this.toastService.show(error);
										}
									);
							}
						});
						break;
					case "edit":
						this.editSubject();
						break;
				}
			}
		});
		await popover.present();
	}

	async editSubject() {
		const modal = await this.modalController.create({
			component: SubjectCreateModalComponent,
			cssClass: "modal-profile-css",
			componentProps: {
				uuid: this.subject.uuid,
			},
		});
		modal.onDidDismiss().then((data: any) => {
			if (data.data.close) {
				this.back();
			} else {
				if (this.responsiveService.isMobile()) {
					this.router.navigate([
						"/community/forum/" +
						data.data.subject.topic.uuid +
						"/" +
						data.data.subject.uuid,
					]);
				} else {
					this.init();
				}
			}
		});
		return await modal.present();
	}

	async sendMessage(message) {
		if (this.user.inaric === "") {
			this.modalService.openAccess((value) => {
			});
		} else {
			if (this.user.inaric === "") {
				return;
			}
			if (this.message === "") {
				return;
			}
			await this.modalService.openLoading();
			const files = [];
			if (this.newFile !== null) {
				files.push(this.newFile);
			}

			this.forumService
				.addPost(this.subject.uuid, this.message, files)
				.subscribe(
					(value) => {
						this.init(true);
						this.modalService.closeLoading();
					},
					(error) => {
						this.toastService.show(error);
						this.modalService.closeLoading();
					}
				);
		}
	}

	openRegister() {
		this.router.navigate(["/auth/signup"]);
	}

	async chooseFileBrowner(event) {
		const file = event.target.files[0];
		this.newFile = new File();
		this.newFile.name = file.name;
		this.newFile.filename = await this.commonHelper.readFileAsync(file);
	}

	removeImage() {
		this.newFile = null;
	}

	async chooseImage() {
		if (this.user.inaric === "") {
			return;
		}
		const image = await Camera.getPhoto({
			quality: 75,
			width: 600,
			allowEditing: false,
			resultType: CameraResultType.Base64,
			correctOrientation: false,
			source: CameraSource.Camera,
			promptLabelHeader: "Photo",
			promptLabelCancel: "Annuler",
			promptLabelPhoto: "Photo",
			promptLabelPicture: "Gallerie",
		});
		this.newFile = new File();
		this.newFile.name = "camera." + image.format;
		this.newFile.filename =
			"data:image/" + image.format + ";base64," + image.base64String;
	}

	openUser(user) {
		this.modalService.openProfile(user, "Forum", (value) => {
		});
	}

	openFile(file) {
		this.commonHelper.openFile(file);
	}


	ngOnInit(): void {
		this.init();
	}


	dismissModal() {
		this.modalController.dismiss({
			dismissed: true,
		});
	}

}
