import { Injectable, NgZone } from "@angular/core";
import { Capacitor } from "@capacitor/core";
import {
	ActionPerformed,
	PushNotificationSchema,
	PushNotifications,
	Token,
} from "@capacitor/push-notifications";

import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";

import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import { PermissionState } from "@capacitor/core/types/definitions";
import { UserService } from "../user.service";
import { ModalService } from "./modal.service";

import { EventService } from "../event.service";
import { ForumService } from "../forum.service";

@Injectable({ providedIn: "root" })
export class FcmService {
	public token = "";

	constructor(
		private readonly httpClient: HttpClient,
		private router: Router,
		private zone: NgZone,
		private userService: UserService,
		private afMessaging: AngularFireMessaging,
		private modalService: ModalService,
		private eventService: EventService,
		private forumService: ForumService,
		private route: ActivatedRoute
	) {}

	public async checkPermissions(): Promise<PermissionState> {
		const permStatus = await PushNotifications.checkPermissions();
		return permStatus.receive;
	}

	public async registerNotifications(): Promise<string> {
		let permStatus = await PushNotifications.checkPermissions();
		if (permStatus.receive === "prompt") {
			permStatus = await PushNotifications.requestPermissions();
		}

		if (permStatus.receive !== "granted") {
		} else {
			await PushNotifications.register();
		}
		return permStatus.receive;
	}

	public async registerNotificationsWeb(): Promise<string> {
		return new Promise((resolve, reject) => {
			this.afMessaging.requestPermission.subscribe(
				() => {
					this.afMessaging.requestToken.subscribe(
						(token) => {
							this.token = token;
							this.userService
								.changeNotificationToken(token)
								.subscribe((value) => {
									localStorage.setItem(
										"notificationToken",
										token
									);
								});
							resolve(token);
						},
						(error) => {
							console.error(error);
							reject(error);
						}
					);
				},
				(error) => {
					console.error(error);
					reject(error);
				}
			);
		});
	}

	/**
	 * initialize the push notification
	 */
	public async initPush() {
		if (Capacitor.getPlatform() !== "web") {
			await this.registerNotifications();
			await PushNotifications.addListener(
				"registration",
				(token: Token) => {
					this.token = token.value;
					this.userService
						.changeNotificationToken(token.value)
						.subscribe((value) => {
							localStorage.setItem(
								"notificationToken",
								token.value
							);
						});
				}
			);
			await PushNotifications.addListener(
				"pushNotificationActionPerformed",
				(notification: ActionPerformed) => {
					this.openNotification(notification.notification.data);
				}
			);

			await PushNotifications.addListener(
				"registrationError",
				(error: any) => {
					console.error(
						"Push Error on registration, token :",
						JSON.stringify(error)
					);
				}
			);

			await PushNotifications.addListener(
				"pushNotificationReceived",
				(notification: PushNotificationSchema) =>
					this.zone.run(() => {})
			);
		} /*else {
			await this.registerNotificationsWeb();
			this.afMessaging.messages.subscribe((message) => {
				// this.openNotification(message.data);
			});
		}*/
	}

	private openNotification(data): void {
		console.log("openNotification", data);
		switch (data.type) {
			case "0": // message
				this.router.navigate(["/chat/" + data.id]);
				break;
			case "1": // forum
				this.forumService.getSubject(data.id).subscribe(
					(value) => {
						this.router.navigate([
							"/community/forum/" +
								value.topic.uuid +
								"/" +
								value.uuid,
						]);
					},
					(error) => {}
				);
				break;
			case "2": // event
				this.eventService.get(data.id).subscribe(
					(value) => {
						this.modalService.openEvent(
							value,
							"Notification",
							() => {}
						);
					},
					(error) => {}
				);
				break;
			case "3": // Petite annonce
				this.forumService.getSubject(data.id).subscribe(
					(value) => {
						this.router.navigate([
							"/community/offers/" + value.uuid,
						]);
					},
					(error) => {}
				);
				break;
			case "4": // Home
				this.router.navigate(["/home"], {
					relativeTo: this.route.root,
				});
				break;
			case "5": // forum
				this.router.navigate(["/community/forum"], {
					relativeTo: this.route.root,
				});
				break;
			case "6": // offers
				this.router.navigate(["/community/offer"], {
					relativeTo: this.route.root,
				});
				break;
			case "7": // events
				this.router.navigate(["/community/event"], {
					relativeTo: this.route.root,
				});
				break;
			case "8": // profile
				this.router.navigate(["/my-reseau/profile"], {
					relativeTo: this.route.root,
				});
				break;
			case "9": // poll
				this.router.navigate(["/community/poll"], {
					relativeTo: this.route.root,
				});
				break;
			case "10": // event -> intéréssé -- côté back impossible d'appeler 2x la case 2 donc je le double ici
				this.eventService.get(data.id).subscribe(
					(value) => {
						this.modalService.openEvent(
							value,
							"Notification",
							() => {}
						);
					},
					(error) => {}
				);
				break;
		}
	}
}
