export class SubscriptionPaper {
	public title = '';
	public description = '';
	public isSub = false;
	public guidPub = '';

	constructor(data = null) {
		if (data) {
			this.title = data.titre;
			this.description = data.description;
			this.isSub = data.abonne === 1;
			this.guidPub = data.guid_pub;
		}
	}
}
