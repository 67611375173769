<ion-item (click)="dismissModal()" class="header" lines="none">
	<ion-icon name="chevron-back-outline"></ion-icon>
	<ion-label>Paramètres</ion-label>
</ion-item>
<ion-content>
	<ion-card *ngIf="user.inaric !==''" class="notif">
		<ion-item lines="none">
			<ion-label class="ion-text-wrap" slot="start">
				<div class="title">
					<ion-icon name="eye-outline"></ion-icon>
					Visibilité
				</div>
				<div class="description ">
					Acceptez-vous d'être visible<br>
					par les autres adhérents de la CFTC<br>
					et les voir en retour ?
				</div>
			</ion-label>
			<ion-toggle (click)="changeVisibility($event)" [checked]="user.directoryVisible" mode="ios"
						slot="end"></ion-toggle>
		</ion-item>
	</ion-card>
	<!--ion-card *ngIf="user.inaric ===''" class="notif">
		<ion-item lines="none">
			<ion-label class="ion-text-wrap" slot="start">
				<div class="title">
					<ion-icon name="eye-outline"></ion-icon>
					Visibilité
				</div>
				<div class="description ">
					Pour être visible et voir en retours les adhérents CFTC, vous devez être adhérent.
				</div>
			</ion-label>
		</ion-item>
		<ion-button (click)="adherent()" class="bg-yellow" style="margin-left: 15px">Devenir adhérent
		</ion-button>
	</ion-card-->
	<ion-card *ngIf="user && user.role==='inaric'" [formGroup]="notifForm" class="notif">
		<ion-item [class.border]="user.notificationEnabled" lines="none">
			<ion-label class="ion-text-wrap" slot="start">
				<div class="title">
					<ion-icon name="notifications-outline"></ion-icon>
					Notifications
				</div>
				<div class="description ">
					Autorisez à recevoir des notifications.
				</div>
			</ion-label>
			<ion-toggle (click)="changeNotification($event)" formControlName="notificationEnabled" mode="ios"
						slot="end"></ion-toggle>
		</ion-item>
		<ion-item *ngIf="user.notificationEnabled" class="border" lines="none">
			<ion-label class="ion-text-wrap" slot="start">
				<div class="title-sub">
					Forum
				</div>
				<div class="description-sub">
					Recevez une notification lorsque
					de nouveaux messages et posts sont
					publiés dans le forum.
				</div>
			</ion-label>
			<ion-toggle (click)="changeNotificationOptions($event,'forum')"
						formControlName="forum"
						mode="ios"
						slot="end"></ion-toggle>
		</ion-item>
		<ion-item *ngIf="user.notificationEnabled" class="border" lines="none">
			<ion-label class="ion-text-wrap" slot="start">
				<div class="title-sub">
					Petites annonces
				</div>
				<div class="description-sub">
					Recevez une notification lorsque
					de nouvelles annonces sont publiées.
				</div>
			</ion-label>
			<ion-toggle (click)="changeNotificationOptions($event,'offer')"
						formControlName="offer"
						mode="ios"
						slot="end"></ion-toggle>
		</ion-item>
		<ion-item *ngIf="user.notificationEnabled" class="border" lines="none">
			<ion-label class="ion-text-wrap" slot="start">
				<div class="title-sub">
					Messages
				</div>
				<div class="description-sub">
					Recevez une notification lorsque vous
					recevez des messages dans vos
					discussions privées.
				</div>
			</ion-label>
			<ion-toggle (click)="changeNotificationOptions($event,'message')"
						formControlName="message"
						mode="ios"
						slot="end"></ion-toggle>
		</ion-item>
		<ion-item *ngIf="user.notificationEnabled" lines="none" style="padding-top: 15px">
			<ion-label class="ion-text-wrap" slot="start">
				<div class="title-sub">
					Evenements
				</div>
				<div class="description-sub">
					Recevez une notification lorsque de
					nouveaux événements proche de chez
					vous sont prévus.
				</div>
			</ion-label>
			<ion-toggle (click)="changeNotificationOptions($event,'event')"
						formControlName="event"
						mode="ios"
						slot="end"></ion-toggle>
		</ion-item>
	</ion-card>
	<ion-card [formGroup]="cookForm" class="notif">
		<ion-item lines="none">
			<ion-label class="ion-text-wrap" slot="start">
				<div class="title">
					<ion-icon name="notifications-outline"></ion-icon>
					Cookies
				</div>
				<div class="description ">
					Autorisez à partager vos données.<br>
					Cette application utilise des cookies provenant de Facebook pour mesurer son audience, entretenir la
					relation avec vous et vous adresser de temps à autres du contenu qualitatif ainsi que de la
					publicité de promotion de votre application. CFTC l'App vous donne le contrôle d'activer ou de
					désactiver ces cookies.
				</div>
			</ion-label>
			<ion-toggle (click)="changeCookie($event)" formControlName="cookieEnabled" mode="ios"
						slot="end"></ion-toggle>
		</ion-item>
		<!--ion-item *ngIf="user.cookieEnabled" class="border" lines="none">
			<ion-label class="ion-text-wrap" slot="start">
				<div class="title-sub">
					Analytics
				</div>
				<div class="description-sub">
					Activer / Désactiver les cookies en provenance de Google
				</div>
			</ion-label>
			<ion-toggle (click)="changeCookieOptions($event,'analytics')" formControlName="analytics"
						mode="ios"
						slot="end"></ion-toggle>
		</ion-item-->
		<!--<ion-item *ngIf="user.cookieEnabled" lines="none">
			<ion-label class="ion-text-wrap" slot="start">
				<div class="title-sub">
					Facebook
				</div>
				<div class="description-sub">
					Activer / Désactiver les cookies en provenance de Facebook
				</div>
			</ion-label>
			<ion-toggle (click)="changeCookieOptions($event,'facebook')" formControlName="facebook"
						mode="ios"
						slot="end"></ion-toggle>
		</ion-item>-->
	</ion-card>
	<ion-card *ngIf="user && user.role==='inaric'" class="notif">
		<ion-item class="border" lines="none">
			<ion-label class="ion-text-wrap" slot="start">
				<div class="title">
					<ion-icon name="newspaper-outline"></ion-icon>
					Mes abonnements papiers (reçus à mon domicile)
				</div>
				<div class="description ">

				</div>
			</ion-label>
		</ion-item>
		<ion-item *ngFor="let sub of subsPaper; let last=last" [class.border]="!last" lines="none">
			<ion-label class="ion-text-wrap" slot="start">
				<div class="title-sub">
					{{getTitle(sub.title)}}
				</div>
				<div class="description-sub">
					{{sub.description}}
				</div>
			</ion-label>
			<ion-toggle (click)="changeSubPaper($event,sub)" [(ngModel)]="sub.isSub"
						mode="ios"
						slot="end"></ion-toggle>
		</ion-item>
	</ion-card>
	<ion-card *ngIf="user && user.role==='inaric'" [formGroup]="subNewsForm" class="notif">
		<ion-item class="border" lines="none">
			<ion-label class="ion-text-wrap" slot="start">
				<div class="title">
					<ion-icon name="mail-outline"></ion-icon>
					Mes abonnements newsletters CFTC
				</div>
				<div class="description">

				</div>
			</ion-label>
		</ion-item>
		<ion-item class="border" lines="none">
			<ion-label class="ion-text-wrap" slot="start">
				<div class="title-sub">
					Newsletter CFTC l’App
				</div>
				<div class="description-sub">

				</div>
			</ion-label>
			<ion-toggle (click)="changeSubNews($event,'cftc_app')" formControlName="cftc_app"
						mode="ios"
						slot="end"></ion-toggle>
		</ion-item>
		<ion-item class="" lines="none">
			<ion-label class="ion-text-wrap" slot="start">
				<div class="title-sub">
					Newsletter CFTC bi-mensuelle
				</div>
				<div class="description-sub">

				</div>
			</ion-label>
			<ion-toggle (click)="changeSubNews($event,'cftc_bi_mensuelle')" formControlName="cftc_bi_mensuelle"
						mode="ios"
						slot="end"></ion-toggle>
		</ion-item>
	</ion-card>
	<!--<ion-card class="password">
		<form (ngSubmit)="changePassword()" [formGroup]="authForm">
			<ion-grid>
				<ion-row>
					<ion-col size="12">
						<ion-label class="title">
							<ion-icon name="lock-closed-outline"></ion-icon>
							Modifier le mot de passe
						</ion-label>
					</ion-col>
					<ion-col size="12">
						<ion-label class="title-input">
							Nouveau mot de passe
						</ion-label>
					</ion-col>
					<ion-col size="12">
						<ion-item class="ion-no-padding ion-no-margin" lines="none"
								  style="border-radius: 8px;--padding-end: 0;--inner-padding-end: 7px">
							<ion-input [type]="showPassword ? 'text' : 'password'" autocomplete="on" autocorrect="on"
									   clearOnEdit="false"
									   formControlName="password"
									   placeholder="Min. 8 caractères, avec chiffres & majuscules"
									   spellcheck="true"></ion-input>
							<ion-button (click)="onPasswordToggle()"
										class="bg-transparent bd-transparent text-green">
								<ion-icon [name]="showPassword ? 'eye-off' : 'eye'"></ion-icon>
							</ion-button>
						</ion-item>
					</ion-col>
					<ion-col size="12">
						<ion-label class="title-input">
							Re-tapez votre nouveau mot de passe
						</ion-label>
					</ion-col>
					<ion-col size="12">
						<ion-item class="ion-no-padding ion-no-margin" lines="none"
								  style="border-radius: 8px;--padding-end: 0;--inner-padding-end: 7px">
							<ion-input [type]="showConfirmPassword ? 'text' : 'password'" autocomplete="on"
									   autocorrect="on"
									   clearOnEdit="false"
									   formControlName="confirmPassword"
									   placeholder="Min. 8 caractères, avec chiffres & majuscules"
									   spellcheck="true"></ion-input>
							<ion-button (click)="onConfirmPasswordToggle()"
										class="bg-transparent bd-transparent text-green">
								<ion-icon [name]="showConfirmPassword ? 'eye-off' : 'eye'"></ion-icon>
							</ion-button>
						</ion-item>
					</ion-col>
					<ion-col size="12">
						<ion-button [disabled]="authForm.invalid" class="bg-yellow" style="width: 100%;"
									type="submit">Modifier mon mot de passe
						</ion-button>
					</ion-col>
				</ion-row>
			</ion-grid>
		</form>
	</ion-card>-->

</ion-content>
