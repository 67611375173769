import {Component, OnInit} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {User} from "../../../shared/models/user";
import {UserService} from "../../../shared/services/user.service";
import {Camera, CameraResultType, CameraSource} from "@capacitor/camera";
import {Router} from "@angular/router";
import {ToastService} from "../../../shared/services/utils/toast.service";


@Component({
	selector: "app-picture",
	templateUrl: "./picture.component.html",
	styleUrls: ["./picture.component.css"],
})
export class PictureComponent implements OnInit {
	user: User;
	error = "";

	constructor(private router: Router, private toastService: ToastService, public modalController: ModalController, private userServices: UserService) {

	}

	ngOnInit(): void {
		this.user = this.userServices.getUserAuthValue();
	}

	dismissModal() {
		this.modalController.dismiss({
			dismissed: true,
		});
	}

	async picture() {
		const image = await Camera.getPhoto({
			quality: 75,
			width: 600,
			allowEditing: false,
			resultType: CameraResultType.Base64,
			source: CameraSource.Photos,
			correctOrientation: false,
			promptLabelHeader: "Photo",
			promptLabelCancel: "Annuler",
			promptLabelPhoto: "Photo",
			promptLabelPicture: "Gallerie",
		});
		this.send(image);
	}

	async camera() {
		const image = await Camera.getPhoto({
			quality: 75,
			width: 600,
			allowEditing: false,
			resultType: CameraResultType.Base64,
			correctOrientation: false,
			source: CameraSource.Camera,
			promptLabelHeader: "Photo",
			promptLabelCancel: "Annuler",
			promptLabelPhoto: "Photo",
			promptLabelPicture: "Gallerie",
		});
		this.send(image);
	}

	send(image) {
		const base64 = "data:image/" + image.format + ";base64," + image.base64String;
		this.userServices.picture(base64).subscribe(value => {
			this.user = this.userServices.getUserAuthValue();
			this.user.picture += `?cache=${new Date().getTime()}`;
		}, error => {
			this.toastService.show(error);
		});
	}

	delete() {
		this.userServices.pictureDelete().subscribe(value => {
			this.user = this.userServices.getUserAuthValue();
			this.user.picture += `?cache=${new Date().getTime()}`;
		}, error => {
			this.toastService.show(error);
		});
	}

}
