<ion-item lines="none" style="--background: white!important;--min-height: 66px;--max-height: 66px">
	<ion-icon (click)="dismissModal()" *ngIf="responsive.isMobile()" class="back-icon"
			  name="chevron-back-outline"></ion-icon>
	<ion-label class="back">{{title}}</ion-label>
	<ion-icon (click)="dismissModal()" *ngIf="responsive.isDesktop()" class="back-icon" name="close-outline"
			  slot="end"></ion-icon>
</ion-item>
<ion-grid [class]="responsive.isDesktop()?'ion-content-desktop':'ion-content'">
	<ion-row style="height: 100%;">
		<ion-col size="12" style="height: 100%;">

			<ion-content class="bg" #scroll style="height: calc(100% - 113px);overflow-y: auto;">
				<ion-row class="content-chat">
					<ion-col [class]="responsive.isDesktop()?'content-chat-first':'content-chat-first-mobile'"
							 size="12">
						<ion-card style="margin-bottom: 0">
							<ion-grid>
								<ion-row>
									<ion-col size="2" size-lg="1">
										<ion-item class="ion-no-margin ion-no-padding item-avatar" lines="none">
											<ion-avatar (click)="openUser(subject.owner)"
														[class.avatar-desktop]="responsiveService.isDesktop()">
												<img [src]="subject.owner.picture"/>
											</ion-avatar>
										</ion-item>


									</ion-col>

									<ion-col class="title" size="8" size-lg="9">
										{{subject.title}}
									</ion-col>
									<ion-col size="2" style="text-align: right;">
										<ion-button (click)="openMenu($event)" style="--background: white">
											<ion-icon name="ellipsis-horizontal" style="margin-left: 5px"></ion-icon>
										</ion-button>
									</ion-col>
								</ion-row>
								<ion-row style="margin-top: 10px;margin-bottom: 10px">
									<ion-col size="2" size-lg="1">
									</ion-col>
									<ion-col class="date" size="10" size-lg="11">
										Créé par {{subject.owner.lastName}} {{subject.owner.firstName[0]}}.
										| {{subject.posts.length}} réponse<span *ngIf="subject.posts.length>1">s</span>
									</ion-col>
								</ion-row>
								<ion-row>
									<ion-col size="2" size-lg="1">
									</ion-col>
									<ion-col class="text" size="10" size-lg="11">
										<div [innerHTML]="subject.description | linkify:'http-link-blue'"
											 style="white-space:pre-wrap;"></div>
									</ion-col>
								</ion-row>
								<ion-row>
									<ion-col size="2">
									</ion-col>
									<ion-col class="text" size="10">
										<ion-item *ngFor="let file of subject.files" class="files ion-no-padding"
												  lines="none">
											<ion-icon name="attach-outline"></ion-icon>
											<ion-label><a [href]="file.filename" target="_blank">{{file.name}}</a>
											</ion-label>
										</ion-item>
									</ion-col>
								</ion-row>
							</ion-grid>
						</ion-card>
						<div class="triangle" style="position: relative;bottom: 0;margin-bottom: 10px"></div>
					</ion-col>
					<ion-col class="content-chat-other" size="12">
						<ion-list style="padding: 0;">
							<app-forum-responce-renderer (refresh)="init()"
														 *ngFor="let item of subject.posts; let i = index"
														 [action]="true"
														 [index]="i"
														 [post]="item"></app-forum-responce-renderer>
						</ion-list>
					</ion-col>
				</ion-row>

			</ion-content>
			<ion-row
				style="position: absolute;bottom: 0px;width: 100%; z-index:2; max-height: 50vh; overflow-y: auto;">
				<ion-col size="12" style="margin: 0;padding: 0;">
					<ion-row class="message">
						<ion-col *ngIf="newFile && newFile.name !== ''" size="12">
							<ion-item class="files ion-no-padding" lines="none" style="margin-bottom: 0">
								<ion-icon name="attach-outline"></ion-icon>
								<ion-label>{{newFile.name}}</ion-label>
								<ion-icon (click)="removeImage()" name="close-outline"
										  style="margin-left: 10px"></ion-icon>
							</ion-item>
						</ion-col>
						<div style="width: calc(100% - 100px);">
							<ion-textarea #input [(ngModel)]="message" [autoGrow]="true" autocapitalize="sentences"
										  autocomplete="on" autocorrect="on"
										  placeholder="Répondre..." spellcheck="true"></ion-textarea>
						</div>
						<div style="width: 100px">
							<ion-row>
								<ion-col size="6" style="position: relative; text-align: center">
									<input #fileInput (change)="chooseFileBrowner($event)" [disabled]="user.inaric===''"
										   accept="application/pdf" style="display:none;"
										   type="file">
									<ion-icon (click)="fileInput.click()" class="icon" name="attach-outline"
											  style="    margin-left: 10px;"></ion-icon>
								</ion-col>
								<ion-col size="6" style="position: relative;text-align: center">
									<ion-icon (click)="chooseImage()" class="icon" name="camera-outline"
											  style="    margin-left: 10px;"></ion-icon>
								</ion-col>
							</ion-row>
							<ion-row>
								<ion-col size="12" style="position: relative">
									<div (click)="sendMessage(input.value)" class="send">
										<div class="icon">
											<ion-icon name="paper-plane-outline"
													  style="margin-bottom: 10px;"></ion-icon>
										</div>
									</div>
								</ion-col>
							</ion-row>
						</div>
					</ion-row>
				</ion-col>
			</ion-row>
		</ion-col>
	</ion-row>
</ion-grid>

