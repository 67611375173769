<ion-item (click)="dismissModal()" class="header" lines="none">
	<ion-icon name="chevron-back-outline"></ion-icon>
	<ion-label>{{title}}</ion-label>
</ion-item>
<ion-content>
	<ion-grid [style.margin]="responsive.isMobile()?'15px 15px 75px 15px':'none'" style="max-width: 780px;">
		<ion-row>
			<ion-col size="12">
				<ion-row>
					<ion-col>
						<ion-card
							style="margin-top: 62px;text-align: center;   padding-bottom: 23px;   overflow: initial; contain: none">
							<ion-grid>
								<ion-row>
									<ion-col size="12" style="height: 62px;">
										<ion-avatar [class.argent]="user.level===1" [class.bronze]="user.level===0"
													[class.or]="user.level===2">
											<img height="123px" src="{{user.picture}}">
										</ion-avatar>

									</ion-col>
									<ion-col class="name" size="12">
										{{user.lastName}} {{user.firstName}}
									</ion-col>
									<!--   <ion-col *ngIf="user.level === 0" class="game" size="12">
										 Militant bronze
									 </ion-col>
									 <ion-col *ngIf="user.level === 1" class="game" size="12">
										 Militant argent
									 </ion-col>
									 <ion-col *ngIf="user.level === 2" class="game" size="12">
										 Militant or
									 </ion-col>-->
									<ion-col class="info" size="12">
										{{user.syndicat}}
									</ion-col>
									<ion-col *ngIf="user.employer !=='-'" class="info" size="12">
										{{user.employer}}
									</ion-col>
									<ion-col *ngIf="user.city !=='-'" class="info" size="12">
										{{user.city}}
									</ion-col>
									<ion-col class="counter" size="6" style="border-right: solid 1px #47B4BB40;">
										<b style="font-size:16px">{{user.posts.length}}</b><br>
										discussion<span *ngIf="user.posts.length>1">s</span><br>
										sur le forum
									</ion-col>
									<ion-col class="counter" size="6">
										<b style="font-size:16px">{{user.participations.length}}</b><br>
										événements auxquels je participe<span *ngIf="user.participations.length>1">s</span>
									</ion-col>
									<ion-col size="12">
										<ion-button (click)="openChat()" *ngIf="!user.blocked"
													class="button-chat">
											<ion-icon name="chatbox-outline" style="margin-right: 5px"></ion-icon>
											Ecrire
										</ion-button>
										<ion-button (click)="unlock()" *ngIf="user.blocked"
													class="bd-red bg-red button">
											<ion-icon name="ban-outline" style="margin-right: 5px"></ion-icon>
											Débloquer
										</ion-button>
									</ion-col>
								</ion-row>
							</ion-grid>
						</ion-card>
					</ion-col>
				</ion-row>
			</ion-col>
		</ion-row>
	</ion-grid>
</ion-content>

