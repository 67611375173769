import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpService} from './utils/http.service';
import {PaginationService} from './pagination';
import {Chat} from '../models/chat';
import {User} from '../models/user';
import {Message} from '../models/message';

@Injectable({
    providedIn: 'root'
})
export class MessengerService extends PaginationService {

    chats: BehaviorSubject<Array<Chat>>;
    chat: BehaviorSubject<Chat>;
    notification: BehaviorSubject<number>;

    constructor(private https: HttpService) {
        super();
        this.chats = new BehaviorSubject<Array<Chat>>([]);
        this.chat = new BehaviorSubject<Chat>(null);
        this.notification = new BehaviorSubject<number>(0);
    }

    getNotification(): Observable<number> {
        return this.notification;
    }

    gets(): Observable<any> {

        return this.https.get('messenger').pipe(
            map((res: any) => {
                const newArray = res.map(item => {

                    return new Chat(item);

                });
                this.chats.next(newArray);
                return this.chats.getValue();
            })
        );

    }

    get(uuid: string): Observable<Chat> {
        return this.https.get('messenger/' + uuid).pipe(
            map((res: any) => {
                const chat: Chat = new Chat(res);
                this.chat.next(chat);
                return chat;
            })
        );
    }

    create(name: string, participants: User[]): Observable<any> {

        const inaric = [];
        for (let i = 0; i < participants.length; i++) {
            inaric.push(participants[i].inaric);
        }

        return this.https.post('messenger', {
            name,
            inaric,
        }).pipe(
            map((res: any) => {
                const chat: Chat = new Chat(res);
                this.chat.next(chat);
                return chat;
            })
        );

    }

    update(uuid: string, name: string, participants: User[]): Observable<any> {
        const inaric = [];
        for (let i = 0; i < participants.length; i++) {
            inaric.push(participants[i].inaric);
        }

        return this.https.patch('messenger/' + uuid, {
            name,
            inaric,
        });

    }

    delete(uuid: string): Observable<any> {
        return this.https.delete('messenger', uuid);
    }

    send(uuid: string, text: string): Observable<any> {
        return this.https.post('messenger/' + uuid + '/message', {
            text,
        }).pipe(
            map((res: any) => {
                const message: Message = new Message(res);
                return message;
            })
        );
    }

    signal(uuid: string, object: object): Observable<any> {
        return this.https.post('messenger/' + uuid + '/signal', object);
    }

    exit(uuid: string): Observable<any> {
        return this.https.get('messenger/' + uuid + '/exit');
    }
}
