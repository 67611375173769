import {AfterViewInit, Component, OnInit} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {Router} from "@angular/router";
import {EventService} from "../../../shared/services/event.service";

@Component({
	selector: "app-filter",
	templateUrl: "./filter-event.component.html",
	styleUrls: ["./filter-event.component.scss"],
})
export class FilterEventComponent implements OnInit, AfterViewInit {

	filters: any[] = [];

	categories: any[] = [];

	constructor(
		private router: Router,
		private modalController: ModalController,
		private eventService: EventService,
	) {
	}

	ngOnInit(): void {
		this.categories = [];
		// tslint:disable-next-line:forin
		for (const key in this.eventService.categories) {
			const category = {
				key,
				name: this.eventService.categories[key],
				select: false
			};
			for (const filter of this.filters) {
				if (filter === key) {
					category.select = true;
				}
			}
			this.categories.push(category);
		}

	}

	ngAfterViewInit() {
	}

	dismissModal() {
		this.modalController.dismiss({
			dismissed: true,
			filters: this.filters
		});
	}

	cancel() {
		this.dismissModal();
	}

	close() {
		this.filters = [];
		for (const category of this.categories) {
			if (category.select === true) {
				this.filters.push(category.key);
			}
		}
		this.dismissModal();
	}

	selectfilter(evt, item) {
		//this.categories[item].select = evt.returnValue;
	}
}
