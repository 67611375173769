import {User} from "./user";
import {File} from "./file";

export class ForumPost {
	public id = 0;
	public uuid = "";
	public text = "";
	public owner: User = new User();
	public files: File[] = [];
	public createdAt = new Date();
	public updatedAt = new Date();
	public select = false;

	constructor(data = null) {
		if (data) {
			this.id = data.id;
			this.uuid = data.uuid;
			this.text = data.text;
			if (data.owner !== undefined) {
				this.owner = new User(data.owner);
			}
			if (data.files !== undefined) {
				this.files = data.files.map(item => {
					return new File(item);
				});
			}
			this.createdAt = data.createdAt;
			this.updatedAt = data.updatedAt;
		}
	}


}
