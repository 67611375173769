import {Component, OnInit} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {ResponsiveService} from "../../../shared/services/utils/responsive.service";
import {Event} from "../../../shared/models/event";
import {EventService} from "../../../shared/services/event.service";
import {Router} from "@angular/router";
import {UserService} from "../../../shared/services/user.service";
import {User} from "../../../shared/models/user";
import {ToastService} from "../../../shared/services/utils/toast.service";
import {ModalService} from "../../../shared/services/utils/modal.service";

@Component({
	selector: "app-modal-event-my",
	templateUrl: "./event-my.component.html",
	styleUrls: ["./event-my.component.scss"],
})
export class EventMyModalComponent implements OnInit {
	event: Event = new Event();
	user: User = new User();
	interesteds: Event[] = [];
	organizers: Event[] = [];
	pasts: Event[] = [];
	error: string = "";

	constructor(
		private router: Router, private toastService: ToastService,
		private userService: UserService,
		public modalController: ModalController,
		public responsive: ResponsiveService,
		private eventService: EventService,
		private modalService: ModalService
	) {

	}

	ngOnInit(): void {
		this.init();
	}

	async init() {
		await this.modalService.openLoading();
		await this.userService.updateLatLong();
		this.user = this.userService.getUserAuthValue();
		this.eventService.getMyEvents(this.user.geoLat, this.user.geoLon).subscribe((events: any) => {
			this.interesteds = events.interesteds;
			this.organizers = events.organizers;
			this.pasts = events.pasts;
			this.modalService.closeLoading();
		}, error => {
			this.modalService.closeLoading();
			this.toastService.show(error);
		});
	}

	dismissModal() {
		this.modalController.dismiss({
			dismissed: true,
		});
	}

	createEvent() {
		this.openEventCreate(new Event());
	}

	async openEventCreate(event) {

		this.modalService.openEventCreate(event, "", '', () => {
			this.init();
		});

	}

	showEvent(item) {
		this.openEvent(item);
	}

	async openEvent(event) {
		this.modalService.openEvent(event, "", () => {

		});
	}

	editEvent(item) {
		this.openEventCreate(item);
	}

}
