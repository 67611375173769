<ion-header class="ion-no-border">
	<ion-toolbar>
		<ion-icon (click)="dismissModal()" name="close-outline" slot="start"></ion-icon>
		<ion-title>Badge numérique</ion-title>
	</ion-toolbar>
</ion-header>
<ion-content class="ion-no-padding">
	<ion-row [style.background]="infos.TYPE_PARTICIPANT_COLOR" class="qrcode">
		<ion-col size="12" style="text-align: center">
			<img *ngIf="infos.qrcode !=='' && !loading" [class.qrcode-img-desktop]="responsive.isDesktop()"
				 [src]="infos.qrcode"
				 alt="qrcode" class="qrcode-img"/>
			<ion-spinner *ngIf="loading"></ion-spinner>
		</ion-col>
		<ion-col *ngIf="loading" class="title" size="12">Chargement en cours</ion-col>
		<ion-col *ngIf="!loading" [style.color]="colors[infos.TYPE_PARTICIPANT_COLOR]+' !important'" class="name"
				 size="12">{{user.firstName}} {{user.lastName}} </ion-col>
		<ion-col *ngIf="!loading" [style.color]="colors[infos.TYPE_PARTICIPANT_COLOR]+' !important'" class="title"
				 size="12">{{infos.TYPE_PARTICIPANT}}</ion-col>
		<ion-col *ngIf="!loading" [style.color]="colors[infos.TYPE_PARTICIPANT_COLOR]+' !important'" class="id"
				 size="12">
			ID: {{user.inaric}}</ion-col>
	</ion-row>
	<ion-row *ngIf="!loading" class="infos">
		<ion-col class="title" size="12">
			Mon badge numérique au congrès CFTC
		</ion-col>
		<ion-col *ngIf="!fileMode" class="description" size="12">
			Présentez ce badge numérique à l’entrée de l’événement.<br>
			<span *ngIf="infos.PRESTATIONS.length>0">Il vous donnera ensuite accès :</span>
		</ion-col>
		<ion-col *ngIf="fileMode" class="description" size="12">
			Ce badge vous a permis d'accéder avec plus de facilité au Congrès et ses différents espaces ainsi que de
			récupérer vos goodies.<br>
			Vous pouvez désormais télécharger votre attestation de présence au Congrès CFTC. Pour cela, cliquez sur le
			bouton ci-dessous.
		</ion-col>

		<ion-col *ngFor="let p of infos.PRESTATIONS" [hidden]="fileMode" class="list" size="12">
			<div class="title">
				{{titles[p.TYPE_PRESTATION]}}
			</div>
			<div *ngIf="p.LIBELLES_PRESTATIONS.length > 0">
				<div *ngFor="let l of p.LIBELLES_PRESTATIONS" class="point">• {{l}}</div>
			</div>
		</ion-col>


		<ion-col *ngIf="!fileMode" class="program" size="12">
			Vous ne vous êtes pas encore inscrits ou souhaitez modifier votre inscription à votre atelier du jeudi
			matin, à la célébration œcuménique ou à la représentation théâtrale ? Vous avez jusqu'au lundi 13 novembre à
			13 heures pour cela !
		</ion-col>
		<ion-col *ngIf="!fileMode" class="button" size="12">
			<ion-button (click)="openProgramme()" class="bd-blue bg-blue text-white">
				Choisir ou modifier ses rendez-vous
			</ion-button>
		</ion-col>
		<ion-col *ngIf="fileMode" class="button" size="12">
			<ion-button (click)="openFile()" class="bd-blue bg-blue text-white">
				Je télécharge mon attestation
			</ion-button>
		</ion-col>
	</ion-row>
</ion-content>
<ion-content *ngIf="loading">

</ion-content>
