<div [class.hidden]="hidden" class="content">
	<a class="banner">
		<div class="banner--wrapper">
			<img alt="Logo" class="banner--logo" height="60" src="/assets/image/logo-banner.png" width="60"/>
			<p class="banner--paragraph">Téléchargez l’app sur votre mobile</p>
		</div>
		<span (click)="open()" class="banner--button">Ouvrir</span>
	</a>
	<div (click)="close()" class="close">X</div>
</div>



