import {Component, OnInit} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {ThematicCollection} from "../../../shared/models/thematicCollection";
import {ThematicContent} from "../../../shared/models/thematicContent";
import {ThematicService} from "../../../shared/services/thematic.service";
import {ModalService} from "../../../shared/services/utils/modal.service";
import {Browser} from "@capacitor/browser";
import {UserService} from "../../../shared/services/user.service";
import {ScrollToService} from "@hkjeffchan/ngx-scroll-to";

@Component({
	selector: "app-modal-thematic",
	templateUrl: "./thematic.component.html",
	styleUrls: ["./thematic.component.scss"],
})
export class ThematicModalComponent implements OnInit {
	autoplay: boolean = false;
	content: ThematicContent = new ThematicContent();
	thematic: ThematicCollection = new ThematicCollection();
	slug: string;
	showAll: boolean = false;
	showQRCode = false;

	constructor(
		private modalController: ModalController,
		private modalService: ModalService,
		private thematicService: ThematicService,
		private userService: UserService,
		private scrollToService: ScrollToService
	) {
	}

	ngOnInit(): void {
		if (this.thematic) {
			this.thematicService.getContents(this.thematic).subscribe((res: any) => {
				this.content = res;
			});
		}
		this.userService.getPresenceCongres().subscribe((value) => {
			//todo : remove this line in production
			this.showQRCode = value;
		});
	}

	dismissModal() {
		this.modalController.dismiss({
			dismissed: true,
		});
	}

	openLink(url: string) {
		if (url === "cse") {
			this.modalService.openCSE(() => {
			});
		} else {
			Browser.open({url: url});
		}
	}

	changeAnchor(evt: any) {
		/*const config: ScrollToConfigOptions = {
			target: evt
		};
		this.scrollToService.scrollTo(config);*/

	}
}
