<ion-header class="ion-no-border" translucent>
	<ion-toolbar>
		<ion-title [class]="responsive.isMobile() ? 'title' : 'title-desktop'" class="ion-text-center">Devenir élu CSE
		</ion-title>
		<ion-buttons slot="start">
			<ion-button (click)="dismissModal()" class="button-close">
				<ion-icon name="close-outline" style="font-size: 42px;color: var(--cftc-color-1);"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>

</ion-header>
<ion-content fullscreen style="--overflow: auto;">
	<ion-row style="justify-content: center; text-align:center;margin-bottom: 5px !important;">
		<ion-col style="padding-bottom: 0 !important;">
			<ion-label [class]="responsive.isMobile() ? 'title-text' : 'title-text-desktop'" class="title-text">
				Vous souhaitez devenir élu CSE au nom de la CFTC ?
			</ion-label>
		</ion-col>
	</ion-row>
	<ion-row style="justify-content: center; text-align:center;">
		<ion-col style="padding-top: 0 !important; padding-bottom: 15px !important;">
			<ion-label [class]="responsive.isMobile() ? 'description' : 'description-desktop'">
				Nous pouvons vous accompagner dans votre démarche ! En remplissant ce formulaire, vous serez mis en
				contact avec un responsable CFTC de votre structure de rattachement. Une fois le formulaire rempli,
				vous recevrez un mail de confirmation contenant un kit "Je veux devenir élu CSE".
			</ion-label>
		</ion-col>
	</ion-row>
	<form (ngSubmit)="onSubmit()" [class.form-desktop]="responsive.isDesktop()"
		  [formGroup]="form">
		<ion-row style="justify-content: center">
			<ion-col class="maxWidth">
				<ion-input autocapitalize="sentences" autocomplete="on" autocorrect="on" formControlName="name"
						   id="name" name="name"
						   placeholder="Nom de mon entreprise" spellcheck="true"></ion-input>
			</ion-col>
		</ion-row>
		<ion-row style="justify-content: center">
			<ion-col class="maxWidth">
				<ion-input autocapitalize="sentences" autocomplete="on" autocorrect="on" formControlName="address"
						   id="address" name="address"
						   placeholder="Adresse de mon entreprise" spellcheck="true"></ion-input>
			</ion-col>
		</ion-row>
		<ion-row style="justify-content: center">
			<ion-col class="maxWidth">
				<ion-input autocapitalize="sentences" autocomplete="on" autocorrect="on" formControlName="postal"
						   id="postal" name="postal"
						   placeholder="Code postal" spellcheck="true" type="string"></ion-input>
			</ion-col>
			<ion-col class="maxWidth">
				<ion-input autocapitalize="sentences" autocomplete="on" autocorrect="on" formControlName="city"
						   id="city" name="city"
						   placeholder="Ville" spellcheck="true"></ion-input>
			</ion-col>
		</ion-row>
		<ion-row style="justify-content: center">
			<ion-col class="maxWidth">
				<ion-input autocapitalize="sentences" autocomplete="on" autocorrect="on" formControlName="phone"
						   id="phone" name="phone"
						   placeholder="Mon numéro de téléphone" spellcheck="true" type="string"></ion-input>
			</ion-col>
		</ion-row>
		<ion-row style="justify-content: center">
			<ion-col class="maxWidth">
				<ion-input autocapitalize="sentences" autocomplete="on" autocorrect="on" formControlName="email"
						   id="email" name="email"
						   placeholder="Mon adresse email" type="email">
				</ion-input>
			</ion-col>
		</ion-row>
		<ion-row style="justify-content: center">
			<ion-col class="maxWidth">
				<ion-button class="bg-yellow" style="width: 100%;" type="submit">
					Je valide
				</ion-button>
			</ion-col>
		</ion-row>

	</form>

</ion-content>


