export class Alert {
	public id = 0;
	public uuid = "";
	public uuidDestination = "";
	public name = "";
	public category = "";
	public createdAt = new Date().toString();
	public updatedAt = new Date().toString();


	constructor(data = null) {
		if (data) {
			this.id = data.id;
			this.uuid = data.uuid;
			this.uuidDestination = data.uuidDestination;
			this.name = data.name;
			this.category = data.category;
			this.createdAt = data.createdAt;
			this.updatedAt = data.updatedAt;
		}
	}
}
