import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ResponsiveService} from '../../../shared/services/utils/responsive.service';
import {EventService} from '../../../shared/services/event.service';
import {HttpClient, HttpEventType} from '@angular/common/http';
import {Directory, Filesystem} from '@capacitor/filesystem';
import {Share} from '@capacitor/share';
import {Capacitor} from '@capacitor/core';

@Component({
	selector: 'app-modal-pdf-viewer',
	templateUrl: './pdf-viewer.component.html',
	styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerModalComponent implements OnInit {
	url = '';
	title = '';
	fileName = '';
	downloadProgress = 0;

	constructor(
		public modalController: ModalController,
		public responsive: ResponsiveService,
		public eventService: EventService,
		private http: HttpClient
	) {

	}


	ngOnInit(): void {

	}

	dismissModal() {
		this.modalController.dismiss({});
	}

	downloadFile() {
		try {
			if (Capacitor.getPlatform() == 'ios') {
				this.http.get(this.url, {
					responseType: 'blob',
					reportProgress: true,
					observe: 'events'

				}).subscribe(async (event: any) => {
					if (event.type === HttpEventType.DownloadProgress) {
						this.downloadProgress = Math.round(100 * event.loaded / event.total);
					} else if (event.type === HttpEventType.Response) {
						this.downloadProgress = 0;
						const base64 = await this.blobToBase64(event.body) as string;

						const saveFile = await Filesystem.writeFile({
							path: this.fileName,
							data: base64,
							directory: Directory.Documents,
						});

						await Share.share({
							title: this.fileName,
							dialogTitle: 'CFTC - Partage de fichier',
							files: [saveFile.uri],
						});
					}
				});
			} else {
				window.open(this.url, '_system');
			}
		} catch (e) {
			console.log(e);
		}
	}

	blobToBase64(blob) {
		return new Promise((resolve, _) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result);
			reader.readAsDataURL(blob);
		});
	}
}
