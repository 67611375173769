<div [class]="responsive.isDesktop()?'ion-content-all-desktop':'ion-content-all'">
	<ion-item lines="none" style="--background: white!important;--min-height: 66px;--max-height: 66px">
		<ion-icon (click)="dismissModal()" *ngIf="responsive.isMobile()" class="back-icon"
				  name="chevron-back-outline"></ion-icon>
		<ion-label class="back">Actualité</ion-label>
		<ion-icon (click)="dismissModal()" *ngIf="responsive.isDesktop()" class="back-icon" name="close-outline"
				  slot="end"></ion-icon>
	</ion-item>
	<div style="width:100%;height:100%;">
		<iframe [src]="article.url | safe" allowfullscreen frameborder="0" height="100%"
				style="width:100%;height:calc(100% - 46px);"></iframe>
	</div>
</div>

