import {Component, OnInit} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {ResponsiveService} from "../../../shared/services/utils/responsive.service";
import {Event} from "../../../shared/models/event";
import {EventService} from "../../../shared/services/event.service";
import {Router} from "@angular/router";
import {AlertHelper} from "../../../shared/helpers/alert.helper";
import {User} from "../../../shared/models/user";
import {UserService} from "../../../shared/services/user.service";
import {ToastService} from "../../../shared/services/utils/toast.service";

@Component({
	selector: "app-modal-profile",
	templateUrl: "./signalement.component.html",
	styleUrls: ["./signalement.component.scss"],
})
export class SignalementModalComponent implements OnInit {
	event: Event = new Event();
	text = "";
	state = 0;
	user = new User();
	reasons = [
		{
			text: "Propos à caractères haineux",
			select: false
		},
		{
			text: "Incitation à la haine",
			select: false
		},
		{
			text: "Harcèlement sexuel",
			select: false
		},
		{
			text: "Autre possibilité",
			select: false
		},
	];

	constructor(
		private router: Router, private toastService: ToastService,
		private alertHelper: AlertHelper, public modalController: ModalController,
		public responsive: ResponsiveService, public eventService: EventService,
		private userService: UserService) {

	}


	ngOnInit(): void {
		this.init();
	}

	init() {
		this.user = this.userService.getUserAuthValue();
	}

	dismissModal(result) {
		this.modalController.dismiss({
			dismissed: true,
			result
		});
	}

	send() {
		this.dismissModal({reasons: this.reasons, text: this.text});
	}

	selectReason(index) {
		this.reasons[index].select = !this.reasons[index].select;
	}
}
