<ion-item (click)="dismissModal()" lines="none"
		  style="--background: #f1f1f1!important;background: #f1f1f1!important;--padding-start: 12px;">
	<ion-icon class="back-icon" name="chevron-back-outline"></ion-icon>
	<ion-label class="back">Mes évènements</ion-label>
</ion-item>
<ion-content style="--background: transparent;">
	<ion-grid style="padding: 15px;">
		<ion-row>
			<ion-col class="title" size="12">
				Je suis intéressé·e
			</ion-col>
			<ion-col size="12">
				<swiper *ngIf="interesteds.length >0" [grabCursor]="true" [slidesPerView]="'auto'" [spaceBetween]="10">
					<ng-template *ngFor="let item of interesteds" swiperSlide>
						<app-event-my-renderer (click)="showEvent(item)" [item]="item"></app-event-my-renderer>
					</ng-template>
				</swiper>
				<div *ngIf="interesteds.length ==0" class="not-event">
					<div class="not-event-text">
						Vous n’avez pas d’événement
						dans votre liste pour le moment
					</div>
				</div>
			</ion-col>
		</ion-row>
		<ion-row>
			<ion-col class="title" lines="none" size="12">
				Je suis organisateur·trice
			</ion-col>
			<ion-col size="12">
				<swiper *ngIf="organizers.length >0" [grabCursor]="true" [slidesPerView]="'auto'" [spaceBetween]="10">
					<ng-template *ngFor="let item of organizers" swiperSlide>
						<app-event-my-renderer (click)="editEvent(item)" [edit]="true"
											   [item]="item"></app-event-my-renderer>
					</ng-template>
				</swiper>
				<div *ngIf="organizers.length ==0" class="not-event" lines="none">
					<div class="not-event-text">
						Vous n’avez pas d’événement
						en cours d’organisation pour
						le moment
					</div>
				</div>
			</ion-col>
		</ion-row>
		<ion-row>
			<ion-col class="title" size="12">
				Evénements passés
			</ion-col>
			<ion-col size="12">
				<swiper *ngIf="pasts.length >0" [grabCursor]="true" [slidesPerView]="'auto'" [spaceBetween]="10">
					<ng-template *ngFor="let item of pasts" swiperSlide>
						<app-event-my-renderer (click)="showEvent(item)" [item]="item"></app-event-my-renderer>
					</ng-template>
				</swiper>
				<div *ngIf="pasts.length ===0" class="not-event">
					<div class="not-event-text">
						Vous n’avez pas d’événement
						passé dans votre liste pour
						le moment
					</div>
				</div>
			</ion-col>
		</ion-row>
	</ion-grid>
	<!--ion-button (click)="createEvent()" style="position: fixed;bottom: 80px; right: 20px;--background: var(--cftc-color-6);height: 67px;width: 67px;--border-radius:100%!important;">
	  <span style="position: absolute;left: -2px;top: 12px;">+</span>
	  <ion-icon style="font-size: 25px" name="calendar-clear-outline"></ion-icon>
	</ion-button-->
</ion-content>

