import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";
import {ResponsiveService} from "../../../shared/services/utils/responsive.service";

@Component({
  selector: 'app-cse-confirm',
  templateUrl: './cse-confirm.component.html',
  styleUrls: ['./cse-confirm.component.scss'],
})
export class CseConfirmComponent  implements OnInit {

  constructor(private modalController: ModalController, public responsive: ResponsiveService) { }

  ngOnInit() {}


	dismissModal() {
		this.modalController.dismiss({
			dismissed: true,
		});
	}

}
