<ion-content [class]="responsiveService.isDesktop() ? 'ion-content-all-desktop' : 'ion-content-all-mobile'">

	<ion-icon (click)="dismissModal()" [class]="responsiveService.isMobile()?'close' : 'close-desktop'"
			  name="close-outline"></ion-icon>
	<ion-grid [class]="responsiveService.isDesktop() ? 'grid-desktop' : 'grid-mobile'">
		<ion-row style="padding-bottom: 20px;">
			<ion-col size="12">
				<ion-item class="title" lines="none">
					<ion-img class="title-icon"
							 src="assets/image/menu-12.png"
							 style="width: 26px;margin-left: 0"></ion-img>
					<ion-label style="white-space: normal !important;">{{title}}</ion-label>
				</ion-item>
			</ion-col>
			<ion-col *ngIf="description!==''" [class]="responsiveService.isDesktop() ? 'text' : 'text-mobile'"
					 size="12">
				{{description}}
			</ion-col>
			<ng-container *ngIf="responsiveService.isMobile()">
				<ion-col *ngFor="let item of items; let index = index;" size="12">
					<app-event-new-renderer (click)="openEvent(item)"
											[isList]="true"
											[item]="item"></app-event-new-renderer>

					<div class="separator-mobile"></div>
				</ion-col>
			</ng-container>

			<ng-container *ngIf="responsiveService.isDesktop()">

				<ion-col *ngFor="let item of items; let index = index;" size="6">
					<app-event-new-renderer (click)="openEvent(item)"
											[isList]="true"
											[item]="item"></app-event-new-renderer>

					<div class="separator"></div>

				</ion-col>


			</ng-container>

		</ion-row>
	</ion-grid>

</ion-content>
<ion-button (click)="addEvent()" [class]="responsiveService.isDesktop() ? 'btn-add' : 'btn-add-mobile'"
			class="bd-yellow bg-yellow" type="button">
	<ion-img src="../../assets/image/plus-btn.png" style="margin-left: 5px; margin-right: 16px"></ion-img>
	Créer un événement
</ion-button>

