import {Injectable} from "@angular/core";
import {Platform} from "@ionic/angular";
import {Capacitor} from "@capacitor/core";
import {Geolocation} from "@capacitor/geolocation";
import {HttpService} from "./utils/http.service";
import {BehaviorSubject, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {User} from "../models/user";
import {Event} from "../models/event";

declare var google: any;

@Injectable({
	providedIn: "root"
})
export class GeoService {
	map: any;
	permission: any;
	data: BehaviorSubject<object>;
	markers = [];

	constructor(private platform: Platform, private http: HttpService) {
		this.data = new BehaviorSubject<object>({users: [], events: []});
	}

	async location(): Promise<any> {
		try {
			if (Capacitor.isPluginAvailable("Geolocation")) {
				this.permission = await Geolocation.checkPermissions();
				if (this.permission.location !== "denied") {
					return await Geolocation.getCurrentPosition();
				}
				return null;
			}
		} catch (e) {
			console.error("location error : ", e);
			return null;
		}
	}

	showMap(mapRef, lat, long, onZoomChange) {
		const self = this;
		const location = new google.maps.LatLng(lat, long);
		const options = {
			center: location,
			zoom: 6, // 15
			minZoom: 5,
			maxZoom: 15,
			disableDefaultUI: true,
			styles: [
				{
					featureType: "poi",
					elementType: "labels",
					stylers: [
						{visibility: "off"}
					]
				}
			]
		};
		this.map = new google.maps.Map(mapRef.nativeElement, options);
		this.map.addListener("zoom_changed", zoom => {


			const bounds = self.map.getBounds();
			const ne = bounds.getNorthEast();
			const sw = bounds.getSouthWest();

			const dist = (6371 * Math.acos(Math.sin(ne.lat() / 57.2958) * Math.sin(sw.lat() / 57.2958) +
				(Math.cos(ne.lat() / 57.2958) * Math.cos(sw.lat() / 57.2958) * Math.cos((sw.lng() / 57.2958) - (ne.lng() / 57.2958)))));

			onZoomChange(self.map.getZoom(), ne.lat(), ne.lng(), dist);
		});


	}

	removeMarkers() {
		for (let i = 0; i < this.markers.length; i++) {
			//this.markers[i].removeListener('click');
			this.markers[i].setMap(null);
		}
		this.markers = [];
	}

	addMarkers(markers, onClickMarker) {
		this.removeMarkers();
		for (const marker of markers) {
			const position = new google.maps.LatLng(marker.latitude, marker.longitude);
			const params = {
				position,
				title: marker.title,
				latitude: marker.latitude,
				longitude: marker.longitude,
				item: marker.item,
				type: marker.type,
				me: marker.me,
			};

			if (marker.type === "event") {
				params["icon"] = {
					url: "assets/image/map-" + marker.type + "-" + marker.item.category + ".png",
					labelOrigin: new google.maps.Point(15, -15)
				};
			}
			if (marker.type === "user") {
				params["icon"] = {
					url: marker.me ? "assets/image/map-orange-" + marker.type + ".png" : "assets/image/map-" + marker.type + ".png",
					labelOrigin: new google.maps.Point(15, -15)
				};
			}


			if (!marker.me) {
				params["label"] = {
					className: "map-label",
					color: marker.type === "event" ? "#47B4BB" : "#24318A",
					fontWeight: "bold",
					fontSize: "12px",
					text: marker.title,
					y: "-54"
				};
			}


			const mapMarker = new google.maps.Marker(params);
			this.markers.push(mapMarker);
			mapMarker.setMap(this.map);
			mapMarker.addListener("click", () => {
				if (!mapMarker.me) {
					onClickMarker(mapMarker);
				}

			});
		}
	}

	getsLatLon(distance: number, geoLat: number, geoLon: number, save: boolean = false): Observable<any> {
		return this.http.post("geo/find-lat-lon", {
			distance,
			geoLat,
			geoLon,
			save
		}).pipe(
			map((res: any) => {

				const users = res.users.map(item => {
					return new User(item);
				});

				const events = res.events.map(item => {
					return new Event(item);
				});


				return {users, events};
			})
		);

	}

	private async getLocation() {
		return await this.location();
	}

}
