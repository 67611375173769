import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {HttpService} from "./utils/http.service";
import {PaginationService} from "./pagination";
import {Event} from "../models/event";

@Injectable({
	providedIn: "root",
})
export class EventService extends PaginationService {
	events: BehaviorSubject<Array<Event>>;
	event: BehaviorSubject<Event>;

	categories = {};
	categoriesArray = []

	constructor(private https: HttpService) {
		super();

		this.events = new BehaviorSubject<Array<Event>>([]);
		this.event = new BehaviorSubject<Event>(null);
		this.getCat().subscribe((cats: any) => {
			cats = cats.sort((a: any, b: any) => {
				const textA = a.title.toUpperCase();
				const textB = b.title.toUpperCase();
				return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
			});
			this.categoriesArray = cats;
			this.categories = {};
			for (let cat of cats) {
				this.categories[cat.key] = cat.title;
			}
		});
	}

	gets(
		filters: any[],
		geoLat: number = 0,
		geoLon: number = 0,
		distance: number = 999999
	): Observable<any> {
		return this.https
			.post("events", {
				filters,
				geoLat,
				geoLon,
				distance,
			})
			.pipe(
				map((res: any) => {
					const newArray = res.map((item) => {
						return new Event(item);
					});
					this.events.next(newArray);
					return this.events.getValue();
				})
			);
	}

	getMyEvents(geoLat: number, geoLon: number): Observable<any> {
		return this.https
			.post("my-events", {
				geoLat,
				geoLon,
			})
			.pipe(
				map((res: any) => {
					const interesteds = res.participations.map((item) => {
						return new Event(item);
					});
					const organizers = res.owner.map((item) => {
						return new Event(item);
					});
					const pasts = res.events.map((item) => {
						return new Event(item);
					});
					return {interesteds, organizers, pasts};
				})
			);
	}

	getWidgetEvents(geoLat: number, geoLon: number): Observable<any> {
		return this.https
			.post("widget-events", {
				geoLat,
				geoLon,
			})
			.pipe(
				map((res: any) => {
					const newArray = res.map((item) => {
						return new Event(item);
					});
					this.events.next(newArray);
					return this.events.getValue();
				})
			);
	}

	/**
	 *
	 * @param uuid
	 */
	get(uuid: string): Observable<Event> {
		return this.https.get("event/" + uuid).pipe(
			map((res: any) => {
				const event: Event = new Event(res);
				this.event.next(event);
				return event;
			})
		);
	}

	getCat(): Observable<[]> {
		return this.https.get("event/category").pipe(
			map((res: []) => {
				return res;
			})
		);
	}


	create(
		category: string,
		title: string,
		startAt: string,
		stopAt: string,
		location: string,
		address: string,
		city: string,
		zipCode: string,
		capacity: string,
		description: string,
		picture: string,
		visioUrl: string,
		canMessaging: string,
		isPrivate: string,
		national: string,
		files: any[]
	): Observable<any> {
		return this.https.post("event", {
			category,
			title,
			startAt,
			stopAt,
			location,
			address,
			city,
			zipCode,
			capacity,
			description,
			picture,
			visioUrl,
			canMessaging,
			isPrivate,
			national,
			files
		});
	}

	update(
		uuid: string,
		category: string,
		title: string,
		startAt: string,
		stopAt: string,
		location: string,
		address: string,
		city: string,
		zipCode: string,
		capacity: string,
		description: string,
		picture: string,
		visioUrl: string,
		canMessaging: string,
		isPrivate: string,
		national: string,
		files: any[]
	): Observable<any> {
		return this.https.patch("event/" + uuid, {
			category,
			title,
			startAt,
			stopAt,
			location,
			address,
			city,
			zipCode,
			capacity,
			description,
			picture,
			visioUrl,
			canMessaging,
			isPrivate,
			national,
			files
		});
	}

	delete(uuid: string): Observable<any> {
		return this.https.delete("event/" + uuid);
	}

	updateComment(uuid: string, text: string): Observable<any> {
		return this.https.patch("event/comment/" + uuid, {
			text,
		});
	}

	deleteComment(uuid: string): Observable<any> {
		return this.https.delete("event/comment/" + uuid);
	}

	comment(uuid: string, text: string): Observable<any> {
		return this.https.post("event/" + uuid + "/comment", {
			text,
		});
	}

	signal(uuid: string, object: object): Observable<any> {
		return this.https.post("event/" + uuid + "/signal", object);
	}

	join(uuid: string): Observable<any> {
		return this.https.post("event/" + uuid + "/join", {});
	}


}
