import {Component, OnInit} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {ModalService} from "../../../shared/services/utils/modal.service";
import {ForumSubject} from "../../../shared/models/forum-subject";
import {ResponsiveService} from "../../../shared/services/utils/responsive.service";

@Component({
	selector: "app-modal-thematic-forum",
	templateUrl: "./thematic-forum.component.html",
	styleUrls: ["./thematic-forum.component.scss"],
})
export class ThematicForumModalComponent implements OnInit {
	items: ForumSubject[] = [];
	title: String = "Forums";
	description: String = "";

	constructor(private modalController: ModalController, private modalService: ModalService, public responsiveService: ResponsiveService) {
	}

	ngOnInit(): void {

		this.items.sort((a, b) => {
			const dateA = new Date(a.createdAt);
			const dateB = new Date(b.createdAt);
			return dateB.getTime() - dateA.getTime();

		});

	}

	openForum(forum: ForumSubject) {
		this.modalService.openForum(forum, forum.topic, 'Forum', () => {

		});
	}


	dismissModal() {
		this.modalController.dismiss({
			dismissed: true,
		});
	}
}
