<!-- MODE LISTE MOBILE -->
<ion-card class="ion-card-list">
	<ion-card>
		<ion-grid style="padding-bottom: 0 !important;">
			<ion-row class="row">
				<ion-col size="8" style="padding-bottom: 0 !important;">
					<ion-item lines="none">
						<div>
							<ion-label class="name">
								{{ item.author !== '' ? item.author : 'Confédération CFTC' }}
							</ion-label>
							<ion-label class="titleList">
								{{item.title}}
							</ion-label>
							<p [class]="isNotHome ? 'date-not-home' : 'date'">
								{{item.dateDay}} {{item.dateMonth}} {{item.dateYear}}
							</p>
						</div>
					</ion-item>
				</ion-col>
				<ion-col size="4" style="padding-bottom: 0 !important;">
					<ion-card
						class="imgList"
						style="background-image: url('./assets/image/ressourcerie-x2.png')">
						<img [src]="item.visuel?item.visuel:'assets/image/logo-banner.png'" class="play">
					</ion-card>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ion-card>
</ion-card>
