export class Interstitiel {
	public image = '';
	public btnUrl = '';
	public btnLabel = '';
	public endAt = new Date().toString();
	public label = '';
	public logo = '';
	public title = '';

	constructor(data = null) {
		if (data) {
			this.image = data.image;
			this.btnUrl = data.btn_url;
			this.btnLabel = data.btn_label;
			this.endAt = data.end_at;
			this.label = data.label;
			this.logo = data.logo;
			this.title = data.title;
		}
	}
}
