import {Component, OnInit} from '@angular/core';
import {ResponsiveService} from '../../../shared/services/utils/responsive.service';
import {EventService} from '../../../shared/services/event.service';
import {NotificationService} from 'src/app/shared/services/alert.service';
import {Router} from '@angular/router';
import {ModalService} from 'src/app/shared/services/utils/modal.service';
import {ModalController} from '@ionic/angular';
import {ForumService} from 'src/app/shared/services/forum.service';
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {UserService} from "../../../shared/services/user.service";
import {ToastService} from "../../../shared/services/utils/toast.service";

@Component({
	selector: 'app-modal-notification-start',
	templateUrl: './notification-start.component.html',
	styleUrls: ['./notification-start.component.scss'],
})
export class NotificationStartModalComponent implements OnInit {

	notifForm: UntypedFormGroup;

	constructor(
		public modalService: ModalService,
		public modalController: ModalController,
		public responsive: ResponsiveService,
		public eventService: EventService,
		public forumService: ForumService,
		private formBuilder: UntypedFormBuilder,
		private userService: UserService,
		private toastService: ToastService,
		private router: Router,
		private notificationService: NotificationService,
	) {

	}


	ngOnInit(): void {
		this.init();
	}

	ionViewWillEnter() {
		this.init();
	}

	init() {
		this.notifForm = this.formBuilder.group({
			forum: [true],
			offer: [true],
			message: [true],
			event: [true],
			notificationEnabled: [true]
		});
	}

	changeAll(event: any) {
		const value = !event.target.checked
		this.notifForm.get('forum').setValue(value);
		this.notifForm.get('offer').setValue(value);
		this.notifForm.get('message').setValue(value);
		this.notifForm.get('event').setValue(value);
	}

	changeNotification() {
		const value = this.notifForm.get('notificationEnabled').value;
		this.userService.changeNotification(value).subscribe(
			{
				next: value => {
					const notificationOptions = {
						forum: this.notifForm.get('forum').value,
						offer: this.notifForm.get('offer').value,
						message: this.notifForm.get('message').value,
						event: this.notifForm.get('event').value,
					};
					this.userService.changeNotificationOptions(notificationOptions).subscribe(
						{
							next: data => {
								this.dismissModal();
							},
							error: err => {
								this.toastService.show(err);
							}
						});
				},
				error: err => {
					this.toastService.show(err);
				}
			});
	}

	dismissModal() {
		this.modalController.dismiss({
			dismissed: true,
		});
	}
}
