import {ClickCursorDirective} from "../directive/click.cursor.directive";
import {NgModule} from "@angular/core";

@NgModule({
	declarations: [ClickCursorDirective],
	exports: [ClickCursorDirective]
})

export class SharedModule {
}
