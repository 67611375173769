<ion-card [style.margin-top]="isFirst && responsive.isMobile()?0:12.5" [class.desktop]="responsive.isDesktop()">
    <ion-item lines="none">
        <ion-avatar [class.ion-avatar-mobile]="responsive.isMobile()" slot="start">
            <img src="{{item.picture}}">
            <div [class.connected]="item.connected" class="connected"></div>
        </ion-avatar>
        <ion-label class="ion-text-wrap">
            <div class="title"> {{item.syndicat}}</div>
            <div class="name">{{item.lastName}} {{item.firstName}}</div>
            <!--    <div *ngIf="item.level===0" class="game">
                  <div class="game-point bronze"></div>
                  Militant bronze
              </div>
              <div *ngIf="item.level===1" class="game">
                  <div class="game-point argent"></div>
                  Militant argent
              </div>
              <div *ngIf="item.level===2" class="game">
                  <div class="game-point or"></div>
                  Militant or
              </div>-->
            <div class="info" *ngIf="item.employer!=='-' && item.city!=='-'">
                <ion-icon name="location-outline"></ion-icon>
                <span *ngIf="item.city!=='-'">{{item.city}}</span><span *ngIf="item.employer"> - </span><span *ngIf="item.employer!=='-'">{{item.employer}}</span>
            </div>
            <div *ngIf="userService.isLocation && showDistance" class="info">
                Distance: {{item.distance| number:'1.1-2'}} km
            </div>
        </ion-label>
        <div (click)="openChat($event)" class="chat" slot="end" *ngIf="!item.blocked">
            <ion-icon name="chatbox-outline"></ion-icon>
        </div>
        <div class="chat blocked" slot="end" *ngIf="item.blocked">
            <ion-icon name="ban-outline"></ion-icon>
        </div>

    </ion-item>
</ion-card>
