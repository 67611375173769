import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ResponsiveService} from '../../../shared/services/utils/responsive.service';
import {EventService} from '../../../shared/services/utils/event.service';
import {HttpService} from 'src/app/shared/services/utils/http.service';
import {ToastService} from 'src/app/shared/services/utils/toast.service';
import {ModalService} from 'src/app/shared/services/utils/modal.service';
import {Router} from '@angular/router';
import {User} from 'src/app/shared/models/user';
import {Article} from 'src/app/shared/models/article';
import {Video} from 'src/app/shared/models/video';
import {ForumSubject} from "../../../shared/models/forum-subject";

@Component({
	selector: 'app-modal-find',
	templateUrl: './find.component.html',
	styleUrls: ['./find.component.scss'],
})
export class FindModalComponent implements OnInit {
	data = {directory: [], videos: [], articles: [], offers: [], forums: []};
	findText = '';
	showArticles = true;
	showVideos = true;
	showAdherents = true;
	showOffers = true;
	showForums = true;
	noResult = false;

	constructor(
		public modalController: ModalController,
		public responsive: ResponsiveService,
		public eventService: EventService,
		private httpService: HttpService,
		private toastService: ToastService,
		private modalService: ModalService,
		private router: Router,
	) {

	}


	ngOnInit(): void {
	}

	ionViewWillEnter() {
		this.init();
	}

	init() {

	}

	find() {
		try {
			this.data = {directory: [], videos: [], articles: [], offers: [], forums: []};
			this.httpService.post('search', {search: this.findText}).subscribe(value => {

				this.data = {directory: [], videos: [], articles: [], offers: [], forums: []};

				this.data.directory = value.directory.map(item => {
					return new User(item);
				});
				this.data.videos = value.videos.map(item => {
					return new Video(item);
				});
				this.data.articles = value.articles.map(item => {
					return new Article(item);
				});

				this.data.offers = value.offers.map(item => {
					return new ForumSubject(item);
				});
				this.data.forums = value.forums.map(item => {
					return new ForumSubject(item);
				});

				this.noResult = this.data.directory.length === 0 && this.data.videos.length === 0 && this.data.articles.length === 0 && this.data.offers.length === 0 && this.data.forums.length === 0;

			}, error => {
				if (error)
					this.toastService.show(error);
			});
		} catch (e: any) {
			if (e.message) {
				console.error(e.message);
				this.toastService.show(e.message);
			}

		}

	}

	changeShowArticles() {
		this.showArticles = !this.showArticles;
	}

	changeShowVideos() {
		this.showVideos = !this.showVideos;
	}

	changeShowAdherents() {
		this.showAdherents = !this.showAdherents;
	}

	changeShowForums() {
		this.showForums = !this.showForums;
	}

	changeShowOffers() {
		this.showOffers = !this.showOffers;
	}

	openProfile(user) {
		this.modalService.openProfile(user, 'Recherche', value => {
		});
	}

	openArticle(article) {
		this.modalService.openArticle(article, value => {
		});
	}

	openVideo(video) {
		this.modalService.openVideo(video, value => {
		});
	}

	openForum(forum) {


		this.router.navigate(['/community/forum/' + forum.topic.uuid + '/' + forum.uuid]);
		this.dismissModal();
	}

	openOffer(offer) {
		this.router.navigate(['/community/offers/' + offer.uuid]);
		this.dismissModal();
	}

	openOffers() {
		this.router.navigate(['/community/offer']);
		this.dismissModal();
	}

	openForums() {
		this.router.navigate(['/community/forum']);
		this.dismissModal();
	}

	openAllUser() {
		this.router.navigate(['/my-reseau/directory']);
		this.dismissModal();
	}

	openAllArticle() {
		this.router.navigate(['/actuality/cftc']);
		this.dismissModal();
	}

	openAllVideo() {
		this.router.navigate(['/actuality/informer']);
		this.dismissModal();
	}

	dismissModal() {
		this.modalController.dismiss({
			dismissed: true,
		});
	}
}
