<ion-card [style.height.px]="state===0?350:232">
    <ion-card-header>
        Pourquoi souhaitez-vous signaler ce contenu ?
    </ion-card-header>
    <ion-card-content>
        <ion-list *ngIf="state===0" style="margin-bottom: 15px;">
            <ion-item *ngFor="let reason of reasons;let index=index" class="reasons" lines="none">
                <ion-checkbox [(ngModel)]="reason.select" mode="ios"></ion-checkbox>
                <ion-label>{{reason.text}}</ion-label>
            </ion-item>
        </ion-list>
        <div style="width: 100%;border-bottom: solid 1px var(--cftc-color-13);    margin-bottom: 90px;" *ngIf="state===1">
            <input placeholder="Précisions" [(ngModel)]="text">
        </div>

        <ion-grid>
            <ion-row>
                <ion-col size="6" *ngIf="state===0" class="button-left" (click)="dismissModal(null)">
                    Annuler
                </ion-col>
                <ion-col size="6" *ngIf="state===1" class="button-left" (click)="state=0">
                    Retour
                </ion-col>
                <ion-col size="6" *ngIf="state===0" class="button-right" (click)="state=1">
                    Suivant
                </ion-col>
                <ion-col size="6" *ngIf="state===1" class="button-right" (click)="send()">
                    Envoyer
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-card-content>
</ion-card>
