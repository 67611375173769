import {UserService} from 'src/app/shared/services/user.service';
import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {IonTextarea, ModalController} from '@ionic/angular';
import {ResponsiveService} from '../../../shared/services/utils/responsive.service';
import {Event} from '../../../shared/models/event';
import {EventService} from '../../../shared/services/event.service';
import {Camera, CameraResultType, CameraSource} from '@capacitor/camera';
import {Router} from '@angular/router';
import {ToastService} from '../../../shared/services/utils/toast.service';
import {ModalService} from '../../../shared/services/utils/modal.service';
import {User} from 'src/app/shared/models/user';
import {Capacitor} from "@capacitor/core";
import {File} from "../../../shared/models/file";
import {CommonHelper} from "../../../shared/helpers/common.helper";

@Component({
	selector: 'app-modal-event-create',
	templateUrl: './event-create.component.html',
	styleUrls: ['./event-create.component.scss'],
})
export class EventCreateModalComponent implements OnInit, AfterViewInit {
	event: Event = new Event();
	title: string;
	dateEnd = false;
	isVisio = false;
	isAllDay = false;
	user: User;
	error = '';
	cg = false;
	isIos = Capacitor.getPlatform() === 'ios';
	categories = []
	category = '';
	@ViewChild('myTextArea') private textArea: IonTextarea;

	constructor(
		private router: Router,
		private toastService: ToastService,
		private modalService: ModalService,
		public modalController: ModalController,
		public responsive: ResponsiveService,
		private eventService: EventService,
		private userService: UserService,
		private commonHelper: CommonHelper,
	) {
	}

	dayAlreadyGone = (dateString: string) => {
		const date = new Date(dateString);
		const today = new Date();
		if (date > today || date.toDateString() === today.toDateString()) {
			return true;
		}
	};

	dayAlreadyGoneFinish = (dateString: string) => {
		const date = new Date(dateString);
		const today = new Date();
		if (date > today || date.toDateString() === today.toDateString()) {
			return true;
		}
	};

	ngOnInit(): void {
		this.user = this.userService.getUserAuthValue();
		if (this.category !== '') {
			this.event.category = this.category;
		}
		this.categories = this.eventService.categoriesArray;

		if (this.event.uuid !== '') {
			this.eventService.get(this.event.uuid).subscribe((event: Event) => {
				this.event = event;
				this.isVisio = false;
				if (this.event.visioUrl !== '') {
					this.isVisio = true;
				}
				if (this.event.stopAt !== '' && this.event.stopAt !== null) {
					this.dateEnd = true;
				}
				// this.textArea.autoGrow = true;
			});
		}
	}

	ngAfterViewInit() {
		/* this.textArea.ionChange.subscribe(() => {
		   this.textArea.autoGrow = true;
		 });*/
	}

	dismissModal(event = null) {
		this.modalController.dismiss({
			dismissed: true,
			event,
		});
	}

	async picture() {
		const image = await Camera.getPhoto({
			quality: 75,
			width: 600,
			allowEditing: false,
			resultType: CameraResultType.Base64,
			source: CameraSource.Photos,
			promptLabelHeader: 'Photo',
			promptLabelCancel: 'Annuler',
			promptLabelPhoto: 'Photo',
			promptLabelPicture: 'Gallerie',
		});
		this.event.picture =
			'data:image/' + image.format + ';base64,' + image.base64String;
	}

	removeImage() {
		this.event.picture = '';
	}

	categorieChange(evt) {
	}

	addDate() {
		this.event.stopAt = this.event.startAt;
		this.dateEnd = true;
	}

	async save() {
		await this.modalService.openLoading();
		if (this.isAllDay) {
			this.event.stopAt = '';
		}
		this.eventService
			.create(
				this.event.category,
				this.event.title,
				this.event.startAt,
				this.event.stopAt,
				this.event.location,
				this.event.address,
				this.event.city,
				this.event.zipCode,
				this.event.capacity,
				this.event.description,
				this.event.picture,
				this.event.visioUrl,
				this.event.canMessaging,
				this.event.isPrivate,
				this.event.national,
				this.event.files
			)
			.subscribe(
				(value) => {
					this.modalService.closeLoading();
					this.dismissModal(new Event(value));
				},
				(error) => {
					this.modalService.closeLoading();
					this.toastService.show(error);
				}
			);
	}

	async edit() {
		await this.modalService.openLoading();
		if (this.isAllDay) {
			this.event.stopAt = '';
		}
		this.eventService
			.update(
				this.event.uuid,
				this.event.category,
				this.event.title,
				this.event.startAt,
				this.event.stopAt,
				this.event.location,
				this.event.address,
				this.event.city,
				this.event.zipCode,
				this.event.capacity,
				this.event.description,
				this.event.picture,
				this.event.visioUrl,
				this.event.canMessaging,
				this.event.isPrivate,
				this.event.national,
				this.event.files
			)
			.subscribe(
				(value) => {
					this.modalService.closeLoading();
					this.dismissModal(new Event(value));
				},
				(error) => {
					this.modalService.closeLoading();
					this.toastService.show(error);
				}
			);
	}

	async chooseFile(event) {
		const file = event.target.files[0];
		const newFile = new File();
		newFile.name = file.name;
		newFile.filename = await this.commonHelper.readFileAsync(file);
		this.event.files.push(newFile);
	}

	removeFile(index) {
		this.event.files.splice(index, 1);
	}


	changeStartAtDate(event) {
		if (this.event.startAt === '') {
			this.event.startAt = event.detail.value;
		} else {
			event.detail.value = event.detail.value.split('T')[0] + 'T' + this.event.startAt.split('T')[1];
			this.dayAlreadyGoneFinish = (dateString: string) => {
				const date = new Date(dateString);
				const today = new Date(this.event.startAt);
				if (date > today || date.toDateString() === today.toDateString()) {
					return true;
				}
			}
			this.event.stopAt = event.detail.value;
		}

	}

	changeStartAtTime(event) {
		if (this.event.startAt === '') {
			this.event.startAt = event.detail.value;
		} else {
			event.detail.value = this.event.startAt.split('T')[0] + 'T' + event.detail.value.split('T')[1];
		}
	}

	changeStopAtDate(event) {
		if (this.event.stopAt === '') {
			this.event.stopAt = event.detail.value;
		} else {
			event.detail.value = event.detail.value.split('T')[0] + 'T' + this.event.stopAt.split('T')[1];
		}
	}

	changeStopAtTime(event) {

		if (this.event.stopAt === '') {
			this.event.stopAt = event.detail.value;
		} else {
			event.detail.value = this.event.stopAt.split('T')[0] + 'T' + event.detail.value.split('T')[1];
		}

	}
}
