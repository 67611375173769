import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ResponsiveService} from '../../../shared/services/utils/responsive.service';
import {EventService} from '../../../shared/services/event.service';
import {Router} from '@angular/router';
import {AlertHelper} from '../../../shared/helpers/alert.helper';
import {UserService} from '../../../shared/services/user.service';
import {ToastService} from '../../../shared/services/utils/toast.service';
import {MessengerService} from '../../../shared/services/messenger.service';
import {ModalService} from '../../../shared/services/utils/modal.service';
import {Qrcode} from '../../../shared/models/qrcode';
import {Browser} from '@capacitor/browser';
import {UrlService} from '../../../shared/services/url.service';
import {User} from '../../../shared/models/user';
import {Capacitor} from "@capacitor/core";

@Component({
	selector: 'app-modal-qrcode',
	templateUrl: './qrcode.component.html',
	styleUrls: ['./qrcode.component.scss'],
})
export class QrcodeModalComponent implements OnInit {
	fileMode = true;
	infos: Qrcode = new Qrcode();
	titles = {
		Atelier: 'Jeudi matin :',
		Dejeuner: 'Aux repas suivants, auxquels vous avez été inscrit par votre structure :',
		Soiree: 'Mercredi soir : ',
		Représentation: 'Mardi à 19h30 :',
		Célébration: 'Mardi à 18h30 :',
	};
	user: User;
	loading = false;
	colors = {
		'#F7A82B': '#24318A',
		'#EC6277': '#FFFFFF',
		'#3A2558': '#FFFFFF',
		'#2A6AB2': '#FFFFFF',
		'#47C1EF': '#24318A',
		'#5BBB9E': '#FFFFFF',
		'#BFBFBF': '#24318A',
	}

	constructor(
		private router: Router, private toastService: ToastService,
		private alertHelper: AlertHelper, public modalController: ModalController,
		public responsive: ResponsiveService, public eventService: EventService,
		private userService: UserService, private messengerService: MessengerService,
		private modalService: ModalService, private urlService: UrlService) {

	}


	ngOnInit(): void {
		this.user = this.userService.getUserAuthValue();
		this.loading = true;
		this.userService.getQRCode().subscribe((data: Qrcode) => {
			console.log(data);
			this.infos = new Qrcode(data);
			this.loading = false;
		});
	}

	dismissModal() {
		this.modalController.dismiss({
			dismissed: true,
		});
	}

	async openProgramme() {
		const url = "https://cftc-congres2023.fr/inscriptions/?inaric=" + btoa(this.user.inaric);
		await Browser.open({url: url});
	}

	async openFile() {
		if (Capacitor.getPlatform() === 'android') {
			await this.modalService.openPdf(this.infos.attestation, 'Votre fichier pdf', "attestation.pdf", () => {
			});
		} else {
			await Browser.open({url: this.infos.attestation});
		}
	}
}
