import {ModalController, Platform} from "@ionic/angular";
import {Component, Input, OnInit} from "@angular/core";
import {ResponsiveService} from "../../../../shared/services/utils/responsive.service";
import {Event} from "../../../../shared/models/event";
import {EventService} from "../../../../shared/services/event.service";

@Component({
	selector: "app-event-my-renderer",
	templateUrl: "./event-my.component.html",
	styleUrls: ["./event-my.component.scss"],
})
export class EventMyRendererComponent implements OnInit {
	@Input() item: Event;
	@Input() edit = false;

	constructor(public platform: Platform, public responsive: ResponsiveService, public eventService: EventService,
				public modalController: ModalController) {
	}

	ngOnInit(): void {
	}

}
