import {Platform} from "@ionic/angular";
import {Component, Input, OnInit} from "@angular/core";
import {ResponsiveService} from "../../../../shared/services/utils/responsive.service";
import {User} from "../../../../shared/models/user";
import {Router} from "@angular/router";
import {MessengerService} from "../../../../shared/services/messenger.service";
import {ToastService} from "../../../../shared/services/utils/toast.service";
import {UserService} from "../../../../shared/services/user.service";

@Component({
	selector: "app-directory",
	templateUrl: "./directory.component.html",
	styleUrls: ["./directory.component.css"],
})
export class DirectoryComponent implements OnInit {
	@Input() item: User;
	@Input() isFirst = false;
	@Input() showDistance = false;

	constructor(
		public platform: Platform, public responsive: ResponsiveService,
		private toastService: ToastService, private messengerService: MessengerService, public userService: UserService,
		private router: Router) {
	}

	ngOnInit(): void {
	}

	openChat(evt) {
		evt.stopPropagation();
		this.messengerService.create("", [this.item]).subscribe(value => {
			this.router.navigate([(this.responsive.isMobile() ? "/chat/" : "/chat-d/") + value.uuid]);
		}, error => {
			this.toastService.show(error);
		});
	}

	unlock() {
		this.userService.blacklist(this.item.inaric).subscribe(value => {
			this.item.blocked = !this.item.blocked;
		}, error => {
			this.toastService.show(error);
		});
	}
}
