import {User} from "./user";
import {environment} from "../../../environments/environment";

export class Message {
	public id: number = 0;
	public uuid: string = "";
	public text: string = "";
	public owner: User = new User();
	public createdAt = new Date().toString();
	public updatedAt = new Date().toString();
	public active = true;

	constructor(data = null) {
		if (data) {
			this.id = data.id;
			this.uuid = data.uuid;
			this.text = data.text;
			this.owner = new User(data.owner);
			this.createdAt = data.createdAt;
			this.updatedAt = data.updatedAt;

		}
	}
}
