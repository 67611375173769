import {User} from "./user";
import {ForumSubject} from "./forum-subject";

export class ForumTopic {
	public id = 0;
	public uuid = "";
	public title = "";
	public description = "";
	public subjects: ForumSubject[] = [];
	public owner: User = new User();
	public createdAt = new Date();
	public updatedAt = new Date();
	public select = false;

	constructor(data = null) {

		if (data) {
			this.id = data.id;
			this.uuid = data.uuid;
			this.title = data.title;
			this.description = data.description;
			if (data.subjects !== undefined) {
				this.subjects = data.subjects.map(item => {
					return new ForumSubject(item);
				});
			}
			if (data.subjects !== undefined) {
				this.owner = new User(data.owner);
			}
			this.owner = new User(data.owner);
			this.createdAt = data.createdAt;
			this.updatedAt = data.updatedAt;
		}
	}


}
