<ion-card>
    <ion-card-content>
        <ion-grid>
            <ion-row>
                <ion-col size="12" style="text-align: center;margin-bottom: 5px">
                    <img src="assets/image/analytics.png" style="width: 60px"/>
                </ion-col>
                <ion-col size="12" style="" class="title">
                    Gestion des cookies
                </ion-col>
                <ion-col size="12" style="" class="text">
                    Dans le but d'améliorer l'usage et l'ergonomie de votre application, nous mettons en place des cookies de mesure d'audience.
                    Avant de poursuivre la navigation, vous êtes invité à :<br>
                    - Donner votre consentement au dépôt des cookies sur votre appareil,<br>
                    - Ou bien refuser leur dépôt<br>
                    Après avoir choisi, vous pourrez modifier vos préférences cookies à tout moment via la rubrique<br>
                    <a (click)="openModalSetting()">Gestion de cookies de l’Application CFTC.</a><br>
                    Pour plus d’information, consultez notre <a (click)="openModalConfidenciality()">Politique de cookies</a>.<br>
                </ion-col>
                <ion-col size="12">
                    <ion-button class="bd-yellow bg-yellow btn-map" type="button" style="width: 100%" (click)="accepte()">
                        Accepter
                    </ion-button>
                </ion-col>
                <ion-col size="12">
                    <ion-button class="bd-transparent bg-transparent text-yellow btn-map" type="button" style="width: 100%" (click)="refuse()">
                        Refuser
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-card-content>
</ion-card>
