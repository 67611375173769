<ion-card>
    <ion-row>
        <ion-col size="2">
            <ion-img class="img" [src]="'assets/image/play.png'"></ion-img>
        </ion-col>
        <ion-col size="10">
            <ion-row>
                <ion-col class="date" size="12">
                    {{video.date}}
                </ion-col>
                <ion-col class="title" size="12">
                    {{video.title}}
                </ion-col>
            </ion-row>
        </ion-col>
    </ion-row>

</ion-card>
