<ion-item (click)="dismissModal(isDeleted)" lines="none" style="--background: white!important;">
    <ion-icon class="back-icon" name="close-outline" slot="start"></ion-icon>
    <ion-label class="back">{{title}}</ion-label>
</ion-item>
<ion-content style="--background: white;">
    <ion-grid style="padding: 15px;">
        <ion-row *ngIf="state===0">
            <ion-col class="title" size="12" style="margin-bottom: 20px">
                Valider ma suppression de compte
            </ion-col>
            <ion-col class="sub-title" size="12">
                Mes avantages CFTC l'App
            </ion-col>
            <ion-col class="text" size="12">
                <ion-label class="point">•</ion-label>
                Se retrouver et partager : Un forum, une messagerie, des petites annonces, des événements pour échanger
                avec la communauté CFTC
            </ion-col>
            <ion-col class="text" size="12">
                <ion-label class="point">•</ion-label>
                Militer : La Ressourcerie pour trouver, commander, personnaliser et même partager vos outils CFTC
            </ion-col>
            <ion-col class="text" size="12">
                <ion-label class="point">•</ion-label>
                Profiter : Les bons plans pour profiter de réductions sur vos marques préférées
            </ion-col>
            <ion-col class="text" size="12">
                <ion-label class="point">•</ion-label>
                Se former : Le portail formation pour se former à ses actions de militant grâce aux parcours en ligne
            </ion-col>
            <ion-col class="text" size="12">
                <ion-label class="point">•</ion-label>
                S’informer : Tous les articles de CFTC.fr et du Décodeur pour ne rien rater de l’actualité
            </ion-col>
            <ion-col class="text-bold " size="12" style="margin-bottom: 20px">
                En supprimant mon compte, je n'aurais plus accès à ces avantages d'adhérent CFTC.
            </ion-col>
            <ion-col class="sub-title" size="12">
                QUELLES SONT MES MOTIVATIONS ?
            </ion-col>
            <ion-col class="text" size="12">
                Pouvez-vous nous partager vos motivations à supprimer votre compte ? Si vous vous êtes confronté à un
                problème technique, faites-le nous savoir ! Nous le corrigerons rapidement.
            </ion-col>
            <ion-col size="12" style="margin-bottom: 20px">
                <ion-textarea [(ngModel)]="description" auto-grow="true" maxlength="30"
                              placeholder="Votre explication est facultative"
                              rows="5"></ion-textarea>
            </ion-col>
            <ion-col class="sub-title" size="12">
                SUPPRIMER MON COMPTE
            </ion-col>
            <ion-col class="text" size="12">
                Pour confirmer votre suppression de compte, merci d’écrire “SUPPRIMER” en capitales.
            </ion-col>
            <ion-col size="12" style="margin-bottom: 20px">
                <ion-input [(ngModel)]="deleteText" placeholder="« SUPPRIMER »"></ion-input>
            </ion-col>
            <ion-col class="ion-text-center" size="12">
                <ion-button (click)="deleted()" [disabled]="deleteText!=='SUPPRIMER'" class="bg-red"
                            style="width: 100%">
                    Supprimer
                    mon compte
                </ion-button>
            </ion-col>
            <ion-col class="text-opatity " size="12">
                Vous allez perdre de manière irréversible toutes vos données, vos messages, etc. Il n’y a pas de retour
                en arrière possible.
            </ion-col>
        </ion-row>
        <ion-row *ngIf="state===1">
            <ion-col class="title" size="12" style="margin-bottom: 20px">
                Votre compte a été supprimé
            </ion-col>
            <ion-col class="text" size="12">
                Votre compte CFTC l'App a bien été supprimé. Désormais vous ne pouvez plus vous connecter avec vos
                identifiants et les utilisateurs de CFTC l'App ne peuvent plus vous trouver. Il est cependant encore
                possible de réactiver votre compte si vous le souhaitez. Vous avez pour cela 30 jours. Une fois les 30
                jours passés, votre compte ainsi que son contenu seront définitivement supprimés.
            </ion-col>
            <ion-col class=" ion-text-center" size="12" style="height: calc(100vh - 390px);">

                <img alt="" class="image" src="assets/image/delete-account.png"/>

            </ion-col>
            <ion-col class="ion-text-center" size="12">
                <ion-button (click)="activeAccount()" class="bg-yellow" style="width: 100%">
                    Réactiver mon compte
                </ion-button>
            </ion-col>
            <ion-col (click)=" openHome()" class="ion-text-center home" size="12">
                Retourner à l’accueil
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
