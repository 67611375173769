import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { ResponsiveService } from "../../../shared/services/utils/responsive.service";
import { EventService } from "../../../shared/services/event.service";
import { Router } from "@angular/router";
import { AlertHelper } from "../../../shared/helpers/alert.helper";
import { UserService } from "../../../shared/services/user.service";
import { ToastService } from "../../../shared/services/utils/toast.service";
import { MessengerService } from "../../../shared/services/messenger.service";
import { ModalService } from "../../../shared/services/utils/modal.service";
import { Article } from "../../../shared/models/article";
import { Share } from "@capacitor/share";
import { Video } from "src/app/shared/models/video";

@Component({
	selector: "app-modal-informer",
	templateUrl: "./video.component.html",
	styleUrls: ["./video.component.scss"],
})
export class VideoModalComponent implements OnInit {
	video: Video = new Video();
	autoplay: boolean = false;

	constructor(
		private router: Router,
		private toastService: ToastService,
		private alertHelper: AlertHelper,
		public modalController: ModalController,
		public responsive: ResponsiveService,
		public eventService: EventService,
		private userService: UserService,
		private messengerService: MessengerService,
		private modalService: ModalService
	) {}

	ngOnInit(): void {
		if (this.autoplay) {
			this.video.youtubeUrl += "?autoplay=1";
		}
	}

	dismissModal() {
		this.modalController.dismiss({
			dismissed: true,
		});
	}
}
