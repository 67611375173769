import {User} from "./user";
import {ForumPost} from "./forum-post";
import {ForumTopic} from "./forum-topic";
import {File} from "./file";

export class ForumSubject {
	public id = 0;
	public uuid = "";
	public title = "";
	public description = "";
	public posts: ForumPost[] = [];
	public owner: User = new User();
	public topic: ForumTopic = new ForumTopic();
	public createdAt = new Date().toString();
	public updatedAt = new Date().toString();
	public type = "";
	public picture = "";
	public files: File[] = [];
	public select = false;
	public archived = false;

	constructor(data = null) {

		if (data) {
			this.id = data.id;
			this.uuid = data.uuid;
			this.title = data.title;
			this.description = data.description;
			this.archived = data.archived;
			if (data.posts !== undefined) {
				this.posts = data.posts.map(item => {
					return new ForumPost(item);
				});
				this.posts.sort((a, b) => {
					// @ts-ignore
					return new Date(b.createdAt) - new Date(a.createdAt);
				});

			}
			if (data.files !== undefined) {
				this.files = data.files.map(item => {
					return new File(item);
				});
			}
			if (data.topic !== undefined) {
				this.topic = new ForumTopic(data.topic);
			}
			if (data.owner !== undefined) {
				this.owner = new User(data.owner);
			}
			this.createdAt = data.createdAt;
			this.updatedAt = data.updatedAt;
			this.type = data.type;
			this.picture = data.picture;
		}
	}


}
