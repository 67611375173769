import {AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn} from "@angular/forms";

export interface ValidationResult {
	[key: string]: boolean;
}

export class PasswordValidator {

	public static strong(control: UntypedFormControl): ValidationResult {
		const hasNumber = /\d/.test(control.value);
		const hasUpper = /[A-Z]/.test(control.value);
		const hasLower = /[a-z]/.test(control.value);
		const valid = hasNumber && hasUpper && hasLower;
		if (!valid) {
			// return what´s not valid
			return {strong: true};
		}
		return null;
	}

	public static checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
		const pass = group.get("password").value;
		const confirmPass = group.get("confirmPassword").value;
		return pass === confirmPass ? null : {notSame: true};
	};
}
