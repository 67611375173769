<ion-header class="ion-no-border" translucent>
    <ion-toolbar>
        <ion-title class="ion-text-center title">Rechercher un événement</ion-title>
        <ion-buttons slot="start">
            <ion-button (click)="dismissModal()" class="button-close">
                <ion-icon name="close-outline" style="font-size: 30px;color: var(--cftc-color-1);"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content fullscreen>
    <ion-grid style="padding: 0;">
        <ion-row>
            <ion-col [size]="12" class="sub-title">
                Sélectionnez la ou les catégorie(s)
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col [size]="12" style="padding-top:0; ">
                <ion-list>
                    <ion-item *ngFor="let category of categories;let index=index" class="itemLine" lines="none">
                        <ion-label class="modal-text">{{category.name}}</ion-label>
                        <ion-checkbox (click)="selectfilter($event,index)" [(ngModel)]="category.select"
                                      mode="ios"></ion-checkbox>
                    </ion-item>
                </ion-list>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
<ion-footer translucent>
    <ion-toolbar>
        <ion-buttons>
            <ion-button (click)="cancel()"
                        class="button-footer bd-green bg-transparent text-green"
                        style="width: 50%;    margin-right: 5px!important;">
                Annuler
            </ion-button>
            <ion-button (click)="close()"
                        class="bd-yellow  bg-yellow button-footer"
                        style="width: 50%;color: white!important; --color: white!important;     margin-left: 5px!important;">
                Terminer
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
