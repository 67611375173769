<ion-card>
    <ion-row>
        <ion-col class="date" size="12">
            {{article.date}} | {{article.category}}
        </ion-col>
        <ion-col class="title" size="12">
            {{article.title}}
        </ion-col>
    </ion-row>
</ion-card>
