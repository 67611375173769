import {AfterViewInit, Component, OnInit} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {Router} from "@angular/router";
import {ToastService} from "src/app/shared/services/utils/toast.service";
import {CommonHelper} from "src/app/shared/helpers/common.helper";
import {ForumService} from "src/app/shared/services/forum.service";
import {ModalService} from "src/app/shared/services/utils/modal.service";
import {EventService} from "src/app/shared/services/event.service";

@Component({
	selector: "app-modal-edit-comment",
	templateUrl: "./edit-comment.component.html",
	styleUrls: ["./edit-comment.component.scss"],
})
export class EditCommentModalComponent implements OnInit, AfterViewInit {
	comment: any;
	type = "";

	constructor(
		private router: Router, private toastService: ToastService, private commonHelper: CommonHelper,
		public modalController: ModalController, private forumService: ForumService, private modalService: ModalService, private eventService: EventService) {

	}


	ngOnInit(): void {
	}

	ngAfterViewInit() {
	}

	dismissModal(close = false) {
		this.modalController.dismiss({
			dismissed: true,
			comment: this.comment,
			type: this.type,
			close
		});
	}

	async edit() {
		await this.modalService.openLoading();
		if (this.type === "forum") {
			this.forumService.updatePost(this.comment.uuid, this.comment.text, this.comment.files).subscribe(value => {
				this.modalService.closeLoading();
				this.dismissModal();
			}, error => {
				this.modalService.closeLoading();
				this.toastService.show(error);
			});
		} else if (this.type === "event") {
			this.eventService.updateComment(this.comment.uuid, this.comment.text).subscribe(value => {
				this.modalService.closeLoading();
				this.dismissModal();
			}, error => {
				this.modalService.closeLoading();
				this.toastService.show(error);
			});
		}

	}

	delete() {
		if (this.type === "forum") {
			this.forumService.deletePost(this.comment.uuid).subscribe(value => {
				this.dismissModal(true);
			}, error => {
				this.toastService.show(error);
			});
		}
		if (this.type === "event") {
			this.eventService.deleteComment(this.comment.uuid).subscribe(value => {
				this.dismissModal(true);
			}, error => {
				this.toastService.show(error);
			});
		}
	}

	removeImage(index) {
		this.comment.files.splice(index, 1);
	}

}
