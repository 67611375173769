import {Component, OnInit} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {User} from "../../../shared/models/user";
import {ResponsiveService} from "../../../shared/services/utils/responsive.service";
import {UserService} from "../../../shared/services/user.service";
import {MessengerService} from "../../../shared/services/messenger.service";
import {Router} from "@angular/router";
import {ToastService} from "../../../shared/services/utils/toast.service";
import {ModalService} from "../../../shared/services/utils/modal.service";

@Component({
	selector: "app-modal-profile",
	templateUrl: "./profile.component.html",
	styleUrls: ["./profile.component.scss"],
})
export class ProfileModalComponent implements OnInit {
	user: User;
	myUser: User;
	title: string;
	error = "";

	constructor(public router: Router, private modalService: ModalService, private toastService: ToastService, public modalController: ModalController, public responsive: ResponsiveService, private userService: UserService, private messengerService: MessengerService) {
	}

	ngOnInit(): void {
		this.userService.get(this.user).subscribe((user: User) => {
			this.user = user;
		});
		this.myUser = this.userService.getUserAuthValue();
	}

	dismissModal() {
		this.modalController.dismiss({
			dismissed: true,
		});
	}

	async openChat() {
		if (this.myUser.inaric === '') {
			await this.modalService.openAccess(value => {
			});
			return;
		} else {
			this.messengerService.create("", [this.user]).subscribe(value => {
				this.router.navigate([(this.responsive.isMobile() ? "/chat/" : "/chat-d/") + value.uuid]);
				this.modalController.dismiss({
					dismissed: true
				});
			}, error => {
				this.toastService.show(error);
			});
		}
	}

	unlock() {
		this.userService.blacklist(this.user.inaric).subscribe(value => {
			this.user.blocked = !this.user.blocked;
		}, error => {
			this.toastService.show(error);
		});
	}

}
