import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpService} from './utils/http.service';
import {PaginationService} from './pagination';
import {Article} from '../models/article';
import {Video} from '../models/video';


@Injectable({
	providedIn: 'root'
})
export class InformerService extends PaginationService {

	cftcs: BehaviorSubject<Array<Article | Video>>;
	decodeurs: BehaviorSubject<Array<Article | Video>>;
	video: BehaviorSubject<Video>;
	article: BehaviorSubject<Article>;

	constructor(private https: HttpService) {
		super();

		this.cftcs = new BehaviorSubject<Array<Article | Video>>([]);
		this.decodeurs = new BehaviorSubject<Array<Article | Video>>([]);

	}

	getArticle(uuid: string): Observable<Article> {
		return this.https.get('article/' + uuid).pipe(
			map((res: any) => {
				const article: Article = new Article(res);
				this.article.next(article);
				return article;
			})
		);
	}

	getVideo(uuid: string): Observable<Video> {
		return this.https.get('video/' + uuid).pipe(
			map((res: any) => {
				const video: Video = new Video(res);
				this.video.next(video);
				return video;
			})
		);
	}

	getsCftc(nextPage: number, term: string = ''): Observable<any> {
		if (nextPage === 1) {
			this.cftcs.next([]);
		}
		return this.https.get('cftcs/' + nextPage + '/' + term).pipe(
			map((res: any) => {
				const newArray = res.map(item => {
					if (item.youtubeUrl) {
						return new Video(item);
					} else {
						return new Article(item);
					}

				});
				const currentArray = this.cftcs.getValue();
				this.cftcs.next(currentArray.concat(newArray));
				return this.cftcs.getValue();
			})
		);
	}

	getsDecodeurs(nextPage: number, term: string = ''): Observable<any> {
		if (nextPage === 1) {
			this.decodeurs.next([]);
		}
		return this.https.get('decodeurs/' + nextPage + '/' + term).pipe(
			map((res: any) => {
				const newArray = res.map(item => {
					if (item.youtubeUrl) {
						return new Video(item);
					} else {
						return new Article(item);
					}
				});
				const currentArray = this.decodeurs.getValue();
				this.decodeurs.next(currentArray.concat(newArray));
				return this.decodeurs.getValue();
			})
		);
	}
}
