import {ThematicCollection} from "./thematicCollection";

export class Thematic {
	public title = '';
	public thematic_collections : ThematicCollection[] = [];

	constructor(data = null) {
		if (data) {
			this.title = data.title;
			if(data.thematic_collections){
				this.thematic_collections = data.thematic_collections.map((thematic_collection:any) => {
					return new ThematicCollection(thematic_collection);
				});
			}
		}

	}
}
