<ion-content [class]="responsiveService.isDesktop() ? 'ion-content-all-desktop' : 'ion-content-all-mobile'">

	<ion-icon (click)="dismissModal()" [class]="responsiveService.isMobile() ? 'close' : 'close-desktop'"
			  name="close-outline"></ion-icon>
	<ion-grid [class]="responsiveService.isDesktop() ? 'grid-desktop' : 'grid-mobile'">
		<ion-row style="padding-bottom: 35px;">
			<ion-col [style.padding-left]="responsiveService.isMobile() ? '20px' : ''" size="12">
				<ion-item class="title" lines="none">
					<ion-img class="title-icon"
							 src="assets/image/menu-3.png"
							 style="width: 26px;margin-left: 0"></ion-img>
					<ion-label style="white-space: normal !important;">{{title}}</ion-label>
				</ion-item>
			</ion-col>
			<ion-col *ngIf="description!==''" [class]="responsiveService.isDesktop() ? 'text' : 'text-mobile'"
					 size="12">
				{{description}}
			</ion-col>
			<ion-col *ngIf="responsiveService.isMobile()" size="12">
				<ion-list style="margin-left: 10px;margin-right: 10px">
					<ng-container *ngFor="let item of items; let index = index; let last=last;">
						<app-forum-subject-renderer (click)="openForum(item)"
													[index]="index"
													[item]="item"></app-forum-subject-renderer>
					</ng-container>
				</ion-list>
			</ion-col>
			<ng-container *ngIf="responsiveService.isDesktop()">

				<ion-col *ngFor="let item of items; let index = index; let last=last;" size="6"
						 style="padding-right: 30px;">
					<app-forum-subject-renderer (click)="openForum(item)"
												[index]="index"
												[item]="item"></app-forum-subject-renderer>

				</ion-col>


			</ng-container>
		</ion-row>
	</ion-grid>

</ion-content>
