<ion-content class="ion-content-all">

	<ion-icon (click)="dismissModal()" class="close" name="close-outline"></ion-icon>

	<div class="header">

		<ion-img [src]="thematic.image"></ion-img>
		<div class="bg"></div>
		<div class="text">
			<div class="mini-title">TOUT SUR…</div>
			<div class="title">{{thematic.title}}</div>
			<div class="description">
				<div [class.description-text-auto]="showAll" [innerHTML]="thematic.description"
					 class="description-text"></div>
				<div (click)="showAll = true" *ngIf="!showAll" class="more">Voir plus
					<ion-icon name="chevron-down-outline"></ion-icon>
				</div>
				<div (click)="showAll = false" *ngIf="showAll" class="more">Voir moins
					<ion-icon name="chevron-up-outline"></ion-icon>
				</div>
			</div>
			<div *ngIf="thematic.btn_label!==''" class="cta">
				<ion-button (click)="openLink(thematic.btn_url)" class="btn bg-red bd-red text-white"
							style="white-space: normal;">{{thematic.btn_label}}
					<ion-icon name="chevron-forward-circle-outline" style="margin-left: 10px;"></ion-icon>
				</ion-button>
			</div>
		</div>
	</div>

	<ion-card class="card-filter">
		<!--<app-coup-de-midi *ngIf="slug==='Congrès'"></app-coup-de-midi>-->
		<app-qrcode *ngIf="showQRCode && slug ==='54e Congrès CFTC'" [left]="30" [right]="30" [top]="15"></app-qrcode>
		<app-thematic-summary (changeAnchor)="changeAnchor($event)" [isMobile]="true"
							  [items]="thematic"></app-thematic-summary>
	</ion-card>

	<ion-row>
		<ion-col *ngFor="let s of content.sections, let i=index" [size]="12" style="padding: 0 !important;">
			<app-esrt-section *ngIf="s.type === 'external_element'" [data]="s.contents"
							  [id]="s.type+''+i"></app-esrt-section>
			<app-widget-thematic-forum (open)="dismissModal()" *ngIf="s.type === 'forum'" [id]="s.type+''+i"
									   [info]="s.infobulle"
									   [items]="s.contents" [btnTitle]="s.btn_label"
									   [paddingTop]="25" [title]="s.title"></app-widget-thematic-forum>
			<app-widget-thematic-ressourcerie (open)="dismissModal()" *ngIf="s.type === 'ressourcerie'"
											  [id]="s.type+''+i"
											  [info]="s.infobulle"
											  [items]="s.contents"
											  [btnTitle]="s.btn_label"
											  [paddingTop]="25" [title]="s.title"></app-widget-thematic-ressourcerie>
			<app-widget-thematic-actuality (open)="dismissModal()" *ngIf="s.type === 'post'" [id]="s.type+''+i"
										   [info]="s.infobulle"
										   [items]="s.contents"
										   [btnTitle]="s.btn_label"
										   [paddingTop]="25" [title]="s.title"></app-widget-thematic-actuality>
			<app-widget-thematic-decodeur (open)="dismissModal()" *ngIf="s.type === 'post_decodeur'" [id]="s.type+''+i"
										  [info]="s.infobulle"
										  [items]="s.contents"
										  [paddingTop]="25" [title]="s.title"></app-widget-thematic-decodeur>
			<app-widget-thematic-events (open)="dismissModal()" *ngIf="s.type === 'event'" [id]="s.type+''+i"
										[info]="s.infobulle"
										[items]="s.contents"
										[btnTitle]="s.btn_label"
										[paddingTop]="20" [title]="s.title"></app-widget-thematic-events>
		</ion-col>

		<!--ion-col [size]="12" style="padding: 0 !important;">
			<app-widget-thematic-decodeur></app-widget-thematic-decodeur>
		</ion-col-->

	</ion-row>


</ion-content>
