import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpService} from './utils/http.service';
import {PaginationService} from './pagination';
import {Ressourcerie} from '../models/ressourcerie';


@Injectable({
	providedIn: 'root'
})
export class RessourcerieService extends PaginationService {


	constructor(private https: HttpService) {
		super();

	}

	getRessourcerie(type: 'productions' | 'productions-personalize' | 'contributions', codeInaric: string = ''): Observable<Ressourcerie[]> {

		return this.https.get('ressourcerie/' + type + (codeInaric !== '' ? '/' + codeInaric : '')).pipe(
			map((res: any) => {
				if (res === null) {
					return [];
				}
				const arr = res.map(item => {
					return new Ressourcerie(item);

				});
				return arr;
			})
		);
	}

}
