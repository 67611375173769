import {Component, OnInit} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {ModalService} from "../../../shared/services/utils/modal.service";
import {ResponsiveService} from "../../../shared/services/utils/responsive.service";
import {Ressourcerie} from "../../../shared/models/ressourcerie";
import {lastValueFrom} from "rxjs";
import {UrlService} from "../../../shared/services/url.service";

@Component({
	selector: "app-modal-thematic-ressourcerie",
	templateUrl: "./thematic-ressourcerie.component.html",
	styleUrls: ["./thematic-ressourcerie.component.scss"],
})
export class ThematicRessourcerieModalComponent implements OnInit {
	items: Ressourcerie[] = [];
	description: String = "";
	title: String = "Ressourcerie"

	constructor(private modalController: ModalController,
				private urlService: UrlService,
				private modalService: ModalService, public responsiveService: ResponsiveService) {
	}

	ngOnInit(): void {
		this.items.sort((a, b) => {
			const dateA = new Date(a.date);
			const dateB = new Date(b.date);
			return dateB.getTime() - dateA.getTime();

		});
	}


	async openRessourserie(item: any) {

		const res = await lastValueFrom(this.urlService.getUrl(item.url));
		//await Browser.open({url: res.url});
		this.modalService.openRessourcerie(res.url, (data: any) => {

		});

	}


	dismissModal() {
		this.modalController.dismiss({
			dismissed: true,
		});
	}
}
