<!--ion-card>
    <ion-card-header>
       <ion-item lines="none">
           <ion-icon class="start" slot="start" name="bag-outline"></ion-icon>
           <ion-label>Accès limité</ion-label>
           <ion-icon class="end" slot="end" name="close-outline" (click)="dismissModal(null)"></ion-icon>
       </ion-item>
    </ion-card-header>
    <ion-card-content>
        <ion-grid style="margin-top:35px ">
            <ion-row>
                <ion-col size="12" style="text-align: center;margin-bottom: 5px">
                    <img src="assets/image/widget-annuaire-adherent.png" style="width: 100px"/>
                </ion-col>
                <ion-col size="12" style="text-align: center;margin-bottom: 25px">
                    Vous devez être adhérent pour accéder à cette fonctionnalité.
                </ion-col>
                <ion-col size="12">
                    <ion-button class="bd-yellow bg-yellow btn-map" type="button" style="width: 100%" (click)="open()">
                        Devenir adhérent
                        <ion-icon name="chevron-forward-circle-outline" style="margin-left: 10px"></ion-icon>
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-card-content>
</ion-card-->

<ion-header class="ion-no-border">
	<ion-toolbar style="background: #EDEFF6;--ion-background-color: #EDEFF6 !important; --background: #EDEFF6;">
		<ion-buttons slot="start">
			<ion-button (click)="dismissModal(false)" class="button-close">
				<ion-icon name="close-outline" style="font-size: 42px;color: var(--cftc-color-1);"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>

</ion-header>
<ion-content fullscreen
			 style="--overflow: auto;background: #EDEFF6;--ion-background-color: #EDEFF6 !important; --background: #EDEFF6;">
	<ion-row style="margin-bottom: 5px !important;">
		<ion-item lines="none" style="--padding-start: 0 !important;">
			<ion-img src="assets/image/icon-become-adherent.png" style="margin-right: 12px"></ion-img>
			<ion-label class="title-text">
				Devenez adhérent
			</ion-label>
		</ion-item>
	</ion-row>
	<ion-row>
		<ion-col style="padding-top: 0 !important; padding-bottom: 15px !important;">
			<ion-label class="description">
				Ce contenu est réservé aux adhérents CFTC. <br>
				Pour profiter de l’ensemble des avantages CFTC et de toutes les fonctionnalités de CFTC l’App, veuillez
				adhérer.
			</ion-label>
		</ion-col>
	</ion-row>

	<ion-img [class.img-desktop]="this.responsive.isDesktop()" src="assets/image/img-become-adherent.png"></ion-img>

	<ion-row>
		<ion-col style="padding-top: 0 !important;">
			<ion-button (click)="open()" class="text-btn">Je souhaite adhérer</ion-button>
		</ion-col>
	</ion-row>

	<ion-row>
		<ion-col
			style="margin-top: 15px; padding-top: 0 !important; padding-bottom: 15px !important; text-align: center;">
			<ion-label (click)="moreToKnow()" class="text-btn-secondary bg-transparent">En savoir plus</ion-label>
		</ion-col>
	</ion-row>

</ion-content>
