import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpService} from './utils/http.service';
import {Setup} from '../models/setup';

@Injectable({
	providedIn: 'root'
})
export class SetupService {

	setup: BehaviorSubject<Setup>;

	constructor(private https: HttpService) {
		this.setup = new BehaviorSubject<Setup>(null);
	}

	checkClientVersion(vs: string): Observable<boolean> {
		return this.https.get('setup/checkClientVersion/' + vs).pipe(
			map((res: any) => {
				return res.update;
			})
		);
	}

}
