import {Presation} from './prestation';

export class Qrcode {
	qrcode = '';
	TYPE_PARTICIPANT = '';
	TYPE_PARTICIPANT_COLOR = '';
	PRESTATIONS: Presation[] = [];
	attestation: string = '';

	constructor(data = null) {
		if (data) {
			this.TYPE_PARTICIPANT = data.TYPE_PARTICIPANT;
			this.TYPE_PARTICIPANT_COLOR = data.TYPE_PARTICIPANT_COLOR;
			this.PRESTATIONS = data.PRESTATIONS;
			this.qrcode = data.qrcode;
			this.attestation = data.attestation;
		}
	}
}
