<ion-card>
	<ion-grid>
		<ion-row class="row">
			<ion-col size="10">
				<ion-row>
					<ion-col size="12">
						<ion-item class="categorie" lines="none">
							<img
								[src]="
									'assets/image/' +
									item.category +
									'-green-icon.png'
								"
								style="max-height: 16px"
							/>
							<div>
								{{ eventService.categories[item.category] }}
							</div>
						</ion-item>
					</ion-col>
					<ion-col size="12" style="margin-bottom: 5px">
						<!--ion-label class="title">
							{{item.startAtDay.substr(0, 4)}}., {{item.startAtDayNumber}} {{item.startAtMonth}},
							à {{item.startAtHours}}h {{item.startAtMinutes}}
						</ion-label-->

						<ion-label class="title">
							{{ item.startAtDay }}, {{ item.startAtDayNumber }}
							{{ item.startAtMonth }}, à {{ item.startAtHours }}h
							<!-- {{item.startAtMinutes}} -->
						</ion-label>
					</ion-col>
					<ion-col size="12">
						<ion-label class="name">{{ item.title }}</ion-label>
					</ion-col>
					<ion-col size="12">
						<ion-label class="info">
							<ion-icon name="location-outline"></ion-icon>
							{{ item.address }} {{ item.zipCode }}
							{{ item.city }}
						</ion-label>
					</ion-col>
					<ion-col
						size="12"
						style="margin-top: -2px; padding-left: 12px"
					>
						<ion-label class="info"
							>Distance:
							{{ item.distance | number : "1.1-2" }} km</ion-label
						>
					</ion-col>
				</ion-row>
			</ion-col>
			<ion-col size="2">
				<div *ngIf="!edit" class="chat">
					<ion-icon name="eye-outline"></ion-icon>
				</div>
				<div *ngIf="edit" class="chat-inverse">
					<ion-icon name="settings-outline"></ion-icon>
				</div>
			</ion-col>
		</ion-row>
	</ion-grid>
</ion-card>
