import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes,} from '@angular/router';
import {LoadGuard} from './shared/guards/load.guard';
import {CguGuard} from './shared/guards/cgu.guard';

const routes: Routes = [
	{
		path: '',
		canActivate: [CguGuard],
		canLoad: [LoadGuard],
		loadChildren: () => {
			return import('./main/main.module').then((m) => m.MainPageModule);
		},
	},
	{
		path: 'auth',
		canActivate: [],
		loadChildren: () =>
			import('./auth/auth.module').then((m) => m.AuthPageModule),
	},
	{
		path: 'onboarding',
		canActivate: [],
		loadChildren: () =>
			import('./onboarding/onboarding.module').then((m) => m.OnboardingPageModule),
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules, useHash: true, enableTracing: false}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {
}
