import {ThematicSection} from "./thematicSection";

export class ThematicContent {

	public title = '';
	public type = '';
	public sections: ThematicSection[] = [];


	constructor(data = null) {
		if (data) {
			this.title = data.title;
			this.type = data.type;
			if (data.sections) {
				this.sections = data.sections.map((section: any) => {
					return new ThematicSection(section);
				});
			}
		}

	}
}
