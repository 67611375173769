import {Component, OnInit} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {ResponsiveService} from "../../../shared/services/utils/responsive.service";
import {EventService} from "../../../shared/services/event.service";
import {UserService} from "src/app/shared/services/user.service";
import {ToastService} from "src/app/shared/services/utils/toast.service";
import {ModalService} from "src/app/shared/services/utils/modal.service";

@Component({
	selector: "app-modal-analytics",
	templateUrl: "./analytics.component.html",
	styleUrls: ["./analytics.component.scss"],
})
export class AnalyticsModalComponent implements OnInit {

	constructor(
		public modalController: ModalController,
		public responsive: ResponsiveService,
		public eventService: EventService,
		public userService: UserService,
		public toastService: ToastService,
		public modalService: ModalService,
	) {

	}


	ngOnInit(): void {
	}

	dismissModal(result) {
		this.modalController.dismiss({
			dismissed: true,
			result
		});
	}


	accepte() {
		this.userService.changeAnalytics(true).subscribe(result => {
			this.dismissModal(true);
		}, error => {
			this.toastService.show(error);
		});
	}

	refuse() {
		this.userService.changeAnalytics(false).subscribe(result => {
			this.dismissModal(false);
		}, error => {
			this.toastService.show(error);
		});
	}

	openModalConfidenciality() {
		this.modalService.openConfidenciality(value => {
		});
	}

	openModalSetting() {
		this.modalService.openSetting(value => {
		});
	}
}
