import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable()
export class EventService {
	public OnScrollView = new Subject<any>();

	public refreshShowView(scroll): void {
		this.OnScrollView.next(scroll);
	}
}
