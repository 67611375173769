<ion-header>
	<ion-toolbar>
		<ion-button (click)="downloadFile()" class="btn-download" slot="start">
			<ion-icon name="download-outline"></ion-icon>
		</ion-button>
		<ion-title>{{title}}</ion-title>
		<ion-buttons slot="end">
			<ion-button (click)="dismissModal()" style="font-size: 14px">Fermer</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content>
	<pdf-viewer [original-size]="false" [src]="url"
				style="width: 100%; height: 100%;"></pdf-viewer>
</ion-content>
