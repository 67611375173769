import {Injectable} from '@angular/core';
import {ModalService} from '../services/utils/modal.service';
import {Browser} from '@capacitor/browser';

@Injectable()
export class CommonHelper {
	constructor(private modalService: ModalService) {
	}

	timeSince(date) {
		// @ts-ignore
		const seconds = Math.floor((new Date() - new Date(date)) / 1000);

		let interval = seconds / 31536000;

		if (interval > 1) {
			return Math.floor(interval) + ' année(s)';
		}
		interval = seconds / 2592000;
		if (interval > 1) {
			return Math.floor(interval) + ' mois';
		}
		interval = seconds / 86400;
		if (interval > 1) {
			return Math.floor(interval) + ' jour(s)';
		}
		interval = seconds / 3600;
		if (interval > 1) {
			return Math.floor(interval) + ' heure(s)';
		}
		interval = seconds / 60;
		if (interval > 1) {
			return Math.floor(interval) + ' minute(s)';
		}
		return Math.floor(seconds) + ' seconde(s)';
	}

	timeSinceChat(date) {
		const currentDate = new Date().getTime();
		const previousDate = parseInt(date);
		// @ts-ignore
		const seconds = Math.floor((currentDate - previousDate) / 1000);

		let interval = seconds / 31536000;

		if (interval > 1) {
			return Math.floor(interval) + ' année(s)';
		}
		interval = seconds / 2592000;
		if (interval > 1) {
			return Math.floor(interval) + ' mois';
		}
		interval = seconds / 86400;
		if (interval > 1) {
			return Math.floor(interval) + ' jour(s)';
		}
		interval = seconds / 3600;
		if (interval > 1) {
			return Math.floor(interval) + ' heure(s)';
		}
		interval = seconds / 60;
		if (interval > 1) {
			return Math.floor(interval) + ' minute(s)';
		}
		return Math.floor(seconds) + ' seconde(s)';
	}

	detectMimeType(b64) {
		const signatures = {
			JVBERi0: 'application/pdf',
			R0lGODdh: 'image/gif',
			R0lGODlh: 'image/gif',
			iVBORw0KGgo: 'image/png',
			'/9j/': 'image/jpg',
		};
		for (const s in signatures) {
			if (b64.indexOf(s) === 0) {
				return signatures[s];
			}
		}
	}

	readFileAsync(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = () => {
				resolve(reader.result);
			};

			reader.onerror = reject;

			reader.readAsDataURL(file);
		});
	}

	openFile(file: any) {
		const url = file.filename;
		if (file.name.indexOf('.pdf') > -1) {
			this.modalService.openPdf(url, 'Votre fichier pdf', file.name, () => {
			});
		} else {
			Browser.open({url});
		}
	}
}
