export class Article {
	public id = 0;
	public uuid = "";
	public createdAt = new Date().toString();
	public updatedAt = new Date().toString();
	public title = "";
	public description = "";
	public category = "";
	public pin = false;
	public inTheHeadlines = false;
	public image = "";
	public content = "";
	public url = "";
	public rubrique = "";
	public term = "";
	public date = new Date().toString();

	public dateDay;
	public dateMonth;
	public dateYear;

	public month = [
		"Janv",
		"Fev",
		"Mars",
		"Avr",
		"Mai",
		"Juin",
		"Juil",
		"Août",
		"Sept",
		"Oct",
		"Nov",
		"Déc",
	];

	public select = false;

	constructor(data = null) {
		if (data) {
			this.id = data.id;
			this.uuid = data.uuid;
			this.title = data.title;
			this.image = data.image;
			this.description = data.description;
			this.pin = data.pin;
			this.content = data.content;
			this.category = data.category;
			this.inTheHeadlines = data.inTheHeadlines;
			this.date = data.date;
			this.dateDay = data.dateDay;
			this.dateMonth = data.dateMonth;
			this.dateYear = data.dateYear;
			this.url = data.url;
			this.term = data.term;
			this.rubrique = data.rubrique;
			this.createdAt = data.createdAt;
			this.updatedAt = data.updatedAt;
		}
		if (this.date !== "" && this.date !== undefined) {
			const date = new Date(this.date);
			this.dateDay = date.getDate();
			this.dateYear = date.getFullYear();
			this.dateMonth = this.month[date.getMonth()];
		}
	}
}
