import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {Capacitor} from "@capacitor/core";
import {SplashScreen} from "@capacitor/splash-screen";
import {AppUpdate} from "@capawesome/capacitor-app-update";
import {Platform} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import {Angulartics2GoogleAnalytics, Angulartics2Matomo} from "angulartics2";
import {lastValueFrom} from "rxjs";
import {environment} from "../environments/environment";
import {SetupService} from "./shared/services/setup.service";
import {UserService} from "./shared/services/user.service";
import {ModalService} from "./shared/services/utils/modal.service";
import {ResponsiveService} from "./shared/services/utils/responsive.service";

@Component({
	selector: "app-root",
	templateUrl: "app.component.html",
	styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
	isNatif = Capacitor.isNativePlatform();

	constructor(
		private platform: Platform,
		private translate: TranslateService,
		public responsive: ResponsiveService,
		private modalService: ModalService,
		private setupService: SetupService,
		private userSerivce: UserService,
		private router: Router,
		private angulartics2Matomo: Angulartics2Matomo,
		private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics
	) {
		this.angulartics2GoogleAnalytics.startTracking();
		this.angulartics2Matomo.startTracking();
	}

	ngOnInit() {

		this.translate.setDefaultLang("fr");
		this.platform.ready().then(async () => {
			setTimeout(() => {
				SplashScreen.hide({
					fadeOutDuration: 500,
				});
			}, 2000);
		});

		this.init();
	}

	async init() {
		try {
			const tokenSSO = this.getParameterByName("token");
			if (tokenSSO) {
				const result: any = await lastValueFrom(
					this.userSerivce.signInSSO(tokenSSO)
				);
				if (!result) {
					await this.router.navigate(["/auth/signin"]);
				}
			}
		} catch (e) {
			await this.router.navigate(["/auth/signin"]);
		}

		if (Capacitor.isNativePlatform() && environment.production) {
			const result = await AppUpdate.getAppUpdateInfo();
			console.log(JSON.stringify(result));
			if (result.currentVersionCode < result.availableVersionCode) {
				await this.modalService.updateNewVersion((result) => {
				});
			}
		}
	}

	getParameterByName(name, url = window.location.href) {
		name = name.replace(/[\[\]]/g, "\\$&");
		const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
		const results = regex.exec(url);
		if (!results) {
			return null;
		}
		if (!results[2]) {
			return "";
		}
		return decodeURIComponent(results[2].replace(/\+/g, " "));
	}
}
