import {Component, OnInit} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {ModalService} from "../../../shared/services/utils/modal.service";
import {ResponsiveService} from "../../../shared/services/utils/responsive.service";

@Component({
	selector: "app-modal-thematic-article",
	templateUrl: "./thematic-article.component.html",
	styleUrls: ["./thematic-article.component.scss"],
})
export class ThematicArticleModalComponent implements OnInit {
	items: any[] = [];
	description: String = "";
	title: String = "Articles"

	constructor(private modalController: ModalController, private modalService: ModalService, public responsiveService: ResponsiveService) {
	}

	ngOnInit(): void {
		this.items.sort((a, b) => {
			const dateA = new Date(a.date);
			const dateB = new Date(b.date);
			return dateB.getTime() - dateA.getTime();

		});
	}


	async openArticle(item: any) {
		if (item.hasOwnProperty('youtubeUrl')) {
			await this.modalService.openVideo(item, (value) => {
			});
		} else {
			await this.modalService.openArticle(item, (value) => {
			});
		}
	}


	dismissModal() {
		this.modalController.dismiss({
			dismissed: true,
		});
	}
}
