export const environment = {
	firebase: {
		projectId: 'cftc-l-app',
		appId: '1:174146904509:web:84ca4f8d349785f95b72d4',
		storageBucket: 'cftc-l-app.appspot.com',
		apiKey: 'AIzaSyD7K_2QCEm_S2qVIZ88BYzSvTLG3mLtYNc',
		authDomain: 'cftc-l-app.firebaseapp.com',
		messagingSenderId: '174146904509',
		measurementId: 'G-D2YK7N1PTP',
	},
	production: false,
	host: 'https://preprod-cftcapp.castoretpollux.com/',
	ga: 'UA-215721018-1',
	pixel: '230938035241956',
	urlDecoder: 'https://cftc-abecedaire.preview2.castoretpollux.com/',
	urlRessourcerie: 'https://ressourcerie-preprod.castoretpollux.com/'
};
