import { HttpClient, HttpClientModule } from "@angular/common/http";
import localeFr from "@angular/common/locales/fr";
import { Injectable, LOCALE_ID, NgModule } from "@angular/core";
import { AngularFireModule, FIREBASE_OPTIONS } from "@angular/fire/compat";
import { AngularFireMessagingModule } from "@angular/fire/compat/messaging";
import { ReactiveFormsModule } from "@angular/forms";
import {
	BrowserModule,
	HAMMER_GESTURE_CONFIG,
	HammerGestureConfig,
	HammerModule,
} from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxGoogleAnalyticsModule } from "ngx-google-analytics";
import { NgxMaskModule } from "ngx-mask";
import { customAnimation } from "src/app/shared/animations/custom.animation";
import { NotificationService } from "src/app/shared/services/alert.service";
import { ThematicService } from "src/app/shared/services/thematic.service";
import { AnalyticsService } from "src/app/shared/services/utils/analytics.service";
import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BannerModule } from "./components/banner/banner.module";
import { UpdateComponent } from "./components/modal/update/update.component";
import { AuthGuard } from "./shared/guards/auth.guard";
import { CguGuard } from "./shared/guards/cgu.guard";
import { FirstGuard } from "./shared/guards/first.guard";
import { LoadGuard } from "./shared/guards/load.guard";
import { AlertHelper } from "./shared/helpers/alert.helper";
import { CommonHelper } from "./shared/helpers/common.helper";
import { SharedModule } from "./shared/module/shared.module";
import { GeoService } from "./shared/services/geo.service";
import { InformerService } from "./shared/services/informer.service";
import { InterstitielService } from "./shared/services/interstitiel.service";
import { RessourcerieService } from "./shared/services/ressourcerie.service";
import { UrlService } from "./shared/services/url.service";
import { UserService } from "./shared/services/user.service";
import { EventService } from "./shared/services/utils/event.service";
import { FcmService } from "./shared/services/utils/fcm.service";
import { HttpService } from "./shared/services/utils/http.service";
import { ModalService } from "./shared/services/utils/modal.service";
import { ResponsiveService } from "./shared/services/utils/responsive.service";
import { ToastService } from "./shared/services/utils/toast.service";

// ----- Avoir date en FR ------
import { registerLocaleData } from "@angular/common";
import { ScrollToModule } from "@hkjeffchan/ngx-scroll-to";
import { Angulartics2Module } from "angulartics2";

registerLocaleData(localeFr, "fr");

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http);
}

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
	overrides = <any>{
		pinch: { enable: false },
		rotate: { enable: false },
	};
}

@NgModule({
	declarations: [AppComponent, UpdateComponent],
	imports: [
		BrowserModule,
		IonicModule.forRoot({
			navAnimation: customAnimation,
			swipeBackEnabled: false,
			innerHTMLTemplatesEnabled: true,
		}),
		Angulartics2Module.forRoot(),
		NgxGoogleAnalyticsModule.forRoot(environment.ga),
		AppRoutingModule,
		HttpClientModule,
		ReactiveFormsModule,
		HammerModule,
		//PixelModule.forRoot({enabled: false, pixelId: [environment.pixel]}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		SharedModule,
		BannerModule,
		ScrollToModule.forRoot(),
		AngularFireModule.initializeApp(environment.firebase),
		AngularFireMessagingModule,
		NgxMaskModule.forRoot(),
	],
	providers: [
		// {provide: LocationStrategy, useClass: HashLocationStrategy},

		// -------- Date FR --------
		{ provide: LOCALE_ID, useValue: "fr-FR" },
		// --------------------------
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: MyHammerConfig,
		},
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: FIREBASE_OPTIONS, useValue: environment.firebase },
		LoadGuard,
		AuthGuard,
		CguGuard,
		FirstGuard,
		EventService,
		ThematicService,
		UrlService,
		InterstitielService,
		HttpService,
		NotificationService,
		ResponsiveService,
		UserService,
		GeoService,
		ModalService,
		AlertHelper,
		CommonHelper,
		ToastService,
		AnalyticsService,
		FcmService,
		InformerService,
		RessourcerieService,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
