import {Component, OnInit} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {ResponsiveService} from "../../../shared/services/utils/responsive.service";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ModalService} from "../../../shared/services/utils/modal.service";
import {UserService} from "../../../shared/services/user.service";
import {ToastService} from "../../../shared/services/utils/toast.service";

@Component({
	selector: "app-modal-cse",
	templateUrl: "./cse.component.html",
	styleUrls: ["./cse.component.scss"],
})
export class CseModalComponent implements OnInit {
	form: UntypedFormGroup = this.formBuilder.group({
		name: ['', Validators.required],
		address: ['', Validators.required],
		postal: ['', Validators.required],
		city: ['', Validators.required],
		phone: ['', Validators.required],
		email: ['', Validators.required, Validators.email],
	});


	constructor(
		private modalController: ModalController,
		private formBuilder: UntypedFormBuilder,
		private modalService: ModalService,
		public responsive: ResponsiveService,
		private userService: UserService,
		private toastService: ToastService
	) {
	}

	ngOnInit(): void {
	}

	dismissModal() {
		this.modalController.dismiss({
			dismissed: true,
		});
	}

	onSubmit() {
		try {
			const name = this.form.controls.name.value;
			const address = this.form.controls.address.value;
			const postal = this.form.controls.postal.value;
			const city = this.form.controls.city.value;
			const phone = this.form.controls.phone.value;
			const email = this.form.controls.email.value;
			this.userService.sendCandidatureCSE(name, address, postal, city, phone, email).subscribe((res: any) => {
				this.dismissModal();
				this.modalService.openCseConfirm(() => {
				});
			})
		} catch (e: any) {
			console.error(e);
			this.toastService.show('Une erreur est survenue');
		}
	}
}
