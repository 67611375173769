import {Injectable} from '@angular/core';
import {ProfileModalComponent} from '../../../components/modal/profile/profile.component';
import {AlertController, LoadingController, ModalController} from '@ionic/angular';
import {EventModalComponent} from '../../../components/modal/event/event.component';
import {EventCreateModalComponent} from '../../../components/modal/event-create/event-create.component';
import {EventMyModalComponent} from '../../../components/modal/event-my/event-my.component';
import {FilterEventComponent} from '../../../components/modal/filter-event/filter-event.component';
import {NotificationModalComponent} from '../../../components/modal/notification/notification.component';
import {FindModalComponent} from '../../../components/modal/find/find.component';
import {PictureComponent} from '../../../components/modal/picture/picture.component';
import {SettingModalComponent} from '../../../components/modal/setting/setting.component';
import {ArticleModalComponent} from '../../../components/modal/article/article.component';
import {SignalementModalComponent} from '../../../components/modal/signalement/signalement.component';
import {ThematicModalComponent} from 'src/app/components/modal/thematic/thematic.component';
import {EditCommentModalComponent} from 'src/app/components/modal/edit-comment/edit-comment.component';
import {ToastService} from 'src/app/shared/services/utils/toast.service';
import {AccessModalComponent} from 'src/app/components/modal/access/access.component';
import {AnalyticsModalComponent} from 'src/app/components/modal/analytics/analytics.component';
import {ConfidentialityModalComponent} from 'src/app/components/modal/confidentiality/confidentiality.component';
import {ResponsiveService} from 'src/app/shared/services/utils/responsive.service';
import {PdfViewerModalComponent} from '../../../components/modal/pdf-viewer/pdf-viewer.component';
import {InterstitielModalComponent} from '../../../components/modal/interstitiel/interstitiel.component';
import {NotificationNewModalComponent} from '../../../components/modal/notification-new/notification-new.component';
import {UpdateComponent} from '../../../components/modal/update/update.component';
import {DeleteAccountModalComponent} from '../../../components/modal/delete-account/delete-account.component';
import {
	SuppressionReactiveAccountComponent
} from '../../../components/modal/suppression-reactive-account/suppression-reactive-account.component';
import {QrcodeModalComponent} from "../../../components/modal/qrcode/qrcode.component";
import {
	NotificationStartModalComponent
} from "../../../components/modal/notification-start/notification-start.component";
import {VideoModalComponent} from "../../../components/modal/video/video.component";
import {CseModalComponent} from "../../../components/modal/cse/cse.component";

import {CseConfirmComponent} from "../../../components/modal/cse-confirm/cse-confirm.component";
import {
	CoupDeMidiConfirmComponent
} from "../../../components/modal/coup-de-midi-confirm/coup-de-midi-confirm.component";
import {RessourcerieModalComponent} from "../../../components/modal/ressourcerie/ressourcerie.component";
import {ThematicEventModalComponent} from "../../../components/modal/thematic-event/thematic-event.component";
import {ThematicArticleModalComponent} from "../../../components/modal/thematic-article/thematic-article.component";
import {ThematicForumModalComponent} from "../../../components/modal/thematic-forum/thematic-forum.component";
import {ForumModalComponent} from "../../../components/modal/forum/forum.component";
import {
	ThematicRessourcerieModalComponent
} from "../../../components/modal/thematic-ressourcerie/thematic-ressourcerie.component";
import {DecodeurModalComponent} from "../../../components/modal/decodeur/decodeur.component";

@Injectable()
export class ModalService {
	loading;

	constructor(
		private modalController: ModalController,
		private loadingController: LoadingController,
		private alertController: AlertController,
		private responsive: ResponsiveService,
		private toastService: ToastService
	) {
	}

	async updateNewVersion(callback) {
		const modal = await this.modalController.create({
			component: UpdateComponent,
			cssClass: 'modal-update-new-css',
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openNotificationNew(callback) {
		const modal = await this.modalController.create({
			component: NotificationNewModalComponent,
			cssClass: this.responsive.isMobile() ? 'modal-notification-new-css' : 'modal-notification-new-css-desktop',
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openPdf(url, title, fileName, callback) {
		const modal = await this.modalController.create({
			component: PdfViewerModalComponent,
			cssClass: 'modal-signalement-css',
			componentProps: {url, title, fileName},
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openInterstitiel(pub, counter, callback) {
		const modal = await this.modalController.create({
			component: InterstitielModalComponent,
			cssClass: this.responsive.isMobile() ? 'modal-interstitiel-css' : 'modal-interstitiel-css-desktop',
			componentProps: {pub, counter},
			keyboardClose: false,
			backdropDismiss: false,
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openAnalytics(callback) {
		const modal = await this.modalController.create({
			component: AnalyticsModalComponent,
			cssClass: 'modal-signalement-css',
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openAccess(callback) {
		const modal = await this.modalController.create({
			component: AccessModalComponent,
			cssClass: this.responsive.isMobile() ? 'modal-access-css' : 'modal-access-desktop-css',
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openEditComment(comment, type, callback) {
		const modal = await this.modalController.create({
			component: EditCommentModalComponent,
			cssClass: 'modal-profile-css',
			componentProps: {comment, type},
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openSignalement(callback) {
		const modal = await this.modalController.create({
			component: SignalementModalComponent,
			cssClass: this.responsive.isMobile() ? 'modal-signalement-css' : 'modal-signalement-css-desktop',
			componentProps: {},
		});
		modal.onDidDismiss().then((data: any) => {
			if (data && data.data && data.data.result) {
				this.toastService.show(
					'Nous avons bien pris en compte votre signalement. Nous reviendrons rapidement vers vous par mail.',
					3000
				);
			}
			callback(data);
		});
		return await modal.present();
	}

	async openVideo(video, callback, autoplay = false) {
		const modal = await this.modalController.create({
			component: VideoModalComponent,
			cssClass: this.responsive.isMobile() ? 'modal-thematic-css' : 'modal-thematic-bottom-css-desktop',
			componentProps: {video, autoplay},
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openThematic(item, slug, callback) {
		const modal = await this.modalController.create({
			component: ThematicModalComponent,
			cssClass: this.responsive.isMobile() ? 'modal-thematic-css' : 'modal-cftc-css-desktop',
			componentProps: {thematic: item, slug},
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openThematicEvent(items, title, description, category, callback) {
		const modal = await this.modalController.create({
			component: ThematicEventModalComponent,
			cssClass: this.responsive.isMobile() ? 'modal-thematic-css' : 'modal-thematic-css-desktop',
			componentProps: {items, title, description, category},
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openForum(subject, topic, title, callback) {
		const modal = await this.modalController.create({
			component: ForumModalComponent,
			cssClass: this.responsive.isMobile() ? 'modal-thematic-css' : 'modal-thematic-bottom-css-desktop',
			componentProps: {subject, topic, title},
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openThematicForum(items, title, description, callback) {
		const modal = await this.modalController.create({
			component: ThematicForumModalComponent,
			cssClass: this.responsive.isMobile() ? 'modal-thematic-css' : 'modal-thematic-css-desktop',
			componentProps: {items, title, description},
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openThematicArticle(items, title, description, callback) {
		const modal = await this.modalController.create({
			component: ThematicArticleModalComponent,
			cssClass: this.responsive.isMobile() ? 'modal-thematic-css' : 'modal-thematic-css-desktop',
			componentProps: {items, title, description},
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openThematicRessourcerie(items, title, description, callback) {
		const modal = await this.modalController.create({
			component: ThematicRessourcerieModalComponent,
			cssClass: this.responsive.isMobile() ? 'modal-thematic-css' : 'modal-thematic-css-desktop',
			componentProps: {items, title, description},
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openCSE(callback) {
		const modal = await this.modalController.create({
			component: CseModalComponent,
			cssClass: this.responsive.isMobile() ? 'modal-cse-css' : 'modal-cse-css-desktop',
			componentProps: {},
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openCseConfirm(callback) {
		const modal = await this.modalController.create({
			component: CseConfirmComponent,
			cssClass: this.responsive.isMobile() ? 'modal-cse-confirm-css' : 'modal-cse-confirm-css-desktop',
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openCoupMidiConfirm(callback) {
		const modal = await this.modalController.create({
			component: CoupDeMidiConfirmComponent,
			cssClass: this.responsive.isDesktop() ? 'modal-coup-midi-confirm-css-desktop' : 'modal-coup-midi-confirm-css-mobile',
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openArticle(article, callback) {
		const modal = await this.modalController.create({
			component: ArticleModalComponent,
			cssClass: this.responsive.isMobile() ? 'modal-thematic-css' : 'modal-thematic-bottom-css-desktop',
			componentProps: {article},
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openDecodeur(url, callback) {
		const modal = await this.modalController.create({
			component: DecodeurModalComponent,
			cssClass: this.responsive.isMobile() ? 'modal-profile-css' : 'modal-cftc-css-desktop',
			componentProps: {url},
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openRessourcerie(url, callback) {
		const modal = await this.modalController.create({
			component: RessourcerieModalComponent,
			cssClass: this.responsive.isMobile() ? 'modal-profile-css' : 'modal-cftc-css-desktop',
			componentProps: {url},
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openQrcode(callback) {
		const modal = await this.modalController.create({
			component: QrcodeModalComponent,
			cssClass: this.responsive.isMobile() ? 'modal-profile-css' : 'modal-cftc-css-qrcode-desktop',
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openConfidenciality(callback) {
		const modal = await this.modalController.create({
			component: ConfidentialityModalComponent,
			cssClass: 'modal-profile-css',
			componentProps: {},
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openSetting(callback) {
		const modal = await this.modalController.create({
			component: SettingModalComponent,
			cssClass: 'modal-profile-css',
			componentProps: {},
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openFind(callback) {
		const modal = await this.modalController.create({
			component: FindModalComponent,
			cssClass: this.responsive.isMobile() ? 'modal-profile-css' : 'modal-profile-css-desktop',
			componentProps: {},
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openNotificationStart(callback) {
		const modal = await this.modalController.create({
			component: NotificationStartModalComponent,
			cssClass: this.responsive.isMobile() ? 'modal-profile-css' : 'modal-notif-css-desktop',
			componentProps: {},
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openNotification(callback) {
		const modal = await this.modalController.create({
			component: NotificationModalComponent,
			cssClass: this.responsive.isMobile() ? 'modal-profile-css' : 'modal-profile-css-desktop',
			componentProps: {},
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openFilterEvent(filters, callback) {
		const modal = await this.modalController.create({
			component: FilterEventComponent,
			cssClass: 'modal-profile-css',
			componentProps: {
				filters,
			},
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openMyEvent(callback) {
		const modal = await this.modalController.create({
			component: EventMyModalComponent,
			cssClass: this.responsive.isMobile() ? 'modal-profile-css' : 'modal-profile-css-desktop',
			componentProps: {},
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openDeleteAccount(title, callback) {
		const modal = await this.modalController.create({
			component: DeleteAccountModalComponent,
			cssClass: this.responsive.isMobile() ? 'modal-delete-account-css' : 'modal-delete-account-css-desktop',
			componentProps: {
				title,
			},
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	// Réactivation du compte
	async openReactiveAccount(uuid: string, callback) {
		const modal = await this.modalController.create({
			component: SuppressionReactiveAccountComponent,
			cssClass: this.responsive.isMobile() ? 'modal-reactive-account-css' : 'modal-reactive-account-css-desktop',
			componentProps: {
				uuid,
			},
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openEventCreate(event, title, category, callback) {
		const modal = await this.modalController.create({
			component: EventCreateModalComponent,
			cssClass: this.responsive.isMobile() ? 'modal-thematic-css' : 'modal-thematic-bottom-css-desktop',
			componentProps: {
				event,
				title,
				category,
			},
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openEvent(event, title, callback) {
		const modal = await this.modalController.create({
			component: EventModalComponent,
			cssClass: this.responsive.isMobile() ? 'modal-thematic-css' : 'modal-thematic-bottom-css-desktop',
			componentProps: {
				event,
				title,
			},
		});
		modal.onDidDismiss().then((data: any) => {
			callback(data);
		});
		return await modal.present();
	}

	async openProfile(user, title, callback) {
		const modal = await this.modalController.create({
			component: ProfileModalComponent,
			cssClass: 'modal-profile-css',
			componentProps: {
				user,
				title,
			},
		});
		modal.onDidDismiss().then((data: any) => {
			callback();
		});
		return await modal.present();
	}

	async openProfileImage(callback) {
		const modal = await this.modalController.create({
			component: PictureComponent,
			cssClass: 'modal-css',
			componentProps: {},
		});
		modal.onDidDismiss().then((data: any) => {
			callback();
		});
		return await modal.present();
	}

	async openLoading() {
		this.loading = await this.loadingController.create({
			cssClass: 'my-custom-class',
			message: 'Veuillez patienter...',
			duration: 0,
		});
		await this.loading.present();
	}

	async closeLoading() {
		await this.loading.dismiss();
	}

	async showAlert(text) {
		const alert = await this.alertController.create({
			cssClass: 'my-custom-class',
			header: 'Information',
			message: text,
			buttons: ['OK'],
		});

		await alert.present();

		// @ts-ignore
		const {didDismiss} = await alert.onDidDismiss();
	}
}
